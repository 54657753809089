import { ElementRef, OnChanges, OnDestroy } from '@angular/core';
import { Directive } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Directive({
    selector: '[onboardingFix]'
})
export class OnboardingFixDirective{
    constructor(el: ElementRef, private router: Router) {
        if (location.href.includes('onboarding-wizard')) {
            this.el = el;
            el.nativeElement.classList.add('onboarding-fix');
            router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((path: NavigationEnd) => {
                if (!path.url.includes('onboarding-wizard')){
                    this.removeClass()
                }
            })
        }
    }

    public el: ElementRef;


    public removeClass(){
        if (this.el.nativeElement.classList.contains('onboarding-fix')) {
            this.el.nativeElement.classList.remove('onboarding-fix')
        }
    }


}
