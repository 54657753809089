import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';
import { VideoConfig, VideoSource } from './types';

@Component({
  selector: 'video-js',
  templateUrl: './video-js.component.html',
  styleUrls: ['./video-js.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoJsComponent implements AfterViewInit, OnDestroy {

  constructor(private elementRef: ElementRef) { }

  @ViewChild('videoPlayer') video: ElementRef;

  @Input() autoplay = false;
  @Input() fluid = false;
  @Input() aspectRatio = '16:9';
  @Input() sources: VideoSource[] = [];

  public player: videojs.Player;

  ngAfterViewInit(): void {
    this.config.autoplay = this.autoplay || false;
    this.config.fluid = this.fluid;
    this.config.aspectRatio = this.aspectRatio;
    this.config.sources = this.sources;
    this.player = videojs(this.video.nativeElement, this.config, function onPlayerReady() {});
  }

  ngOnDestroy(): void{
    if (this.player) {
      this.player.dispose();
    }
  }

  private config: VideoConfig = {
    autoplay: false,
    fluid: false,
    aspectRatio: '16:9',
    sources: []
  };
}
