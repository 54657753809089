import { Component, ElementRef, OnInit, ViewChild, HostListener, Input, OnChanges } from '@angular/core';
import { Country, FitConfigProviderService } from '@shared/fit-config-provider.service';
import { AuthorizationService, Flow, User } from '@shared/authorization.service';
import { NotificationsService } from '@shared/notifications.service';
import { ProfileService, AppVisualsV2 } from '../profile.service';
import { BillingInfo } from '../types';
import { style } from './stripe.style';
import { DisplayCardError } from '../../shared/card-errors/declined-card-errors';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PersonalizedAppModalComponent } from '../personalized-app-modal/personalized-app-modal.component';
import { AppReadyComponent } from '../onboarding-wizard/app-ready/app-ready.component';

declare var Stripe;
interface StripeErrorResponse {
    error: StripeCardError;
}
interface StripeCardError {
    code: string;
    decline_code: string;
    message: string;
}

@Component({
    selector: 'profile-billing-info',
    templateUrl: './billing-info.component.html',
    styleUrls: ['./billing-info.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: BillingInfoComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: BillingInfoComponent,
            multi: true,
        },
    ],
})

export class BillingInfoComponent implements OnInit, OnChanges {
    constructor(
        private profileService: ProfileService,
        private fitConfig: FitConfigProviderService,
        private fitConfigProviderService: FitConfigProviderService,
        private auth: AuthorizationService,
        private cardErrors: DisplayCardError,
        private notifications: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: BsModalService
    ) { }

    @ViewChild('iFrameElem') iFrameElement: ElementRef;
    @ViewChild('cardForm') cardForm: ElementRef;
    @ViewChild('cardFormInput') cardFormInput: ElementRef;
    @ViewChild('billingForm') billingForm: NgForm;

    @Input() user: User;
    @Input() showSaveCard = true;
    @Input() submitted = false;

    get billing() {
        return this._billing;
    }
    set billing(value) {
        this._billing = value;
    }

    get stripePublicKey() {
        return this._stripePublicKey;
    }
    set stripePublicKey(value) {
        this._stripePublicKey = value;
    }

    get clientSecret() {
        return this._clientSecretString;
    }
    set clientSecret(value) {
        this._clientSecretString = value;
    }

    get invoiceNumber() {
        return this._invoice_number;
    }
    set invoiceNumber(value) {
        this._invoice_number = value;
    }

    get gateway() {
        return this._gateway;
    }
    set gateway(value) {
        this._gateway = value;
    }

    public token;
    public showSpiner = false;
    public closeFrame = true;
    public countries: Country[] = [];
    public isDisabled = false;
    public disableSaveCard = true;
    public cardElement;
    public authorizeNetUrl: string;
    public billingInfoCompleted = false;
    public showCardSection = false;
    public userAddedCard = false;
    public fromDashboard = false;
    public flow: Flow;
    public displayStripeForm = false;
    public showAddCard = false;
    public copyCheckbox = false;

    ngOnInit(): void {
        this.profileService.billingInfoCompleted.subscribe((bool) => {
            this.billingInfoCompleted = bool;
        });

        this.profileService.pwaAssets.subscribe(res => {
            if (res) {
                this._pwaAssets = res;
            }
        });

        this.route.paramMap.subscribe(params => {
            if (!params.has('dashboard')) {
                this.fromDashboard = false;
                return;
            }
            
            this.fromDashboard = true;
            this.showCardSection = false;
        });

        this.getCountries();
        this.load();
    }

    public onCopyFromYourInfo() {
        this.copyCheckbox ? this.onCopyFromOwner() : this.cleanFields();
        this.updateBillingInfo();
    }

    public async getCountries() {
        const countries = await this.fitConfigProviderService.getCountryList();
        if (countries) {
            return this.countries = countries;
        }
        
        return this.notifications.error('Error', 'Error loading list of countries');
    }

    public onValidatorChange: () => void = () => { };
    public onTouched: () => void = () => { };
    public onChange: (value: BillingInfo) => void = (value: BillingInfo) => { };

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    public validate(control: AbstractControl): ValidationErrors {
        if (!this.billing) {
            return { billing: 'Billing Info is required' };
        } else if (!this.billing.name) {
            return { name: 'Name is required' };
        } else if (!this.billing.surname) {
            return { lastname: 'Lastname is required' };
        } else if (!this.billing.city) {
            return { city: 'City is required' };
        } else if (!this.billing.street_name_and_number) {
            return { address: 'Address is required' };
        } else if (!this.billing.country) {
            return { country: 'Country is required' };
        } else if (!this.billing.state) {
            return { state: 'State is required' };
        } else if (!this.billing.zip_code) {
            return { zipCode: 'Zip code is required' };
        }
        
        return null;
    }

    public writeValue(obj: BillingInfo): void {
        if (obj) {
            this.billing = obj;
        } else {
            this.billing = {
                name: '',
                surname: '',
                email: '',
                city: '',
                street_name_and_number: '',
                street_name_and_number_line_2: '',
                country: '',
                state: '',
                zip_code: '',
                cards: [],
            };
        }
    }

    public updateBillingInfo() {
        this.onTouched();
        this.onChange(this.billing);
    }

    ngOnChanges() {
        if (this.billingInfoCompleted) {
            const gateway = localStorage.getItem('gateway');
            this._gateway = gateway;
            this.onChooseGateway();
        }
    }

    public async load() {
        this.auth.user.subscribe((user) => {
            if (user) {
                this.user = user;
                this._gateway = user.coach_add_card_payment_gateway;
                localStorage.setItem('gateway', this._gateway);
            }
        });
        
        const config = await this.fitConfig.getConfig();
        this.authorizeNetUrl = config.authorizeNetUrl;
        this.fetchBillingInfo();
    }

    public cleanFields() {
        this.billing.city = '';
        this.billing.country = '';
        this.billing.email = '';
        this.billing.name = '';
        this.billing.state = '';
        this.billing.street_name_and_number = '';
        this.billing.street_name_and_number_line_2 = '';
        this.billing.surname = '';
        this.billing.zip_code = '';
    }

    public async fetchBillingInfo() {
        const { data, error } = await this.profileService.getBillingInfo();
        if (!error) {
            this._billing = data;
            this._billing.email = '';
            
            if (data.name && data.surname && data.city && data.country && data.state && data.street_name_and_number && data.zip_code) {
                this.profileService.billingInfoCompleted.next(true);
                this.profileService.hasBillingInfo.next(true);
                this.onChooseGateway();
            }
            
            if (this._billing.cards && this._billing.cards.length > 0) {
                this.userAddedCard = true;
            }
        }
        
        this.updateBillingInfo();
    }

    public closeStripe() {
        this.showAddCard = false;
    }

    public onChooseGateway() {
        switch (this._gateway) {
            case 'authorize-net':
                this.onAddAuthorize();
                break;
            case 'stripe':
                this.onAddStripe();
                break;
        }
    }

    // public async checkCards() {
    //     let fetchCounter = 0;
    //     let timeout;
    //     const reTry = async () => {
    //         clearTimeout(timeout);
    //         await this.fetchBillingInfo();
    //         if (fetchCounter <= 3 && !this.userAddedCard) {
    //             timeout = setTimeout(() => {
    //                 fetchCounter++;
    //                 reTry();
    //             }, 1000);
    //         } else if (fetchCounter === 3 && !this.userAddedCard) {
    //             location.reload();
    //         }
    //     };
    //     reTry();
    // }

    public async onCopyFromOwner() {
        const cards = JSON.parse(JSON.stringify(this._billing.cards));
        let billing = this.profileService.copyOwnerInfo();
        
        if (billing) {
            this._billing = billing;
            this._billing.cards = cards;
        } else {
            const { data, error } = await this.profileService.getOwnerInfo();
            if (!error) {
                this.profileService.ownerInfo = data;
                billing = this.profileService.copyOwnerInfo();
                if (billing) {
                    this._billing = billing;
                    this._billing.cards = cards;
                }
            }
        }
    }

    public nextStep(form) {
        if (!form.valid) {
            return;
        }
        
        this.onFormSubmit();
    }

    public async onFormSubmit() {
        const { data, error } = await this.profileService.createBilling(
            this._billing
        );
        
        if (!error) {
            sessionStorage.setItem('show-wizard', 'true');
            this.profileService.billingInfoCompleted.next(true);
            this.auth.refreshUserData();
            this.showCardSection = true;
            this.onChooseGateway();
            
            if (this.fromDashboard) {
                this.notifications.success('Success', 'Saved');
            }
        } else {
            this.notifications.error(
                'Error',
                error.errorMessages[0] || 'Something went wrong while saving your billing info!!'
            );
        }
    }

    public async onSetAsDefault(id: number) {
        const { data, error } = await this.profileService.setAsPrimaryCard(id);
        if (!error) {
            this.notifications.success('Success', 'Successfully set default card');
            this.fetchBillingInfo();
        }
    }

    public async onDeleteCard(id: number) {
        if (this._billing.cards.length === 1) {
            this.notifications.warning('Warning', 'Credit card can\'t be removed until new one is added!');
            return;
        }
        
        const { data, error } = await this.profileService.deleteCard(id);
        if (!error) {
            this.notifications.success('Success', 'Successfully removed card');
            this.fetchBillingInfo();
        }
    }

    private async onAddAuthorize() {
        this.closeFrame = false;
        this.showSpiner = true;
        
        const { data, error } = await this.profileService.getBillingCardToken();
        if (!error) {
            this.token = data.token;
            this.showSpiner = false;
            this.iFrameElement.nativeElement.style.display = 'block';
            this.cardFormInput.nativeElement.value = data.token;
            this.cardForm.nativeElement.submit();
        }
        
        this.iFrameElement.nativeElement.style.display = 'block';
    }

    private async onAddStripe() {
        this.showAddCard = true;
        
        const { data, error } = await this.profileService.getBillingCardToken();
        if (!error) {
            this.stripePublicKey = data.card_token.stripe_public_key;
            this.clientSecret = data.card_token.client_secret;
            this.invoiceNumber = data.card_token.invoice_number;
            this.displayStripeForm = true;

            const stripe = Stripe(`${this.stripePublicKey}`);
            const elements = stripe.elements();
            const cardElement = elements.create('card', {
                hidePostalCode: true,
                style,
            });

            cardElement.on('change', (event) => {
                this.disableSaveCard = !event.complete;
            });
            
            if (!this.showCardSection) {
                return;
            }

            cardElement.mount('#card-element');

            const setupForm = document.getElementById('setup-form');
            setupForm.addEventListener('submit', (ev) => {
                ev.preventDefault();
                stripe
                    .confirmCardSetup(this.clientSecret, {
                        payment_method: {
                            card: cardElement,
                            metadata: {
                                invoice_number: `${this.invoiceNumber}`,
                            },
                        },
                    })
                    .then(async (result: StripeErrorResponse) => {
                        if (result.error) {
                            this.cardErrors.displayStripeError(
                                result.error
                            );
                        } else {
                            sessionStorage.setItem('show-wizard', 'true');
                            await this.auth.refreshUserData();
                            await this.fetchBillingInfo();

                            if (!this.fromDashboard) {
                                this.router.navigate(['/']);

                                if (this.user.coach_package?.include_custom_application) {
                                    const initialState = {
                                        logo: this._pwaAssets.logo.url,
                                        brandName: this.user.application_name,
                                        complete: async () => {
                                            await this.auth.refreshUserData();
                                            this._BsModalRef.hide();
                                            this.router.navigate(['/']);
                                        }
                                    };
                                    this._BsModalRef = this.modalService.show(PersonalizedAppModalComponent,
                                        { initialState, backdrop: true, ignoreBackdropClick: false, class: 'modal-app-ready' }
                                    );
                                } else if (!this.user.coach_package?.include_custom_application && !this.user.coach_package.pwa_wizard_advanced_flow) {
                                    const initialState = {
                                        isModal: true
                                    };
                                    this.modalService.show(AppReadyComponent,
                                        { initialState, backdrop: true, ignoreBackdropClick: false, class: 'modal-app-ready' }
                                    );
                                } else {
                                    this.router.navigate(['/profile/onboarding-wizard/uploading-assets']);
                                }
                            } else {
                                this.disableSaveCard = true;
                            }
                        }
                    });
            });
        } else {
            this.notifications.error('Error', 'Billing service error');
        }
    }

    @HostListener('window:message', ['$event'])
    frameMessageListener($event: MessageEvent) {
        if ($event.data === 'action=cancel') {
            this.token = '';
            this.iFrameElement.nativeElement.style.display = 'none';
        }
    }

    private _billing: BillingInfo = {
        cards: null,
        name: '',
        surname: '',
        email: '',
        street_name_and_number: '',
        street_name_and_number_line_2: '',
        city: '',
        country: '',
        state: '',
        zip_code: '',
    };

    private _gateway = null;
    private _clientSecretString: string;
    private _stripePublicKey: string;
    private _invoice_number: string;
    private _pwaAssets: AppVisualsV2 = null;
    private _BsModalRef: BsModalRef;
}
