import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GalleryItem } from '@app/gallery/gallery-item';
import { NotificationsService } from '@shared/notifications.service';
import { PageableComponent } from '@shared/pageable.component';
import { debounceTime } from 'rxjs';
import { MessageRequest, Attachment, Thread, ThreadRequest, ResolutionCenterService } from '../resolution-center.service';

@Component({
    selector: 'coach-center',
    templateUrl: './coach-center.component.html',
    styleUrls: ['./coach-center.component.scss']
})

export class CoachCenterComponent extends PageableComponent<any> implements OnInit {
    constructor(
        private resolutionCenterService: ResolutionCenterService,
        private notification: NotificationsService
    ) {
        super();

        this.searchQueryStream.pipe(
            debounceTime(500)
        ).subscribe(() => (
            this._page = 1,
            this.load()
        ));
    }

    @ViewChild('messageForm') public messageForm: NgForm;

    @Output() public showNewButton = new EventEmitter<boolean>();

    public searchContact = new EventEmitter<string>();

    get messages() { return this._messages; }
    set messages(value) { this._messages = value; }

    get newThread() { return this._newThread; }
    set newThread(value) { this._newThread = value; }

    get uploadCounter() { return this._uploadedCounter; }

    public showCreateNewForm = true;
    public createNewButton = false;
    public selectedThread = null;
    public showCreateNew = true;
    public noSearchResult = false;
    public showLoadMoreMessages = false;
    public loadingMessages = false;
    public seen = true;
    public _timeout = null;
    public showLoadMoreThreads = false;
    public disableThreadForm = false;
    public selectedContactThread = null;
    public progress = 0;
    public sending = false;
    public showClosedThreads = false;

    public messageText = '';

    public defaultAvatar = 'assets/icons/ic_placeholder.svg';

    public uploadedFiles = [];
    public fileList = [];

    ngOnInit(): void {
        /*
        this.searchContact.pipe(
            distinctUntilChanged(),
            debounceTime(400)
        ).subscribe(term => {
            this.userService.searchUser(term, 1)
                .then(response => {
                    this.users = response.data.data;
                });
        });
        */

        this.load();
    }

    public onShowClosedThreadsChange() {
        this._page = 1;
        this._threadPage = 1;
        this.showCreateNewForm = true;
        this.selectedThread = null;
        this.showCreateNew = false;
        this.load();
    }

    public async closeThread($event: Event, threadId: number) {
        $event.preventDefault();
        $event.stopPropagation();

        const { data, error } = await this.resolutionCenterService.closeThread(threadId);
        if (!error) {
            this._page = 1;
            this._threadPage = 1;
            this.showCreateNewForm = true;
            this.selectedThread = null;
            this.showCreateNew = false;
            this.load();
        }
    }

    public async load() {
        this.showLoadMoreThreads = false;
        const { data, error } = await this.resolutionCenterService.getResolutionThreadList(
            undefined,
            this.searchQuery,
            this.showClosedThreads,
            this._page
        );
        
        if (!error) {
            this._list = data;
            if (data.last_page > this._page) {
                this.showLoadMoreThreads = true;
            } else {
                this.showLoadMoreThreads = false;
            }
        }
    }

    public onShowThreadForm() {
        this.showCreateNewForm = true;
        this.selectedThread = null;
        this.showCreateNew = false;
    }

    public onAttachedFiles(eventFiles) {
        for (let i = 0; i < eventFiles.length; i++) {
            let haveAllready = this.fileList.some(item => item.name === eventFiles[i].name);
            if (!haveAllready) {
                this.fileList.push(eventFiles[i]);
            }
        }
        
        this.startUploading();
    }

    public startUploading() {
        this._uploadedCounter = 0;
        if (!this.fileList.length) {
            return;
        }
        
        let amountToUpload = this.fileList.length;
        const upload = async () => {
            if (amountToUpload > this._uploadedCounter) {
                const data = await this.uploadAttachment(this.fileList[this._uploadedCounter]);
                if (data) {
                    data.subscribe(
                        res => {
                            if (res.progress) {
                                this.progress = res.progress;
                            } else if (res.response) {
                                this.progress = 0;
                                if (res.response !== undefined) {
                                    this.uploadedFiles.push(res.response.data);
                                }
                                this._uploadedCounter++;
                            } else {
                                this.progress = 0;
                            }
                        },
                        error => {
                            this.progress = 0;
                            this._uploadedCounter++;
                            const err = error.status === 502 ? error.statusText : error.error.errorMessages.join('\r\n');
                            this.notification.error('Error', err);
                        },
                        () => {
                            upload();
                        }
                    );
                }
            } else {
                this.fileList = [];
                this._uploadedCounter = 0;
            }

        };

        upload();
    }

    public uploadAttachment(file) {
        return this.resolutionCenterService.uploadAttachment(file.name, file, null);
    }

    public sendThreadMessage() {
        if (this.sending) {
            return;
        }

        this.sending = true;
        const message: MessageRequest = {
            application_id: undefined,
            thread_id: this.selectedThread.id,
            message: this.messageText.trim(),
            attachment_ids: this.uploadedFiles.map(file => file.id)
        };

        if (!message.message && !message.attachment_ids.length) {
            return;
        }

        this.resolutionCenterService.sendResolutionMessage(message, this.uploadedFiles).then(res => {
            this.sending = false;
            this.messageForm.resetForm();
            this.messageText = '';
            this.checkResolutionThreadMessages();
            this.uploadedFiles = [];
            setTimeout(() => {
                this.scrollToLastReadedMesssage();
            }, 1000);
        });
    }

    public checkResolutionThreadMessages() {
        this.loadingMessages = true;
        if (!this.selectedThread) {
            return;
        }

        if (this._loadedPrevious) {
            this.getOnlyNewResolutionMessages();
        } else {
            this.getResolutionMessages();
        }
    }

    public onRemoveAttachment(attachment: GalleryItem) {
        this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== attachment.id);
    }

    public async loadMoreThreads() {
        this._threadPage++;
        this.showLoadMoreThreads = false;

        const { data, error } = await this.resolutionCenterService.getResolutionThreadList(
            null, 
            this.searchQuery, 
            this.showClosedThreads, 
            this._threadPage
        );
        
        if (!error) {
            this._list.data = [...this.list, ...data.data];
            this.showLoadMoreThreads = this.shouldShowLoadMoreButton(data.current_page, data.last_page);
        }
    }

    public onBackToThread() {
        this.showCreateNew = true;
    }

    public loadMoreThreadMessages() {
        this._page++;
        this._loadedPrevious = true;
        this.showLoadMoreMessages = false;
        this.resolutionCenterService.getResolutionMessages(null, this.selectedThread.id, this.page).then(res => {
            this._messages = [...this._messages, ...res.data.data].sort((a, b) => a.id - b.id);
            this.showLoadMoreMessages = this.shouldShowLoadMoreButton(res.data.current_page, res.data.last_page);
            this.setScrollAmount();
        });
    }

    public async onDeleteAttachment(file: Attachment) {
        const { data, error } = await this.resolutionCenterService.deleteAttachment(file, null, false);
        if (!error) {
            this.checkContactThreadMessages();
            this.notification.success('Success', 'Attachment deleted');
            this.getResolutionMessages();
        }
    }

    public checkContactThreadMessages() {
        this.loadingMessages = true;

        if (!this.selectedContactThread) {
            return;
        }

        if (this._loadedPrevious) {
            this.getOnlyNewContactMessages();
        } else {
            this.getContactMessages(this.selectedContactThread.id);
        }
    }

    public async getContactMessages(id: number, search?) {
        const { data, error } = await this.resolutionCenterService.getContactMessages(id, this._page);
        if (!error) {
            this._messages = [...this._messages, ...data.data].sort((a, b) => a.id - b.id);
            this.fillEmptyAvatarsWithDefault();
            this.showLoadMoreMessages = this.shouldShowLoadMoreButton(data.current_page, data.last_page);
            this.loadingMessages = false;
            setTimeout(() => {
                this.scrollToLastReadedMesssage();
            }, 100);
        }
    }

    public scrollToLastReadedMesssage() {
        const messageBox: HTMLDivElement = document.querySelector('.message-list');
        if (messageBox) {
            messageBox.scroll({ top: 20000, behavior: 'smooth' });
        }
    }

    public setScrollAmount() {
        const message: HTMLDivElement = document.querySelector('.message');
        if (this._page > 1 && message) {
            this._scrollAmount = (this._messages.length - ((this._page - 1) * this.itemsPerPage)) * message.offsetHeight;
        }
    }

    public fillEmptyAvatarsWithDefault() {
        this._messages.forEach(message => {
            if (!message.user_avatar) {
                message.user_avatar = this.defaultAvatar;
            }
        });
    }

    public onSelectResolutionThread(thread: Thread) {
        if (this.selectedThread && thread.id === this.selectedThread.id) {
            return;
        }

        if (this.selectedThread) {
            this.resolutionCenterService.cacheThread(this.selectedThread.id, this.messageText);
        }

        if (thread) {
            this.messageText = this.resolutionCenterService.getThreadCahedMessage(thread.id);
        }

        this._messages = [];
        this.createNewButton = true;
        this._loadedPrevious = false;
        this._page = 1;
        this.showCreateNewForm = false;
        this.showCreateNew = false;
        this.selectedThread = thread;
        this.getResolutionMessages(true);
        this.markThreadMessagesSeen();
        this.showNewButton.emit(true);
    }

    public getResolutionMessages(scroll = false) {
        this.resolutionCenterService.getResolutionMessages(null, this.selectedThread.id, this._page).then(res => {
            this._messages = [...res.data.data].sort((a, b) => a.id - b.id);;
            this.showLoadMoreMessages = this.shouldShowLoadMoreButton(res.data.current_page, res.data.last_page);
            this.loadingMessages = false;
            if (scroll) {
                setTimeout(() => {
                    this.scrollToLastReadedMesssage();
                }, 500);

            }
        });
    }

    public scrollMessagesToNewest() {
        if (this._scrolled) {
            return;
        }

        let messagesBox: HTMLDivElement;
        setTimeout(() => {
            messagesBox = document.querySelector('.message-list');
            if (!messagesBox) {
                return this.scrollMessagesToNewest();
            } else {
                messagesBox.scroll({ top: messagesBox.scrollHeight + messagesBox.offsetHeight, left: 0, behavior: 'smooth' });
            }
            this._scrolled = true;
        }, 500);
    }

    public async markThreadMessagesSeen() {
        let thread = this.selectedThread;
        const { error } = await this.resolutionCenterService.markThreadAsSeen(null, thread.id);
        if (!error) {
            this.resolutionCenterService.updateTotalNotifications.next(true);
            this.seen = true;
            this._list.data.forEach(thread => {
                if (this.selectedThread.id === thread.id) {
                    thread.unread_messages = 0;
                }
            });
            this._timeout = setTimeout(() => {
                this.resolutionCenterService.updateAllAppsMessages.next(true);
            }, 500);
        }
    }

    public async onThreadFormSubmit(form: NgForm) {
        if (this.disableThreadForm) {
            return;
        }

        this.disableThreadForm = true;
        const { data, error } = await this.resolutionCenterService.createNewThread(this._newThread, this.uploadedFiles);
        if (!error) {
            this.load();
            this.notification.success('Success', 'Thread successfully created');
            this.uploadedFiles = [];
            form.resetForm();
            setTimeout(() => {
                this.disableThreadForm = false;
            }, 500);
        }
    }

    private async getOnlyNewResolutionMessages() {
        this.resolutionCenterService.getResolutionMessages(null, this.selectedThread.id, 1).then(res => {
            const newMessages: any = [];
            res.data.data.forEach(message => {
                const check = this._messages.some(msg => msg.id === message.id);
                if (!check) {
                    newMessages.push(message);
                }
            });
            this._messages = [...this._messages, ...newMessages].sort((a, b) => a.id - b.id);
            this.loadingMessages = false;
            this._scrolled = false;
            if (newMessages.length) {
                setTimeout(() => {
                    this.scrollToLastReadedMesssage();
                }, 100);
            }
        });
    }

    private async getOnlyNewContactMessages() {
        this.resolutionCenterService.getContactMessages(this.selectedContactThread.id, this._page).then(res => {
            const newMessages: any = [];
            res.data.data.forEach(message => {
                const check = this._messages.some(msg => msg.id === message.id);
                if (!check) {
                    newMessages.push(message);
                }
            });
            this._messages = [...newMessages, ...this._messages].sort((a, b) => a.id - b.id);
            this.fillEmptyAvatarsWithDefault();
            this.loadingMessages = false;
            this._scrolled = false;

            if (newMessages.length) {
                setTimeout(() => {
                    this.scrollToLastReadedMesssage();
                }, 500);
            }
        });
    }

    private shouldShowLoadMoreButton(currentPage: number, lastPage: number) {
        return currentPage < lastPage;
    }

    private _loadedPrevious = false;
    private _messages = [];
    private _scrolled = false;
    private _threadPage = 1;
    private _uploadedCounter = 0;
    private _scrollAmount = 0;
    private _newThread: ThreadRequest = {
        application_id: null,
        subject: '',
        message: '',
        attachment_ids: []
    };
}
