import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { FitApiClientService, FitList } from '@shared/fit-api-client.service';

import { GalleryModalComponent } from './gallery-modal/gallery-modal.component';
import { GalleryVideoCropComponent } from './gallery-video-crop/gallery-video-crop.component';
import { GalleryItem } from './gallery-item';
import { MarkerDimensions } from '@shared/components/area-marker/area-marker.component';

export { GalleryItem };

export interface GalleryUploadLimits {
    image_types: string[];
    video_types: string[];
    upload_limit_in_bytes: number;
    document_types: string[];
}

// var acceptedVideoMimeTypes = ['mp4', 'mpeg4', 'mpeg', 'mov'];
// var acceptedImageMimeTypes = ['jpeg', 'png'];

function formatBytes(bytes, decimals) {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

@Injectable()
export class GalleryService {
    constructor(private fitApi: FitApiClientService, private modalService: BsModalService) {
        this.getUploadLimits();
    }

    public type: 'all' | 'image' | 'video' | 'document' = 'all';
    public uploadLimits: GalleryUploadLimits = {
        image_types: [],
        upload_limit_in_bytes: 0,
        video_types: [],
        document_types: []
    };

    public formattedBytes = '';

    public search(query: string, page: number, order: string, type?: 'video' | 'image',) {
        return this.fitApi.get<FitList<GalleryItem>>('/admin/gallery', {
            search: query,
            type,
            page: page.toString(),
            order
        });
    }

    public searchAvatar(query: string, page: number, order: string, type?: 'video' | 'image') {
        return this.fitApi.get<FitList<GalleryItem>>('/admin/gallery/avatar', {
            search: query,
            type,
            page: page.toString(),
            order
        });
    }

    public formatBytes(bytes, decimals) {
        return formatBytes(bytes, decimals);
    }

    public fixVideo(id: number) {
        return this.fitApi.post<any>('/admin/fix-video', { id });
    }

    public selectFromGallery(type: 'all' | 'image' | 'video' | 'document', source: 'gallery' | 'avatar'): Promise<GalleryItem> {
        const promise = new Promise<GalleryItem>((resolve, reject) => {
            const initialState = {
                onClose: (item: GalleryItem) => {
                    resolve(item);
                },
                type,
                source
            };
            this.bsModalRef = this.modalService.show(GalleryModalComponent,
                { initialState, backdrop: false, ignoreBackdropClick: true, class: 'gallery-modal' });
        });
        return promise;
    }

    public openCropGalleryItemWindow(item: GalleryItem): Promise<MarkerDimensions> {
        const promise = new Promise<MarkerDimensions>((resolve, reject) => {
            const initialState = {
                onClose: (dimensions: MarkerDimensions) => {
                    resolve(dimensions);
                },
                item
            };
            this.bsModalRef = this.modalService.show(GalleryVideoCropComponent,
                { initialState, backdrop: false, ignoreBackdropClick: true, class: 'gallery-video-crop-modal' });
        })
        return promise;
    }

    public cropGalleryVideoItem(item: GalleryItem, dimensions: MarkerDimensions) {
        return this.fitApi.post<GalleryItem>(`/admin/crop-video`, {
            id: item.id,
            width: dimensions.width,
            height: dimensions.height,
            x: dimensions.left,
            y: dimensions.top
        });
    }

    public delete(id: number) {
        return this.fitApi.delete<any>(`/admin/gallery/${id}`);
    }


    public update(item: GalleryItem) {
        return this.fitApi.put<GalleryItem>(`/admin/gallery/${item.id}`, item);
    }

    public upload(fileName: string, file: File, isUserAvatar: boolean, type: 'image' | 'video' | 'document' | 'all') {
        const form = new FormData();
        form.append('url', file);
        form.append('name', fileName);
        form.append('type', type);
        form.append('is_user_avatar', isUserAvatar ? 'true' : 'false');
        return this.fitApi.uploadFileToGallery<GalleryItem>(form);
    }

    public uploadFromProfile(fileName: string, file: File | Blob, isUserAvatar: boolean) {
        const form = new FormData();
        form.append('url', file);
        form.append('name', fileName);
        form.append('is_user_avatar', isUserAvatar ? 'true' : 'false');
        return this.fitApi.uploadFileFromProfile<GalleryItem>(form);
    }

    public getUploadLimits() {
        this.fitApi.get<GalleryUploadLimits>('/admin/gallery/upload-limits').then(res => {
            this.uploadLimits = res.data;
            this.formattedBytes = this.formatBytes(this.uploadLimits.upload_limit_in_bytes, 2);
        });
    }

    public getImageById(id: number){
        return this.fitApi.get<GalleryItem>(`/admin/gallery/${id}`);
    }

    public isValidFileType(file: File, acceptedType: string) {
        const typeValidator = (types: string[]) => types.some(type => file.type.toLowerCase().indexOf(type) > -1);
        return typeValidator(this.getAcceptableFileTypes(acceptedType));
    }

    public getAcceptableFileTypes(acceptedType: string) {
        switch (acceptedType) {
            case 'image':
                return this.uploadLimits.image_types;
            case 'video':
                return this.uploadLimits.video_types;
            case 'document':
                return this.uploadLimits.document_types;
            case 'all':
                return [...this.uploadLimits.image_types, ...this.uploadLimits.video_types];
        }
    }

    private bsModalRef: BsModalRef;
}
