<div class="data-in-review"
     *ngIf="showReviewPopup && user.status_mobile_apps === 'STATUS_INSUFFICIENT_DATA'">
    <h1>Insufficient data</h1>
    <p>Thank you for going through the initial setup. We cannot develop your app until you provide all the necessary
        information. You can add missing information in the <span>"App status"</span> section as shown in the image
        below.</p>
    <img srcset="/assets/img/image_mockup_popup-insufficient-data.png 1x,
                     /assets/img/image_mockup_popup-insufficient-data@2x.png 2x"
         src="/assets/img/image_mockup_popup-insufficient-data.png"
         alt="Insufficient data">
    <button class="button-true-green"
            (click)="onCloseWizzard()">Go to Dashboard</button>
</div>

<div class="data-in-review"
     *ngIf="showReviewPopup && user.status_mobile_apps === 'STATUS_DATA_IN_REVIEW'">
    <h1>Your data is in review</h1>
    <p>Thank you for completing the initial setup. The information you entered is being reviewed by our team. When
        status is changed you will be able to see it in the <span>"App status"</span> section, as shown in the image
        below.</p>
    <img srcset="/assets/img/image_mockup_popup-in-review.png 1x,
                     /assets/img/image_mockup_popup-in-review@2x.png 2x"
         src="/assets/img/image_mockup_popup-in-review.png"
         alt="Data in review">
    <button class="button-true-green"
            (click)="onCloseWizzard()">Go to Dashboard</button>
</div>

<div class="stepper"
     *ngIf="user?.wizard_section_status && !showReviewPopup">
    <div class="brand-name-wrapper">
        <h4>{{ brandName }}</h4>
    </div>
    <form id="stepper-form"
          #stepperForm="ngForm"
          (submit)="updateByStep(step)">
        <div class="stepper-header-wrapper">
            <div class="stepper-header">
                <div class="stepper-header--item item-1"
                     [ngClass]="{'not-completed': step !== 1 && !user.wizard_section_status.app_visuals, 'active': step === 1, 'completed': step !== 1 && user.wizard_section_status.app_visuals}">
                    <span></span>
                    <div class="step-text"
                         [ngClass]="{'bold-text': user.wizard_section_status.app_visuals || step === 1}">
                        <p>STEP 1</p>
                        <h5>App visuals</h5>
                    </div>
                </div>
                <div class="stepper-line"><span></span></div>
                <div class="stepper-header--item item-2"
                     [ngClass]="{'not-completed': step !== 2 && !user.wizard_section_status.app_info, 'active': step === 2, 'completed': step !== 2 && user.wizard_section_status.app_info}">
                    <span></span>
                    <div class="step-text"
                         [ngClass]="{'bold-text': user.wizard_section_status.app_info || step === 2}">
                        <p>STEP 2</p>
                        <h5>App info</h5>
                    </div>
                </div>
                <div class="stepper-line"><span></span></div>


                <div class="stepper-header--item item-3"
                     [ngClass]="{'not-completed': step !== 3 && !user.wizard_section_status.your_info, 'active': step === 3, 'completed': step !== 3 && user.wizard_section_status.your_info}">
                    <span></span>
                    <div class="step-text"
                         [ngClass]="{'bold-text': user.wizard_section_status.your_info || step === 3}">
                        <p>STEP 3</p>
                        <h5>Your info</h5>
                    </div>
                </div>
                <div class="stepper-line"><span></span></div>

                <div class="stepper-header--item item-4"
                     [ngClass]="{'not-completed': step < 4  && !user.wizard_section_status.billing_info, 'active': step === 4 || step >= 5, 'completed': step < 4 && user.wizard_section_status.billing_info}">
                    <span></span>
                    <div class="step-text"
                         [ngClass]="{'bold-text': step === 4 || step === 5 || user.wizard_section_status.billing_info}">
                        <p>STEP 4</p>
                        <h5>Billing info</h5>
                    </div>
                </div>
            </div>

        </div>


        <div class="stepper-content" #stepperContent>
            <h4 #stepperHeading *ngIf="step < 6">{{ step < 5 ? stepHeadings[step - 1] : stepHeadings[step - 2]}}
                  <span
                  *ngIf="step >= 4 && step < 6">{{step === 4 ? ' 1/2' : ' 2/2'}}</span></h4>
            <h4 #stepperHeading *ngIf="step === 6">Cards</h4>
            <div class="form-row copy-from-row"
                 *ngIf="step === 4">
                <input name="exercise-is-free"
                       class="checkbox input-checkbox"
                       type="checkbox"
                       [class.true]="copyCheckbox"
                       [class.false]="!copyCheckbox"
                       [(ngModel)]="copyCheckbox"
                       (ngModelChange)="onCopyFromYourInfo()" />
                <label class="input-label">Use information from "Your info"</label>
            </div>
            <div class="stepper-content-wrapper step-{{step}}">
                <app-visuals [smallFileUpload]="true"
                             name="profileAppVisuals"
                             required
                             [submitted]="stepperForm.submitted"
                             [stepper]="true"
                             [(ngModel)]="appVisuals"
                             *ngIf="step === 1"></app-visuals>
                <profile-app-info [stepper]="true"
                                  [submitted]="stepperForm.submitted"
                                  required
                                  name="profileAppInfo"
                                  [removeBackground]="true"
                                  *ngIf="step === 2"
                                  [(ngModel)]="appInfo"></profile-app-info>

                <profile-owner-info [stepper]="true"
                                    name="profileOwnerInfo"
                                    required
                                    [submitted]="stepperForm.submitted"
                                    [(ngModel)]="ownerInfo"
                                    *ngIf="step === 3"></profile-owner-info>

                <profile-billing-info [step]="step"
                                      name="profileBillingInfo"
                                      required
                                      [showSaveCard]="false"
                                      [submitted]="stepperForm.submitted"
                                      [(ngModel)]="billingInfo"
                                      [stepper]="true"
                                      *ngIf="step >= 4"></profile-billing-info>
            </div>

        </div>
    </form>

    <div class="stepper-pagination" [ngClass]="{'scroll-border' : showStepperScrollLine}">
        <div class="left-buttons">
            <button class="button-back"
                    *ngIf="step !== 1"
                    (click)="onStepBack()">Back</button>
        </div>

        <div class="right-buttons">
            <button class="skip-button"
                    *ngIf="step < 4"
                    (click)="onSkipStep()">Skip</button>

            <button class="skip-button"
                    *ngIf="step >= 4"
                    (click)="onFinishWizzard()">Close</button>

            <button class="button-next"
                    form="stepper-form"
                    [disabled]="!stepperForm.valid"
                    type="submit"
                    *ngIf="step < 5">Next</button>

            <button class="button-true-green"
                    [disabled]="billingInfo?.cards.length === 0"
                    (click)="onFinishWizzard()"
                    *ngIf="step >= 5">Finish</button>

        </div>

    </div>
</div>
