import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import 'rrweb/dist/rrweb-all.min.css';

if (environment.production) {
    enableProdMode();
    const script = document.createElement('script');
    // the script url should be switched for production at some point of time
    // script.src = 'https://js.authorize.net/v1/Accept.js';
    script.src = 'https://jstest.authorize.net/v1/Accept.js';
    document.head.appendChild(script);
}else {
    const script = document.createElement('script');
    script.src = 'https://jstest.authorize.net/v1/Accept.js';
    document.head.appendChild(script);
}


platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
