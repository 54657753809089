import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
    selector: 'message-reaction',
    templateUrl: './message-reaction.component.html',
    styleUrls: ['./message-reaction.component.scss']
})

export class MessageReactionComponent {
    constructor() {}

    @Input() public messageId: number;
    @Input() public reactions: string
    @Output() public popoverOpen = new EventEmitter<MessageReactionComponent>(true);
    @Output() public reactionChoosed = new EventEmitter();

    @ViewChild(PopoverDirective)
    public pop: PopoverDirective;

    public chooseReaction(pop: PopoverDirective, reaction: string) {
        this.reactionChoosed.emit(reaction);
    }

    public close() {
        this.pop.hide();
    }

    public onOpen() {
        this.popoverOpen.emit(this);
    }
}
