import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { PageableComponent } from '@shared/pageable.component';
import { NotificationsService } from '@shared/notifications.service';
import { InteractionService } from '@app/interaction/interaction.service';

import { GalleryService, GalleryItem } from '../gallery.service';
import { stringify } from 'querystring';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent extends PageableComponent<GalleryItem> implements OnInit, OnDestroy {
    constructor(
        private galleryService: GalleryService,
        private interaction: InteractionService,
        private notification: NotificationsService) {
        super();
    }

    get maxUploadSize() { return this.galleryService.formattedBytes; }
    get files() { return this.fileList as any; }
    get uploadAmount() { return this._uploadAmount; }
    get currentUpload() { return this._uploadIndex + 1; }

    @Output() public itemSelected = new EventEmitter<GalleryItem>(true);

    @Input() public selected: GalleryItem;

    @Input() public type: 'all' | 'image' | 'video' | 'document' = 'all';

    @Input() public source: 'gallery' | 'avatar' = 'gallery';

    public fakePath: string;

    public fileName: string;

    public progress = 0;

    public reloadGallerySeconds = 15;

    public reloadInterval = null;

    public editMode = false;

    public ngOnInit() {
        super.ngOnInit();
        this.load();
    }

    public ngOnDestroy() {
        clearInterval(this.reloadInterval);
    }

    public switchPlayMode(item: GalleryItem) {
        item.playMode = !item.playMode;
    }

    public async onFixVideo(id: number){
        const { data, error } = await this.galleryService.fixVideo(id);
        if (!error){
           this.notification.success('Success', 'Video fixed');
        }
    }

    public isEditing(item: GalleryItem): boolean {
        return this.itemsEditMap.has(item) && this.itemsEditMap.get(item);
    }

    public startEditing(item: GalleryItem) {
        this.itemsEditMap.forEach((value, item) => {
            this.itemsEditMap.set(item, false);
        })
        this.itemsEditMap.set(item, true);
    }

    public async stopEditing(item: GalleryItem) {
        const { error } = await this.galleryService.update(item);
        if (!error) {
            this.itemsEditMap.set(item, false);
        }
    }

    public async load() {
        if (this.loading){
            return;
        }
        
        this.loading = true;
        setTimeout(async ()=>{
            const search = this.source === 'avatar' ? this.galleryService.searchAvatar : this.galleryService.search;
            const { data } = await search.call(
                this.galleryService,
                this.searchQuery,
                this._page,
                this.sortBy,
                this.type !== 'all' ? this.type : 'image,video'
            );
            this._list = data;
            this._list.data.filter(item => item.status === 'READY');
            this.loading = false;
            this.reloadGallery();
        }, 50)
    }

    public reloadGallery() {

        if (this.reloadInterval) {
            clearInterval(this.reloadInterval);
        }
        const reloadIn = 1000 * this.reloadGallerySeconds;

        this.reloadInterval = setInterval(() => {
            this.load();
        }, reloadIn);
    }

    public async crop(video: GalleryItem) {
        const res = await this.galleryService.openCropGalleryItemWindow(video);
        if (res) {
            const { data, error } = await this.galleryService.cropGalleryVideoItem(video, res);
            if (!error) {
                this.notification.success('Success', 'Videos is being croped...');
            } else {
                this.notification.success('Success', error.errorMessages.join('. '));
            }
        }
    }

    public async upload(file: File) {
        const item = file;
        const nameParts = item.name.split('.');
        nameParts.pop();
        this.fileName = nameParts.join('.');

        const res = await this.galleryService.upload(this.fileName, item, this.source === 'avatar', this.type);

        res.subscribe(
            res => {
                if (res.progress) {
                    this.progress = res.progress;
                } else if (res.response) {
                    this.progress = 0;
                    this._uploadIndex++;
                } else {
                    this.progress = 0;
                }
            },
            error => {
                this.progress = 0;
                this._uploadIndex++;
                this.multipleUpload();
                const err = error.status === 502 ? error.statusText : error.error.errorMessages.join('\r\n');
                this.notification.error('Error', err);
            },
            () => {
                this.fileName = '';
                this.multipleUpload();
            }
        );
    }

    public multipleUpload(files?){
        if(files){
            this.fileList = files;
        }
        if (this.fileList && this._uploadIndex < this.fileList.length){
            return this.upload(this.fileList[this._uploadIndex]);
        }
        this._uploadIndex = 0;
        this.fileList = null;
        this.load();
        return;
    }

    public async onFileChange(event) {
        this.fileList = event.target.files;
        let currentIndex = 0;
        let error = false;
        if (this.fileList.length > 0) {
            this.stopGalleryReloadInterval();
            const checkAllFileTypes = () => {
                if (currentIndex < this.fileList.length) {
                    if (this.fileList[currentIndex].size > this.galleryService.uploadLimits.upload_limit_in_bytes) {
                        this.notification.warning('File size', 'Max file size ' + this.maxUploadSize);
                        return;
                    }

                    if (!this.galleryService.isValidFileType(this.fileList[currentIndex], this.type)) {
                        this.notification.warning('Invalid file type', 'Acceptable file types are ' +
                            this.galleryService.getAcceptableFileTypes(this.type));
                        error = true;
                        return;
                    }
                    if (!error) {
                       currentIndex++;
                       return checkAllFileTypes();
                    }
                }
                if (!error && currentIndex === this.fileList.length){
                    this._uploadAmount = this.fileList.length;
                    this._uploadIndex = 0;
                    this.multipleUpload();
                }
            };
            if (currentIndex === 0){
                checkAllFileTypes();
            }
        }
    }

    public getDocumentType(type: string){
        let t = type.split('/')[1];
        return t;
    }

    public stopGalleryReloadInterval(){
        if (this.reloadInterval) {
            clearInterval(this.reloadInterval);
        }
    }

    public async delete(id: number) {
        const isConfirmed = await this.interaction.confirm(
            'Are you sure you want to delete file from gallery?'
        );
        if (isConfirmed) {
            await this.galleryService.delete(id);
            this.load();
        }
    }

    public select(item: GalleryItem) {
        this.selected = item;
        this.itemSelected.emit(item);
    }

    private fileList: FileList;
    private _uploadIndex = 0;
    private _uploadAmount = 0;
    private itemsEditMap = new Map<GalleryItem, boolean>();
}
