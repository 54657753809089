import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import { darkAccentColors, lightAccentColors } from '../colors';

@Component({
    selector: 'app-onboarding-wizard',
    templateUrl: './onboarding-wizard.component.html',
    styleUrls: ['./onboarding-wizard.component.scss']
})
export class OnboardingWizardComponent implements OnInit {

    constructor(private profileService: ProfileService) { }

    public async ngOnInit() {
        const { data, error } = await this.profileService.getPwaAssets();
        if (!error) {
            const color = data.theme === 'dark' ? darkAccentColors.find(c => c.color === data.primary_color) :
                lightAccentColors.find(c => c.color === data.primary_color);
            this.profileService.pwaAssets.next({
                home_background: data.home_background,
                logo: data.logo,
                onboarding_1: data.onboarding_1,
                onboarding_2: data.onboarding_2,
                onboarding_3: data.onboarding_3,
                primary_color: color,
                splash_screen: data.splash_screen,
                theme_color: data.theme
            });
        }
    }

}
