import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../profile.service';
import { Router } from '@angular/router';
import { AuthorizationService } from '@shared/authorization.service';
import { AppReadyComponent } from '../app-ready/app-ready.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-uploading-assets',
    templateUrl: './uploading-assets.component.html',
    styleUrls: ['./uploading-assets.component.scss']
})
export class UploadingAssetsComponent implements OnInit {

    constructor(
        private profileService: ProfileService,
        private router: Router,
        private auth: AuthorizationService,
        private modalService: BsModalService) { }

    public uploadPercent = 0;
    public includedCustomApp = false;
    public isAdvancedFlow = false;

    public async ngOnInit() {
        this.auth.user.subscribe((user) => {
            if (user) {
                this.includedCustomApp = user.coach_package?.include_custom_application;
                this.isAdvancedFlow = user.coach_package?.pwa_wizard_advanced_flow;
            }
        });
        const intId = setInterval(() => {
            if (this.uploadPercent === 100) {
                clearInterval(intId);
                if (this.isAdvancedFlow) {
                    this.router.navigate(['/profile/onboarding-wizard/app-ready']);
                } else {
                    this.continueToDashboard();
                }
            } else {
                this.uploadPercent++;
            }
        }, 130);
        this.profileService.pwaAssets.subscribe(async res => {
            if (res.theme_color) {
                const { data, error } = await this.profileService.setPwaAssets(res);
                if (!error) {
                    await this.generate();
                }
            } else {
                this.router.navigate(['/profile/onboarding-wizard/lets-begin']);
            }
        });
    }

    private async generate() {
        const { data, error } = await this.profileService.generatePwaApp();
        if (!error) {
            sessionStorage.setItem('show-wizard', 'true');
            this.profileService.appVisualsCompleted.next(true);
        } else {
            this.profileService.appVisualsCompleted.next(false);
        }
    }
    private continueToDashboard() {
        this.router.navigate(['/profile/onboarding-wizard/owner-info']);
        // const initialState = {
        //     isModal: true
        // };
        // this.modalService.show(AppReadyComponent,
        //     { initialState, backdrop: true, ignoreBackdropClick: false, class: 'modal-app-ready' });
    }
}
