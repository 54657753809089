import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { SharedModule } from '@shared/shared.module';
import { InteractionModule } from '@app/interaction/interaction.module';

import { GalleryComponent } from './gallery/gallery.component';
import { GalleryService } from './gallery.service';
import { GalleryModalComponent } from './gallery-modal/gallery-modal.component';
import { GalleryItemComponent } from './gallery-item/gallery-item.component';
import { GalleryVideoCropComponent } from './gallery-video-crop/gallery-video-crop.component';

@NgModule({
    declarations: [GalleryComponent, GalleryModalComponent, GalleryItemComponent, GalleryVideoCropComponent],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        InteractionModule,
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot()
    ],
    exports: [GalleryItemComponent],
    providers: [GalleryService]
})
export class GalleryModule { }
