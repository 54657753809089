<div class="column">
    <h5 class="create-new-thread-heading">
        <span>Messsages</span>
        <button *ngIf="createNewButton"
                class="button-true-green"
                (click)="onShowContactForm()">New message</button>
    </h5>
    
    <div class="message-box">
        <span class="link-back"
            *ngIf="!showCreateNew"
            (click)="onBackToContactList()">
            <img src="../../assets/icons/ic_arrow_left-2.svg" />Conversations</span>
        
        <!-- THREAD CONTACTS -->
        <div class="content-left client-contacts"
            [ngClass]="{'hide-section': !showCreateNew}">
            <div class="conversation-heading">
                <h5>Conversations</h5>
                <span>All your conversations will be shown here.</span>
                <div class="search-wrapper users">
                    <input id="search"
                        name="search"
                        class="text-input"
                        type="text"
                        [(ngModel)]="searchQuery"
                        (ngModelChange)="searchQueryStream.next($event)"
                        placeholder="Search users" />
                    <label for="search"></label>
                    <button class="button-clear"
                            [ngClass]="{'active': searchQuery}"
                            (click)="clearSearch()"></button>
                </div>
            </div>
            
            <div class="content-body threads-list"
                [ngClass]="{'center-content': !list.length, 'no-search-result' : noSearchResult}">
                <div class="no-conversations"
                    *ngIf="!list.length">
                    <img src="../../assets/icons/ic_no_conversations.svg" />
                    <h6>No conversations</h6>
                    <span>There is currently no conversation between you and your clients.</span>
                </div>
                
                <ng-container>
                    <div class="contact-item"
                        [ngClass]="{'selected': selectedContactThread?.id === contactThread.id, 'thread-has-new-message': contactThread.unread_messages > 0}"
                        (click)="onSelectedContactThread(contactThread)"
                        *ngFor="let contactThread of conversations">
                        <h6>
                            <div class="thread-avatar"
                                *ngIf="contactThread.user_avatar"
                                [ngStyle]="{'background-image': 'url(' + contactThread.user_avatar + ')'}"></div>
                            <div class="thread-no-avatar"
                                *ngIf="!contactThread.user_avatar"
                                [ngStyle]="{'background-image': 'url(' + defaultAvatar + ')'}"></div>
                            <div>
                                <div class="thread-user">{{ contactThread.user_name }}
                                    <span class="last-message-timestamp" 
                                          *ngIf="contactThread.last_message_at.substring(0, 10) == today">
                                            {{ contactThread.last_message_at | dateFormat:'shortTime' }}
                                    </span>
                                    <span class="last-message-timestamp" 
                                          *ngIf="contactThread.last_message_at.substring(0, 10) != today">
                                            {{ contactThread.last_message_at | dateFormat:'MM/dd/yy' }}
                                    </span>
                                </div>
                                <span class="noth-option-1"
                                    *ngIf="contactThread.unread_messages > 0">{{ contactThread.unread_messages }}
                                </span>
                                <span>{{ contactThread.last_message | slice:0:30 }}
                                    <span *ngIf="contactThread.last_message.length > 30">
                                        ...
                                    </span>
                                </span>
                            </div>
                        </h6>
                    </div>
                    
                    <div class="no-search-result"
                        *ngIf="noSearchResult">
                        <img src="../../assets/icons/ic_no_search_results.svg" />
                        <h6>No search results</h6>
                        <p>No results found for this search.</p>
                    </div>
                </ng-container>
                
                <button *ngIf="showLoadConversations && conversations.length === list.length"
                        class="button-true-green"
                        (click)="loadMoreContactThreads()">Load more</button>
            </div>
        </div>
        
        <!--  CREATE NEW MESSAGE FORM -->
        <div class="content-right new-message"
            *ngIf="showCreateNewForm">
            <div class="new-thread-heading">
                <h5>New Message</h5>
            </div>
            
            <div class="content-body new-thread">
                <form id="new-thread-form"
                    name="new-thread"
                    #newMessageForm="ngForm"
                    (submit)="!newMessageForm.invalid && onNewRecipientMessageSubmit(newMessageForm)">
                    <div>
                        <div class="form-row-input">
                            <p class="input-label">Recipient</p>
                            <ng-select [multiple]="false"
                                    name="contact"
                                    class="select-input contact-dropdown"
                                    placeholder="Enter name of the client you want to start a conversation with, leave empty to broadcast to all"
                                    [items]="users"
                                    bindLabel="fullname"
                                    searchable="true"
                                    [typeahead]="searchContact"
                                    (focus)="searchContact.next('')"
                                    [(ngModel)]="newContact"
                                    [ngClass]="{ 'form-submitted': newMessageForm.submitted }">
                                <ng-template ng-label-tmp
                                            let-item="item"
                                            let-index="index">
                                    <div class="contact-dropdown-selected">
                                        <span
                                            [ngStyle]="{'background-image': 'url(' + item.avatar || defaultAvatar +  ')'}"></span>
                                        <p>{{ item.first_name }} {{ item.last_name }}</p>
                                        <p (click)="removeRecipient()">x</p>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp
                                            let-item="item"
                                            let-index="index">
                                    <span class="contact-dropdown-image"
                                        [ngStyle]="{'background-image': 'url(' + item.avatar || defaultAvatar +  ')'}"></span>
                                    <b>{{ item.first_name }} {{ item.last_name }}</b>
                                </ng-template>
                            </ng-select>
                        </div>
                        
                        <div class="form-row">
                            <p class="input-label">Message</p>
                            <textarea name="message"
                                    placeholder="Type your message here"
                                    [(ngModel)]="messageText"
                                    [ngClass]="{ 'form-submitted': newMessageForm.submitted }"
                                    required></textarea>
                        </div>
                    </div>
                    
                    <!-- <attach-files></attach-files> -->
                    <button class="button-true-green"
                            [disabled]="!newMessageForm.valid"
                            type="submit">Send</button>
                </form>
            </div>
        </div>
        
        <!--  MESSAGES -->
        <div class="content-right"
            *ngIf="!showCreateNewForm"
            [ngClass]="{'hide-section': showCreateNew}">
            <div class="thread-heading">
                <h5>
                    <div class="contact-avatar"
                        [ngStyle]="{'background-image': 'url(' + (selectedContactThread.user_avatar || defaultAvatar) + ')'}">
                        <a *ngIf="selectedContactThread.user_roles.includes('customer') && !selectedContactThread.user_roles.includes('tester')" 
                           class="contact-name"
                           title="details" 
                           [routerLink]="['/user/user/details', selectedContactThread.user_id]">
                            {{ selectedContactThread.user_name }}
                        </a>
                        <span *ngIf="!selectedContactThread.user_roles.includes('customer') || selectedContactThread.user_roles.includes('tester')"
                              class="contact-name">
                            {{ selectedContactThread.user_name }}
                        </span>
                        
                        <div class="contact-details">
                            <span *ngIf="selectedContactThread.user_age">
                                <span class="label">Age</span>
                                <span class="value">{{ selectedContactThread.user_age }}</span>
                            </span>
                            
                            <span *ngIf="selectedContactThread.user_height_in_centimeters">
                                <span class="label">Height</span>
                                <span class="value">{{ selectedContactThread.user_height_in_centimeters + ' cm' }}</span>
                            </span>

                            <span *ngIf="selectedContactThread.user_weight_in_kilograms">
                                <span class="label">Weight</span>
                                <span class="value">{{ selectedContactThread.user_weight_in_kilograms + ' kg' }}</span>
                            </span>
                        </div>
                    </div>
                </h5>
                
                <ng-template #userName>
                    {{ selectedContactThread.user_name }}
                </ng-template>
            </div>
            
            <div class="content-body messages-wrapper">
                <div class="message-list">
                    <button *ngIf="showLoadMoreMessages"
                            class="button-true-green load-more"
                            (click)="loadMoreContactMessages()">Load previous messages</button>
                    <div class="no-new-messages"
                        *ngIf="messages.length === 0 && selectedContactThread">
                        <img src="../../assets/icons/ic_no_conversations.svg" />
                        <h6>No conversation</h6>
                        <span>There is currently no conversation between you and {{ selectedContactThread.user_name
                            }}.</span>
                    </div>
                    
                    <ng-container *ngFor="let message of messages">
                        <div class="message"
                            [ngClass]="{'indent': message.sender !== 'owner'}">
                            <div class="message-avatar"
                                [ngStyle]="{'background-image': 'url(' + (message.user_avatar || defaultAvatar) + ')'}">
                            </div>
                            
                            <div class="message-text">
                                <h6>
                                    <a *ngIf="message.sender !== 'owner' && selectedContactThread.user_roles.includes('customer') && !selectedContactThread.user_roles.includes('tester')"
                                       title="details" 
                                       [routerLink]="['/user/user/details', message.user_id]">
                                            {{ message.user_name }}
                                    </a>
                                    <span *ngIf="message.sender === 'owner' || !selectedContactThread.user_roles.includes('customer') || selectedContactThread.user_roles.includes('tester')"
                                          class="contact-name">
                                            {{ message.user_name }}
                                    </span>
                                    <span> {{ message.created_at | dateFormat }} </span>
                                    <span *ngIf="message.sender === 'owner'" class="badge-admin">Admin</span>
                                </h6>

                                <p class="message">
                                    <span class="message-content"
                                          [ngClass]="{'deleted' : message.is_message_deleted}">
                                        {{message.message}}
                                    </span>
                                    
                                    <img *ngIf="message.sender === 'owner' && !message.is_message_deleted" 
                                         src="../../assets/icons/ic_delete.svg"
                                         title="Delete this message"
                                         (click)="onDeleteMessage(message.id)" />

                                    <img *ngIf="message.sender !== 'owner' && !message.is_message_unread" 
                                         src="../../assets/icons/ic_mark_as_unread.svg"
                                         title="Mark this message as unread"
                                         (click)="onMarkMessageUnread(message.id)" />

                                    <message-reaction [messageId]="message.id"
                                                      [reactions]="availableReactions"
                                                      (reactionChoosed)="onPostReaction(message.id, $event)"></message-reaction>
                                </p>
                                
                                <p class="attachment input-label"
                                   *ngFor="let file of message.attachments">
                                    <a href="{{file.attachment.url}}"
                                    target="_blank"
                                    download="{{ file.attachment.name }}">{{file.attachment.name}}</a>
                                    <img *ngIf="message.sender === 'owner'"
                                        src="../../assets/icons/delete.png"
                                        (click)="onDeleteAttachment(file)" />
                                </p>

                                <p class="reactions">
                                   <span *ngFor="let reaction of message.reactions"
                                         class="reaction">
                                        <span class="reaction-content"
                                              (click)="onPostReaction(message.id, reaction.content)">
                                              {{ reaction.content }}
                                        </span> {{ reaction.count }}
                                   </span>
                                </p>
                            </div>
                        </div>
                    </ng-container>
                </div>
                
                <form id="message-form"
                    name="create"
                    #clientMessageForm="ngForm">
                    <textarea type="text"
                            class="message-input input"
                            name="messageText"
                            maxlength="4000"
                            [required]="uploadedFiles.length === 0"
                            [(ngModel)]="messageText"
                            [ngClass]="{'form-submitted': clientMessageForm.submitted}"></textarea>
                    <attach-files [files]="uploadedFiles"
                                (onRemove)="onRemoveAttachment($event)"
                                [totalUploads]="fileList.length"
                                (onAddFiles)="onAttachedFiles($event)"
                                [progress]='progress'
                                [current]="(uploadCounter)"></attach-files>
                    <button type="button"
                            #sendMessageButton
                            class="button-true-green"
                            [disabled]="!clientMessageForm.valid"
                            (click)="!clientMessageForm.invalid && sendMesssage()">Send</button>
                </form>
            </div>
        </div>
    </div>
</div>