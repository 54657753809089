import { Component, OnInit } from '@angular/core';
import { GalleryItem } from '@app/gallery/gallery-item';
import { AuthorizationService } from '@shared/authorization.service';
import { AppVisualsV2, ProfileService } from '../../profile.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-upload-logo',
    templateUrl: './upload-logo.component.html',
    styleUrls: ['./upload-logo.component.scss']
})
export class UploadLogoComponent implements OnInit {

    constructor(
        private profileService: ProfileService,
        private auth: AuthorizationService,
        private router: Router,
        private route: ActivatedRoute) { }

    get user(){ return this.auth.user.getValue() }

    public logo = null;
    public createLogoFromName = false;
    public brandName = null;
    public themeColor: 'light' | 'dark' = null;
    public includedCustomApp = false;
    public isAdvancedFlow = false;
    public fromDashboard = false;

    public ngOnInit(): void {

        this.route.paramMap.subscribe(params => {
            if (!params.has('dashboard')) {
                this.fromDashboard = false;
                return;
            }
            this.fromDashboard = true;
        });

        this.profileService.pwaAssets.subscribe(res => {
            this.logo = res.logo;
            this.themeColor = res.theme_color;
            this._pwaAssets = res;
            if (!res.splash_screen) {
                // this.router.navigate(['/profile/onboarding-wizard/lets-begin']);
            }
        });

        this.auth.user.subscribe((user) => {
            if (user) {
                this.brandName = user.application_name;
                this.includedCustomApp = user.coach_package?.include_custom_application;
                this.isAdvancedFlow = user.coach_package?.pwa_wizard_advanced_flow;
            }
        });
    }

    public async uploadedLogo($event: GalleryItem) {
        if (!$event) {
            this.createLogoFromName = false;
        }
        this.profileService.pwaAssets.next({
            home_background: this._pwaAssets.home_background,
            onboarding_1: this._pwaAssets.onboarding_1,
            onboarding_2: this._pwaAssets.onboarding_2,
            onboarding_3: this._pwaAssets.onboarding_3,
            primary_color: this._pwaAssets.primary_color,
            splash_screen: this._pwaAssets.splash_screen,
            theme_color: this._pwaAssets.theme_color,
            logo: $event
        });
    }

    public onCreateLogoFromName() {
        this.logo = null;
        this.createLogoFromName = !this.createLogoFromName;
    }

    public async continue() {
        if (this.includedCustomApp && !this.isAdvancedFlow) {
            const { data, error } = await this.profileService.setPwaAssets(this._pwaAssets);
            if (!error) {
                await this.generate();
                this.router.navigate(['/profile/onboarding-wizard/app-info']);
            }
        }
        else if (!this.includedCustomApp && !this.isAdvancedFlow) {
            this.router.navigate(['/profile/onboarding-wizard/owner-info']);
        }
        else if(this.includedCustomApp && this.isAdvancedFlow){
            this.router.navigate(['/profile/onboarding-wizard/uploading-assets']);
        }
        else if (!this.includedCustomApp && this.isAdvancedFlow) {
            this.router.navigate(['/profile/onboarding-wizard/owner-info']);
        }
    }

    public async saveChanges() {
        const { data, error } = await this.profileService.setPwaAssets(this._pwaAssets);
        if (!error) {
            await this.generate();
            this.router.navigate(['/']);
        }

    }

    private async generate() {
        const { data, error } = await this.profileService.generatePwaApp();
        if (!error) {
            this.profileService.appVisualsCompleted.next(true);
        } else {
            this.profileService.appVisualsCompleted.next(false);
        }
    }

    private _pwaAssets: AppVisualsV2 = null;

}
