import { Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { GalleryItem } from '@app/gallery/gallery-item';
import { NotificationsService } from '@shared/notifications.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PreviewModulatorModalComponent } from '../preview-modulator/preview-modulator.component';
import { AppVisuals, ProfileService } from '../profile.service';
import { Modulator, ModulatorRequest } from '@app/applications/types';
import { AppTheme } from '../types';
import { AuthorizationService, User } from '@shared/authorization.service';
import { darkAccentColors, lightAccentColors } from '../colors';
@Component({
    selector: 'app-visuals',
    templateUrl: './app-visuals.component.html',
    styleUrls: ['./app-visuals.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppVisualsComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: AppVisualsComponent,
            multi: true,
        },
    ],
})
export class AppVisualsComponent
    implements ControlValueAccessor, Validator, OnInit {
    constructor(
        private profileService: ProfileService,
        private notification: NotificationsService,
        private modalService: BsModalService,
        private auth: AuthorizationService
    ) { }

    @Input() stepper = false;
    @Input() submitted = false;
    @Input() customClass = '';

    @Input() smallFileUpload = true;

    public get user() { return this._user; }
    public set user(value) { this._user = value; }

    get brandName() { return this._brandName; }
    set brandName(value) { this._brandName = value; }

    get appVisuals() { return this._appVisuals; }
    set appVisuals(value) { this._appVisuals = value; }

    public disableForm = false;

    public themes: AppTheme[] = [
        {
            color: '#FFFFFF',
            name: 'Light Theme',
            value: 'light-theme',
            desc: ''
        }, {
            color: '#232323',
            name: 'Dark Theme',
            value: 'dark-theme',
            desc: ''
        }
    ];

    public accentColors: AppTheme[] = [];

    public isDisabled = false;

    ngOnInit(): void {
        this.auth.user.subscribe((user) => {
            if (user) {
                this._user = user;
                this.brandName = user.application_name;
            }
        });
        this.load();
    }

    public onValidatorChange: () => void = () => { };
    public onTouched: () => void = () => { };
    public onChange: (value: AppVisuals) => void = (value: AppVisuals) => { };

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    public validate(control: AbstractControl): ValidationErrors {
        if (!this.appVisuals) {
            return { appVisuals: 'App visuals is required' };
        } else if (!this.appVisuals.logo) {
            return { logo: 'Logo is required' };
        } else if (this.appVisuals.gallery.length === 0) {
            return { gallery: 'One or more images are required' };
        } else if (!this.appVisuals.accent_color && !this.appVisuals.our_design) {
            return { accent_color: 'Accent color is required' };
        }
        return null;
    }

    public uploadedLogo($event: GalleryItem) {
        this.appVisuals.logo = $event;
        this.updateModulatorData();
        this.onTouched();
        this.onChange(this.appVisuals);
    }

    public writeValue(obj: AppVisuals): void {
        if (obj) {
            this._appVisuals = obj;

            if (obj.theme_color && obj.theme_color.value === 'light-theme') {
                this.accentColors = this._lightAccentColors;
            } else if (obj.theme_color && obj.theme_color.value === 'dark-theme') {
                this.accentColors = this._darkAccentColors;
            }

        } else {
            this._appVisuals = {
                logo: null,
                is_locked: false,
                gallery: [],
                accent_color: null,
                is_from_app_visuals: true,
                modulator_data: null,
                our_design: false,
                theme_color: null
            };
        }
    }

    public async load() {
        const { data, error } = await this.profileService.getAppVisuals();
        if (!error) {
            this.appVisuals = data;
            if (data.modulator_data) {
                this._modulator = data.modulator_data.data;
            }

            if (!this._modulator) {
                this.updateModulatorData();
            }

            if (data.theme_color && data.theme_color.value === 'light-theme') {
                this.accentColors = this._lightAccentColors;
            } else if (data.theme_color && data.theme_color.value === 'dark-theme') {
                this.accentColors = this._darkAccentColors;
            }

            if (JSON.stringify(data.logo) === '{}') {
                this.appVisuals.logo = null;
            }
        }
        this.onTouched();
        this.onChange(this.appVisuals);
    }

    public onChangeImageModel() {
        this._appVisuals.gallery = this._appVisuals.gallery.filter(
            (img: GalleryItem) => img !== null
        );
        this.updateModulatorData();
        this.onTouched();
        this.onChange(this._appVisuals);
    }

    public onUploadedFile($event) {
        this._appVisuals.gallery = this._appVisuals.gallery.filter(
            (img: GalleryItem) => img !== null
        );
        this.updateModulatorData();
        this.onTouched();
        this.onChange(this._appVisuals);
    }

    public trackByFn(i: number) {
        return i;
    }

    public changedTheme() {
        if (this.appVisuals.theme_color.value === 'light-theme') {
            this.accentColors = this._lightAccentColors;
            this.appVisuals.accent_color = null;
        } else {
            this.accentColors = this._darkAccentColors;
            this.appVisuals.accent_color = null;
        }
        this.updateModulatorData();
        this.onTouched();
        this.onChange(this.appVisuals);
    }

    public weChooseChange(checked: boolean) {
        if (checked) {
            this.appVisuals.theme_color = null;
            this.appVisuals.accent_color = null;
        }
        this.appVisuals.our_design = checked;
        this.updateModulatorData();
        this.onTouched();
        this.onChange(this.appVisuals);
    }

    public accentColorChanged() {
        this.updateModulatorData();
        this.onTouched();
        this.onChange(this.appVisuals);
    }

    public showModal() {
        this.updateModulatorData();
        const initialState: { modulator: Modulator } = {
            modulator: {
                aboutBrand: this._modulator.aboutBrand,
                colorPalette: {
                    cssClass: this._modulator.colorPaletteId,
                    description: null,
                    name: null
                },
                fontFamily: this._modulator.fontFamily,
                mockupImages: this._modulator.mockupImages ? this._modulator.mockupImages.map(i => {
                    return {
                        id: i.id,
                        name: null,
                        url: i.url
                    };
                }) : [],
                mockupVideo: this._modulator.mockupVideo,
                svgLogo: this._modulator.defaultLogoName
            }
        };
        this.bsModalRef = this.modalService.show(PreviewModulatorModalComponent, { initialState, class: 'modal-lg-custom modulator-preview-modal' });
    }

    public async onFormSubmit() {
        if (!this.appVisuals.gallery.length && !this.appVisuals.logo && !this.appVisuals.theme_color && !this.appVisuals.accent_color) {
            return;
        }
        await this.update();
    }

    private updateModulatorData() {
        this._modulator = {
            aboutBrand: {
                brandName: this.brandName,
                logo: {
                    id: this.appVisuals.logo ? this.appVisuals.logo.id : null,
                    url: this.appVisuals.logo ? this.appVisuals.logo.url : null,
                }
            },
            colorPaletteId: this.appVisuals.accent_color ? this.appVisuals.accent_color.value : null,
            fontFamily: 'montserrat',
            mockupImages: this.appVisuals.gallery ? this.appVisuals.gallery.map(g => {
                return {
                    id: g.id,
                    name: g.name,
                    url: g.url
                };
            }) : [],
            mockupVideo: null,
            defaultLogoName: null
        };
        this.appVisuals.modulator_data = {
            created_at: null,
            data: this._modulator
        };
    }

    private async update(): Promise<boolean> {
        const imageIds = this.appVisuals.gallery.map((img: GalleryItem) => img.id);
        const logoId = this.appVisuals.logo.id;
        if (!imageIds.length || !logoId) {
            return false;
        }
        const { data, error } =
            await this.profileService.updateAppVisuals(
                logoId,
                imageIds,
                this.appVisuals.theme_color,
                this.appVisuals.accent_color,
                this._modulator,
                this.appVisuals.our_design);
        if (!error) {
            if (!this.stepper) {
                this.notification.success('Success', 'App visuals saved');
            }
            this.profileService.appVisualsCompleted.next(true);
            return true;
        } else {
            this.profileService.appVisualsCompleted.next(false);
            this.notification.success('Error', error.errorMessages.join('\n'));
            return false;
        }
    }

    private _appVisuals: AppVisuals = {
        logo: null,
        is_locked: false,
        gallery: [],
        accent_color: null,
        is_from_app_visuals: true,
        modulator_data: null,
        our_design: false,
        theme_color: null
    };

    private bsModalRef: BsModalRef;

    private _brandName = '';
    private _user: User;

    private _modulator: ModulatorRequest = {
        aboutBrand: {
            brandName: '',
            logo: {
                id: null,
                url: ''
            }
        },
        colorPaletteId: '',
        fontFamily: 'montserrat',
        mockupImages: [],
        mockupVideo: null,
        defaultLogoName: ''
    };

    private _lightAccentColors: AppTheme[] = lightAccentColors;
    private _darkAccentColors: AppTheme[] = darkAccentColors;
}
