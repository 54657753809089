import { Pipe, PipeTransform, InjectionToken, Inject, Optional, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';

export interface DateFormatSettings {
    dateTimeFormat: string;
    timeFormat: string;
    dateFormat: string;
}

export type DateMomentFormatType = 'dateTime' | 'date' | 'time';

export const DATE_FORMAT_PIPE_FORMATS = new InjectionToken<DateFormatSettings>('dateFormatPipe.formatSettings');

@Pipe({ name: 'dateFormat', pure: false })
export class DateFormatPipe implements PipeTransform {
    constructor(
        @Optional() @Inject(DATE_FORMAT_PIPE_FORMATS) private _formatSettings: DateFormatSettings,
        @Inject(LOCALE_ID) private locale: string
    ) { }

    public transform(date: Date | string | number, format?: DateMomentFormatType | string): string {
        if (!date) {
            return '';
        }

        if(date.toString().includes('[')){
            return '';
        }

        let dateFormat = this._formatSettings.dateTimeFormat;

        if (format === 'dateTime') {
            dateFormat = this._formatSettings.dateTimeFormat;
        } else if (format === 'date') {
            dateFormat = this._formatSettings.dateFormat;
        } else if (format === 'time') {
            dateFormat = this._formatSettings.timeFormat;
        } else if (typeof format === 'string' && format.length > 0) {
            dateFormat = format;
        }

        if (typeof date === 'string') {
            date = date.replace(' ', 'T');
        }

        return formatDate(date, dateFormat, this.locale);
    }
}

