import { Injectable } from '@angular/core';

import { FitApiClientService, FitList } from '@shared/fit-api-client.service';

interface GoalSearch {
    id: number;
    name: string;
    order: number;
}

interface GoalResponse extends GoalSearch {
    description: string;
}

interface GoalRequest {
    name: string;
    order: number;
    description: string;
}

export { FitList, GoalSearch, GoalResponse };

@Injectable({ providedIn: 'root' })
export class GoalService {
    constructor(private fitApi: FitApiClientService) { }

    public search(query: string, page: number) {
        return this.fitApi.get<FitList<GoalSearch>>('/admin/goal', {
            search: query,
            page: page.toString()
        });
    }
    public get(id: number) {
        return this.fitApi.get<GoalResponse>('/admin/goal/' + id);
    }
    public create(goal: GoalResponse) {
        return this.fitApi.put<GoalResponse>('/admin/goal/0', this.mapToRequest(goal));
    }
    public update(goal: GoalResponse) {
        return this.fitApi.put<GoalResponse>('/admin/goal/' + goal.id, this.mapToRequest(goal));
    }
    public delete(id: number) {
        return this.fitApi.delete<any>('/admin/goal/' + id);
    }

    private mapToRequest(goal: GoalResponse): GoalRequest {
        return {
            name: goal.name,
            description: goal.description,
            order: goal.order
        }
    }
}
