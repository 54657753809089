import { Component, Input, OnInit } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';

export interface ClientMessage {
    user_id: number;
    message: string;
    attachment_ids?: number[];
}

@Component({
    selector: 'resolution-center',
    templateUrl: './resolution-center.component.html',
    providers: [BsModalService],
    styleUrls: ['./resolution-center.component.scss']
})
export class ResolutionCenterComponent implements OnInit {

    constructor() { }


    @Input() public fromCancelationCentre;

    @Input() public fromModalCentre;

    @Input() public fromCoachCentre;

    @Input() public fromClientCentre;

    @Input() public fromRootCentre;

    @Input() public scrollToForm;

    @Input() public appId;

    @Input() public customerName;

    @Input() public customerLastname;

    @Input() public getModalUserId;

    @Input() public appActivated;


    ngOnInit(): void {

    }

}
