import { Injectable } from '@angular/core';

import { FitApiClientService, FitList } from '@shared/fit-api-client.service';

interface EnvironmentSearch {
    id: number;
    name: string;
    order: number;
}

export interface EnvironmentResponse extends EnvironmentSearch {
    description: string;
}

interface EnvironmentRequest {
    name: string;
    description: string;
    order: number;
}

export { FitList, EnvironmentSearch };

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
    constructor(private fitApi: FitApiClientService) { }

    public search(query: string, page: number) {
        return this.fitApi.get<FitList<EnvironmentSearch>>('/admin/exercise-environment', {
            search: query,
            page: page.toString()
        });
    }

    public all() {
        //return this.fitApi.get<FitList<EnvironmentSearch>>('/admin/exercise-environment/list');
        return this.fitApi.get<any>('/admin/exercise-environment/list');
    }

    public get(id: number) {
        return this.fitApi.get<EnvironmentResponse>('/admin/exercise-environment/' + id);
    }

    public create(environment: EnvironmentResponse) {
        return this.fitApi.put<EnvironmentResponse>('/admin/exercise-environment/0', this.mapToRequest(environment));
    }

    public update(environment: EnvironmentResponse) {
        return this.fitApi.put<EnvironmentResponse>('/admin/exercise-environment/' + environment.id, this.mapToRequest(environment));
    }

    public delete(id: number) {
        return this.fitApi.delete<any>('/admin/exercise-environment/' + id);
    }

    private mapToRequest(environment: EnvironmentResponse): EnvironmentRequest {
        return {
            name: environment.name,
            order: environment.order,
            description: environment.description
        };
    }
}
