<div class="gallery-video-crop-wrapper">
    <div class="gallery-video-crop-header">
        <h4>Cropping video</h4>
        <img class="close-modal"
             (click)="video.pause(); item.playMode = false; bsModalRef.hide()"
             src="assets/icons/clear.png" />
    </div>
    <div class="gallery-video-crop-body text-center">
        <area-marker [nativeWidth]="video.videoWidth"
                     [nativeHeight]="video.videoHeight"
                     (dimensions)="cropDimensionsChange($event)">
            <video #video
                   (ended)="item.playMode = false">
                <source [src]="item.url"
                        [type]="item.mime_type">
                Your browser does not support the video tag.
            </video>
        </area-marker>
        <button *ngIf="!item.playMode"
                style="background: #000;"
                type="button"
                class="button-gallery"
                (click)="video.play(); switchPlayMode(item)">
            <img src="assets/icons/play-white.png" />
        </button>
        <button *ngIf="item.playMode"
                style="background: #f2f3f5;"
                type="button"
                class="button-gallery"
                (click)="video.pause(); switchPlayMode(item)">
            <img src="assets/icons/pause.png" />
        </button>
    </div>
    <div class="gallery-video-crop-footer">
        <button class="button-true-green"
                type="button"
                (click)="video.pause(); item.playMode = false; close()">Crop</button>
    </div>
</div>
