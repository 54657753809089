<div class="wizard-box">
    <div class="container">
        <div class="wizard-box-stepper-box">
            <div *ngIf="!fromDashboard"
                 [ngClass]="{'included-app': includedCustomApp}"
                 class="stepper-box third-step"></div>
            <a [routerLink]="fromDashboard ? '/' : '/profile/onboarding-wizard/primary-color'"
               class="back-btn"
               [ngClass]="{'top-0' : fromDashboard}"><img src="../../assets/icons/ic_arrow_left-2.svg" /><span class="back-arrow-text">{{ fromDashboard ? 'Back to dashboard' : 'Back' }}</span></a>
                    <ng-container *ngIf="!fromDashboard">
                        <h6>Step 3 of {{ !includedCustomApp ? '6' : '8' }}</h6>
                    </ng-container>
                    <ng-container *ngIf="fromDashboard">
                        <h6>Step 3</h6>
                    </ng-container>
        </div>
        <div class="wizard-box-content">
            <h2>Upload your images</h2>
            <p *ngIf="!includedCustomApp"
               class="mb-0">These images will be shown on the screens within your branded web app.</p>
            <p *ngIf="includedCustomApp"
               class="mb-0">These images will be shown on the screens within your personalized fitness app.</p>
            <p class="m-0">Upload 1080px x 1920px portrait images for best results.</p>
            <div class="theme-color-content flex-wrap">
                <form #appVisualsForm="ngForm"
                      class="w-100">
                    <div class="app-visuals flex-column">
                        <div class="d-flex w-100 images-and-preview-box">
                            <div class="logo-wrap">
                                <div class="form-row logo">
                                    <div class="logo-upload-wrapper">
                                        <file-upload [id]="'splash_screen'"
                                                     name="splash_screen"
                                                     [required]="true"
                                                     [type]="'image'"
                                                     [manualClass]="'splash-screen'"
                                                     [themeClass]="pwaAssets.theme_color"
                                                     [primaryColor]="pwaAssets.primary_color?.color"
                                                     [isAvatar]="true"
                                                     [ngClass]="{'form-submitted': appVisualsForm.submitted }"
                                                     [(ngModel)]="pwaAssets.splash_screen"
                                                     (uploadedFile)="uploadedSplashScreen($event)"></file-upload>
                                    </div>
                                </div>
                            </div>
                            <div class="logo-wrap">
                                <div class="form-row logo">
                                    <div class="logo-upload-wrapper">
                                        <file-upload [id]="'onboarding_1'"
                                                     name="onboarding_1"
                                                     [required]="true"
                                                     [type]="'image'"
                                                     [manualClass]="'onboarding-1'"
                                                     [themeClass]="pwaAssets.theme_color"
                                                     [primaryColor]="pwaAssets.primary_color?.color"
                                                     [isAvatar]="true"
                                                     [ngClass]="{'form-submitted': appVisualsForm.submitted }"
                                                     [(ngModel)]="pwaAssets.onboarding_1"
                                                     (uploadedFile)="uploadedOnboarding1($event)"></file-upload>
                                    </div>
                                </div>
                            </div>
                            <div class="logo-wrap">
                                <div class="form-row logo">
                                    <div class="logo-upload-wrapper">
                                        <file-upload [id]="'onboarding_2'"
                                                     name="onboarding_2"
                                                     [required]="true"
                                                     [type]="'image'"
                                                     [manualClass]="'onboarding-2'"
                                                     [themeClass]="pwaAssets.theme_color"
                                                     [primaryColor]="pwaAssets.primary_color?.color"
                                                     [isAvatar]="true"
                                                     [ngClass]="{'form-submitted': appVisualsForm.submitted }"
                                                     [(ngModel)]="pwaAssets.onboarding_2"
                                                     (uploadedFile)="uploadedOnboarding2($event)"></file-upload>
                                    </div>
                                </div>
                            </div>
                            <div class="logo-wrap">
                                <div class="form-row logo">
                                    <div class="logo-upload-wrapper">
                                        <file-upload [id]="'onboarding_3'"
                                                     name="onboarding_3"
                                                     [required]="true"
                                                     [type]="'image'"
                                                     [manualClass]="'onboarding-3'"
                                                     [themeClass]="pwaAssets.theme_color"
                                                     [primaryColor]="pwaAssets.primary_color?.color"
                                                     [isAvatar]="true"
                                                     [ngClass]="{'form-submitted': appVisualsForm.submitted }"
                                                     [(ngModel)]="pwaAssets.onboarding_3"
                                                     (uploadedFile)="uploadedOnboarding3($event)"></file-upload>
                                    </div>
                                </div>
                            </div>
                            <div class="logo-wrap">
                                <div class="form-row logo">
                                    <div class="logo-upload-wrapper">
                                        <file-upload [id]="'home_background'"
                                                     name="home_background"
                                                     [required]="true"
                                                     [type]="'image'"
                                                     [manualClass]="'home-background'"
                                                     [themeClass]="pwaAssets.theme_color"
                                                     [primaryColor]="pwaAssets.primary_color?.color"
                                                     [isAvatar]="true"
                                                     [ngClass]="{'form-submitted': appVisualsForm.submitted }"
                                                     [(ngModel)]="pwaAssets.home_background"
                                                     (uploadedFile)="uploadedHomeBG($event)"></file-upload>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="d-flex w-100 bottom-wizard-box">
        <a *ngIf="!fromDashboard"
           [routerLink]="'/profile/onboarding-wizard/upload-logo'"
           class="btn button-true-green"
           [ngClass]="{'disabled' : !pwaAssets.home_background || !pwaAssets.onboarding_3 || !pwaAssets.onboarding_2 || !pwaAssets.onboarding_1 || !pwaAssets.splash_screen}">Continue</a>
        <button *ngIf="fromDashboard"
                type="button"
                class="btn button-true-green"
                (click)="saveChanges()">Save changes</button>
        <div class="images-uploaded-box"
             [ngClass]="{'all-five': numberOfImages === 5}">
            <span [ngClass]="{'all-five': numberOfImages === 5}"
                  class="checkmark-selected"></span>
            <div class="d-flex flex-column">
                <p>{{ numberOfImages }} of 5 images uploaded</p>
                <div class="uploaded-images-progress i{{numberOfImages}}-images"></div>
            </div>
        </div>
    </div>
</div>
