<div class="client-messages-modal">
    <div class="content-right">
        <h5 class="thread-heading"><span *ngIf="customerName && customerLastname">{{ customerName }} {{ customerLastname
                }}</span><img (click)="closeModalChat()"
                 src="../../assets/icons/ic_close.png" /></h5>
        <div class="content-body messages-wrapper">
            <div class="message-list">
                <button *ngIf="showLoadMoreMessages"
                        class="button-true-green load-more"
                        (click)="loadMoreCustomerMessages()">Load previous messages</button>
                <div class="no-new-messages"
                     *ngIf="messages.length === 0">
                    <img src="../../assets/icons/ic_no_conversations.svg" />
                    <h6>No conversation</h6>
                    <span>There is currently no conversation between you and {{ customerName }}.</span>
                </div>
                <ng-container *ngFor="let message of messages">
                    <div class="message"
                         [ngClass]="{'indent': message.sender !== 'owner'}">
                        <div class="message-avatar"
                             [ngStyle]="{'background-image': 'url(' + (message.user_avatar || defaultAvatar) + ')'}">
                        </div>
                        <div class="message-text">
                            <h6>{{message.user_name}} <span>{{message.created_at | dateFormat}}</span></h6>
                            <p *ngIf="!message.message && !message.attachments.length" class="attachment deleted-attachment">
                                Attachment deleted
                            </p>
                            <p *ngIf="message.message || message.attachments.length">{{message.message}}</p>
                            <p class="attachment input-label"
                               *ngFor="let file of message.attachments">
                                <a href="{{file.attachment.url}}"
                                   target="_blank"
                                   download="{{ file.attachment.name }}">{{file.attachment.name}}</a>
                                <img src="../../assets/icons/delete.png"
                                     (click)="onDeleteAttachment(file)" />
                            </p>
                        </div>
                    </div>
                </ng-container>
            </div>

            <form id="message-form"
                  name="create"
                  #clientMessageModalForm="ngForm">
                <textarea type="text"
                          class="message-input input"
                          name="messageText"
                          maxlength="4000"
                          [(ngModel)]="messageText"
                          [ngClass]="{'form-submitted': clientMessageModalForm.submitted}"></textarea>
                <attach-files [files]="uploadedFiles"
                              (onRemove)="onRemoveAttachment($event)"
                              [totalUploads]="fileList.length"
                              (onAddFiles)="onAttachedFiles($event)"
                              [progress]='progress'
                              [current]="(uploadCounter)"></attach-files>
                <button type="button"
                        #sendMessageButton
                        class="button-true-green"
                        (click)="sendMesssageToContactFromModal()">Send</button>
            </form>
        </div>
    </div>
</div>
