import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthorizationService } from '../authorization.service';

@Injectable()
export class AuthorizationNeededGuard implements CanActivate {
    constructor(private auth: AuthorizationService, private router: Router) { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.auth.isAuthenticated()) {
            this.router.navigateByUrl('/auth/login');
            return false;
        }
        return true;
    }

}
