<div class="wizard-box"
     *ngIf="billing">
    <div class="container">
        <div class="wizard-box-stepper-box">
            <div *ngIf="!fromDashboard" class="stepper-box included-app eight-step"></div>
            
            <a *ngIf="!fromDashboard"
               [routerLink]="'/profile/onboarding-wizard/owner-info'"
               class="back-btn"><img src="../../assets/icons/ic_arrow_left-2.svg" /> <span class="back-arrow-text">Back</span></a>
            <a *ngIf="fromDashboard"
               [routerLink]="'/'"
               class="back-btn top-0"><img src="../../assets/icons/ic_arrow_left-2.svg" /> <span class="back-arrow-text">Back to dashboard</span></a>
            
            <ng-container *ngIf="!fromDashboard">
                <h6>Step {{ !user.coach_package.include_custom_application ? '6' : '8' }} of {{ !user.coach_package.include_custom_application ? '6' : '8' }}</h6>
            </ng-container>
            <ng-container *ngIf="fromDashboard">
                <h6>Step 4</h6>
            </ng-container>
        </div>
        
        <div class="wizard-box-content">
            <h2>Enter your billing information</h2>
            <p class="mb-0" *ngIf="!fromDashboard">
                As soon as you provide your billing information, we will start developing your personalized app.
            </p>
            
            <div class="copy-checkbox-container" *ngIf="!showCardSection">
                <input name="copy-checkbox"
                       class="checkbox input-checkbox"
                       type="checkbox"
                       [class.true]="copyCheckbox"
                       [class.false]="!copyCheckbox"
                       [(ngModel)]="copyCheckbox"
                       (ngModelChange)="onCopyFromYourInfo()" />
                <label class="input-label">Use information from the previous step</label>
            </div>
            
            <form *ngIf="!showCardSection" class="billing-info" #billingForm="ngForm">
                <div class="row-full-width">
                    <div class="form-row">
                        <p class="input-label">Name</p>
                        <input class="input"
                               required
                               type="text"
                               placeholder="Name"
                               name="name"
                               [(ngModel)]="billing.name"
                               (ngModelChange)="updateBillingInfo()"
                               [ngClass]="{ 'form-submitted': billingForm.submitted || submitted  }" />
                    </div>
                    <div class="form-row">
                        <p class="input-label">Surname</p>
                        <input class="input"
                               required
                               type="text"
                               placeholder="Surname"
                               name="surname"
                               [(ngModel)]="billing.surname"
                               (ngModelChange)="updateBillingInfo()"
                               [ngClass]="{ 'form-submitted': billingForm.submitted || submitted  }" />
                    </div>
                    <div class="form-row empty-slot">
                        <!-- empty space -->
                    </div>
                    <div class="form-row">
                        <p class="input-label">City</p>
                        <input class="input"
                               required
                               type="text"
                               placeholder="Enter city name"
                               name="city"
                               [(ngModel)]="billing.city"
                               (ngModelChange)="updateBillingInfo()"
                               [ngClass]="{ 'form-submitted': billingForm.submitted || submitted  }" />
                    </div>
                    <div class="form-row">
                        <p class="input-label">Street address and number</p>
                        <input class="input"
                               required
                               type="text"
                               placeholder="Enter street address and number"
                               name="street_name_and_number"
                               [(ngModel)]="billing.street_name_and_number"
                               (ngModelChange)="updateBillingInfo()"
                               [ngClass]="{ 'form-submitted': billingForm.submitted || submitted  }" />
                    </div>
                    <div class="form-row">
                        <p class="input-label">Street address and number 2</p>
                        <input class="input"
                               type="text"
                               placeholder="Enter street address and number 2"
                               name="street_name_and_number_line_2"
                               [(ngModel)]="billing.street_name_and_number_line_2"
                               [ngClass]="{ 'form-submitted': billingForm.submitted || submitted  }" />
                    </div>
                    <div class="form-row">
                        <p class="input-label">Country</p>
                        <ng-select name="billingCountry"
                                   class="select-input"
                                   [items]="countries"
                                   [searchable]="true"
                                   dropdownPosition="top"
                                   bindLabel="text"
                                   bindValue="text"
                                   required
                                   placeholder="Select your country"
                                   [(ngModel)]="billing.country"
                                   (ngModelChange)="updateBillingInfo()"
                                   [ngClass]="{ 'form-submitted': billingForm.submitted || submitted }">
                        </ng-select>
                    </div>
                    <div class="form-row">
                        <p class="input-label">State</p>
                        <input class="input"
                               required
                               type="text"
                               placeholder="State"
                               name="state"
                               [(ngModel)]="billing.state"
                               (ngModelChange)="updateBillingInfo()"
                               [ngClass]="{ 'form-submitted': billingForm.submitted || submitted  }" />
                    </div>
                    <div class="form-row"
                         stepperFix>
                        <p class="input-label">Postal code/Zip code</p>
                        <input class="input"
                               required
                               type="text"
                               placeholder="Enter your postal / zip code"
                               name="zip_code"
                               [(ngModel)]="billing.zip_code"
                               (ngModelChange)="updateBillingInfo()"
                               [ngClass]="{ 'form-submitted': billingForm.submitted || submitted }" />
                    </div>
                </div>
                <!-- <div class="row-full-width">
                        <button type="submit"
                                *ngIf="!stepper"
                                class="button-true-green">Save</button>
                    </div> -->
            </form>
            
            <ng-template #cardElement>
                <div id="card-element" class="show-border"></div>
            </ng-template>
            
            <div class="cards-section">
                <div *ngIf="showCardSection && billingInfoCompleted">
                    <section class="stripe-section card-wrapper" *ngIf="showAddCard && gateway === 'stripe'">
                        <form id="setup-form" name="setupForm" [attr.data-secret]="clientSecret">
                            <!-- ​<span class="close-stripe-section"
                                  *ngIf="!stepper"
                                  (click)="closeStripe()"><img src="assets/icons/clear.png" /></span> -->
                            <div class="flexed">
                                <p class="input-label">Card information *</p>
                                <ng-container *ngTemplateOutlet="cardElement"></ng-container>
                                <div class="images-box">
                                    <img src="assets/img/stripe-powered.png" />
                                    <img class="credit-card-img" src="assets/img/credit_card_logos_horizontal_color_orig.png" />
                                </div>
                            </div>
                        </form>
                    </section>
                    
                    <div class="form-row overflow" *ngIf="!closeFrame && gateway === 'authorize-net'">
                        <div class="column iFrame" [ngClass]="token ? 'show-dialog' : ''">
                            <p class="spinner" *ngIf="showSpiner"></p>
                            <div id="iframe_holder"
                                 class="center-block"
                                 *ngIf="!closeFrame"
                                 #iFrameElem
                                 style="width:100%; overflow-x: auto; display: none;">
                                <form id="send_token"
                                      action="{{authorizeNetUrl}}"
                                      #cardForm
                                      method="post"
                                      target="add_payment">
                                    <input type="hidden"
                                           name="token"
                                           #cardFormInput
                                           value="Replace with form token from getHostedPaymentPageResponse" />
                                </form>
                                <iframe id="add_payment"
                                        class="embed-responsive-item panel"
                                        name="add_payment"
                                        #iFrame
                                        width="100%"
                                        frameborder="0"
                                        scrolling="no"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="table-section" *ngIf="fromDashboard && showCardSection">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Card number</th>
                                <th>Card type</th>
                                <th>Expiration date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let card of billing.cards">
                                <td>**** {{ card.card_number }}</td>
                                <td>{{ card.card_type }}</td>
                                <td>{{ card.expiration_date }}</td>
                                <td>
                                    <div class="card-actions">
                                        <button class="button button-true-green-small"
                                                [title]="card.is_primary ? 'Click to unset card as default card' : 'Click to set card as default card'"
                                                [class.default]="card.is_primary"
                                                (click)="onSetAsDefault(card.id)">{{card.is_primary ? 'Default card' : 'Set as default'}}</button>
                                        <button class="button-delete"
                                                (click)="onDeleteCard(card.id)"></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <ng-template #billingInfoPopover>
                <p class="popover-text">
                    Billing info is used to send billing related messages, invoices, payments etc.
                </p>
            </ng-template>
        </div>
    </div>
    
    <div class="d-flex w-100 bottom-wizard-box">
        <button class="btn button-true-green"
                *ngIf="!showCardSection"
                [disabled]="!billingForm?.valid"
                (click)="nextStep(billingForm)"
                [ngClass]="{'disabled' : !billingForm?.valid}">{{ fromDashboard ? 'Save Changes' : 'Continue' }}</button>
        <button id="card-button"
                class="btn button-true-green"
                [ngClass]="{'disabled': disableSaveCard}"
                [disabled]="disableSaveCard"
                type="submit"
                form="setup-form"
                *ngIf="displayStripeForm && showCardSection">Complete</button>
    </div>
</div>
