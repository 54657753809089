import { Injectable } from '@angular/core';
import { FitApiClientService, FitList } from '@shared/fit-api-client.service';
import { ProgramItemCustomerResponse } from '@app/program/types';
import { GalleryItem } from '@app/gallery/gallery.service';
import { QuestionTypes } from '@app/questionnaire/types';

export { ProgramItemCustomerResponse };

interface NutritionProgram {
    id: number;
    name: string;
}

interface User {
    id: number;
    first_name: string;
    last_name: string;
    is_active: boolean;
    flow?: string;
}

interface AdministratorList extends User {
    image_url: string;
}

interface CoachList extends Administrator {
    name: string;
}

interface Administrator extends User {
    id: number;
    email: string;
    gender: 'male' | 'female' | 'other';
    image: GalleryItem;
    password: string;
    passwordConfirm: string;
}

interface Coach extends Administrator {}

interface AdministratorRequest extends User {
    email: string;
    gender: 'male' | 'female' | 'other';
    image_id: number;
    password: string;
}

interface CoachRequest extends AdministratorRequest {}

interface Customer extends User {
    email: string;
    gender: 'male' | 'female';
    is_survey_completed: boolean;
    fullname?: string;
    avatar: string;
    signup_date: string;
    last_login: string;
    active_package: Package;
    activated_nutrition_programs: { id: number, name: string }[];
    activated_programs: {id: number, name: string}[];
}

interface CustomerById extends User {
    email: string;
    gender: 'male' | 'female';
    bio: string;
    image: GalleryItem;
    cover_image: GalleryItem;
}

interface Package {
    id: number;
    name: string;
}

/*
export type queryHealth = 'height' | 'weight' | 'steps' | 'heart_rate' | 'respiratory_rate' | 'energy_consumed' | 'body_fat' | 'sleep' | 
                          'calories_intake' | 'proteins_intake' | 'carbohydrates_intake' | 'fat_intake';
*/

export interface UserDetails {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    image_url: string;
    cover_image_url: string;
    recent_fitness_timestamp?: string;
    user_agent?: string;
    daily_streak: number;
    trainings_completed_count: number;
    programs_completed_count: number;
    nutrition_programs_completed_count: number;
    is_active: boolean;
    is_deleted: boolean;
    timezone?: string;
    active_package_name: string;
    is_active_subscriber: boolean;
    package_valid_until?: string;
    last_completed_training: any;
    last_logged_meal: any;
    logged_meals_count: number;
    streaks: {
        current_daily_streak: number;
        longest_daily_streak: number;
        current_weakly_streak: number;
        longest_weekly_streak: number;
    };
    activated_programs: ActivatedProgram[];
    activated_nutrition_programs: any[];
    achievements: Achievement[];
    progress_images: ProgressImage[];
    height_in_centimeters: number;
    weight_in_kilograms: number;
    age: number;
    is_blocked_from_posting: boolean;
    macronutrients: MacroNutritients;
    billing_info: CustomerBillingInfo;
    questionnaire_responses: QuestionnaireResponse[];
    payment_transactions: CustomerTransaction[];
}

interface CustomerTransaction{
    charged_value: number;
    currency_code: string;
    is_test: boolean;
    email: string;
    method: string;
    paymentItem: string;
    status: string;
    timestamp: string;
    transaction_id: string;
    type: string;
}

interface CustomerBillingInfo{
    cards: any[];
    city: string;
    country: string;
    name: string;
    state: string;
    street: string;
    surname: string;
    zip: string;
    stripe_id?: string;
}

export interface QuestionnaireResponse {
    questionnaire_id: number;
    questionnaire_name: string;
    response: QuestionnaireResponseResponse[];
}

interface QuestionnaireResponseResponse {
    type: QuestionTypes;
    text: string;
    answers: QuestionnaireAnswer[];
}

interface QuestionnaireAnswer {
    text: string;
}

export interface MacroNutritients {
    carbs: MacroNutritientItem;
    daily_goals: MacroNutritientItem;
    fats: MacroNutritientItem;
    fiber: MacroNutritientItem;
    protein: MacroNutritientItem;
    sugar: MacroNutritientItem;
    water: MacroNutritientItem;
}

interface MacroNutritientItem {
    title: string;
    value: number;
    unit: string;
}

interface CustomerActivityData {
    id: number;
    name: string;
    completed_at: string;
}

export interface StepsData {
    ends_at: string;
    starts_at: string;
    timestamp?: string;
    unit: string;
    value: number | string;
    avg?: number;
    min?: any;
    max?: any;
}

export interface EnergyConsumedData extends StepsData { }
export interface HeartRateData extends StepsData { }
export interface HeightData extends StepsData { }
export interface WeightData extends StepsData { }

interface HealthData extends StepsData { }

export interface HealthSummary {
    average_heart_rate: number;
    max_heart_rate: number;
    min_heart_rate: number;
    average_respiratory_rate: number;
    max_respiratory_rate: number;
    min_respiratory_rate: number;
    current_height_in_centimeters: number;
    current_weight_in_kilograms: number;
    total_energy_consumed: number;
    total_steps: number;
    total_sleep: number;
    total_calories_intake: number;
    total_proteins_intake: number;
    total_carbohydrates_intake: number;
    total_fat_intake: number;
}

export interface ActivatedProgram {
    id: number;
    name: string;
    image_url: string;
    percentage_completed: number;
}

export type LoadDay = 'previous' | 'next';

export interface Achievement {
    title: string;
    description: string;
    image_url: string;
    is_unlocked: boolean;
}

export interface ProgressImage {
    image_url: string;
    display_date: string;
}

export {
    FitList,
    User,
    Customer,
    CustomerById,
    Administrator,
    AdministratorList,
    Coach,
    CoachList,
    NutritionProgram,
    Package,
};

@Injectable({ providedIn: 'root' })

export class UserService {
    constructor(private fitApi: FitApiClientService) { }

    public searchAdministrator(query: string, page: number) {
        return this.fitApi.get<FitList<AdministratorList>>(
            '/admin/administrator',
            {
                search: query,
                page: page.toString(),
            }
        );
    }

    public getAdministrator(id: number) {
        return this.fitApi.get<Administrator>('/admin/administrator/' + id);
    }

    public createAdministrator(admin: Administrator) {
        return this.fitApi.put<Administrator>(
            '/admin/administrator/0',
            this.mapAdministratorRequest(admin)
        );
    }

    public updateAdministrator(admin: Administrator) {
        return this.fitApi.put<Administrator>(
            '/admin/administrator/' + admin.id,
            this.mapAdministratorRequest(admin)
        );
    }

    public deleteAdministrator(id: number) {
        return this.fitApi.delete<any>(`/admin/administrator/${id}`);
    }

    public searchCoach(query: string, page: number) {
        return this.fitApi.get<FitList<CoachList>>(
            '/admin/coach',
            {
                search: query,
                page: page.toString(),
            }
        );
    }

    public getAllCoaches(appId: number, query?: string) {
        const queryParams: any = {
            application_id: appId.toString()
        };

        if (query) {
            queryParams.search = query;
        }

        return this.fitApi.get<CoachList[]>('/admin/coach/list', queryParams);
    }

    public getCoach(id: number) {
        return this.fitApi.get<Coach>('/admin/coach/' + id);
    }

    public createCoach(coach: Coach) {
        return this.fitApi.put<Coach>('/admin/coach/0', this.mapCoachRequest(coach));
    }

    public updateCoach(coach: Coach) {
        return this.fitApi.put<Coach>('/admin/coach/' + coach.id, this.mapCoachRequest(coach));
    }

    public saveMacronutritients(user_id: number, macronutrients: MacroNutritients) {
        return this.fitApi.put<any>(`/admin/customer/${user_id}/macronutrients`, macronutrients);
    }

    public searchUser(
        query: string,
        page: number,
        isSubscriber?: boolean,
        notSubscriber?: boolean,
        isActiveSubscriber?: boolean,
        isTailor?: boolean,
        isWithNoLogin?: boolean,
        programs?: string) {

        const queryParams: any = {
            search: query,
            page: page.toString()
        };

        if (isSubscriber) {
            queryParams.isSubscriber = `${isSubscriber}`;
        }

        if (notSubscriber) {
            queryParams.isSubscriber = `false`;
        }

        if (isActiveSubscriber) {
            queryParams.isActiveSubscriber = `${isActiveSubscriber}`;
        }

        if (isTailor) {
            queryParams.isTailorMade = `${isTailor}`;
        }

        if (isWithNoLogin) {
            queryParams.withNoLogin = `${isWithNoLogin}`;
        }

        if (programs) {
            queryParams.programIds = `${programs}`;
        }

        return this.fitApi.get<FitList<Customer>>('/admin/customer', queryParams);
    }

    public getUser(id: number) {
        return this.fitApi.get<CustomerById>(`/admin/customer/${id}`);
    }

    public getUserDetails(id: number) {
        return this.fitApi.get<UserDetails>(`/admin/customer/${id}/profile`);
    }

    public createUser(user: User) {
        return this.fitApi.put<CustomerById>('/admin/customer/0', user);
    }

    public updateUser(user: User) {
        return this.fitApi.put<CustomerById>('/admin/customer/' + user.id, user);
    }

    public unsubscribeUser(userId: number) {
        return this.fitApi.put<CustomerById>(`/admin/customer/${userId}/unsubscribe`, null);
    }

    public terminateUserPackage(userId: number) {
        return this.fitApi.put<CustomerById>(`/admin/customer/${userId}/terminate`, null);
    }

    public blockFromPosting(userId: number) {
        return this.fitApi.put<CustomerById>(`/admin/customer/${userId}/block-from-posting`, null);
    }

    public unblockFromPosting(userId: number) {
        return this.fitApi.put<CustomerById>(`/admin/customer/${userId}/unblock-from-posting`, null);
    }

    public assignPackageToUser(userId: number, packageId: number) {
        return this.fitApi.post<CustomerById>('/admin/packages/assign', {
            user_id: userId,
            package_id: packageId,
        });
    }

    public deleteUser(id: number) {
        return this.fitApi.delete<any>('/admin/customer/' + id);
    }

    public updateCustomer(id: number, email: string) {
        return this.fitApi.put<any>(`/admin/customer/${id}/update`, { email: email });
    }

    public getUserPrograms(userId: number) {
        return this.fitApi.get<ProgramItemCustomerResponse[]>(
            `/admin/customer/${userId}/programs`
        );
    }

    public getPackages() {
        return this.fitApi.get<FitList<Package>>(`/admin/packages`);
    }

    public getUserNutritionPrograms(userId: number) {
        return this.fitApi.get<NutritionProgram[]>(
            `/admin/customer/${userId}/nutrition-programs`
        );
    }

    public addRole(user: User, role: string) {
        const params = {
            roles: [role]
        }
        return this.fitApi.put<CustomerById>(`/admin/user/${user.id}/assign-roles`, params);
    }

    public downloadUserList(query: string,
        page: number,
        isSubscriber?: boolean,
        notSubscriber?: boolean,
        isActiveSubscriber?: boolean,
        isTailor?: boolean,
        isWithNoLogin?: boolean,
        programs?: string) {

        const queryParams: any = {
            search: query,
            page: page.toString()
        };

        if (isSubscriber) {
            queryParams.isSubscriber = `${isSubscriber}`;
        }

        if (notSubscriber) {
            queryParams.isSubscriber = `false`;
        }

        if (isActiveSubscriber) {
            queryParams.isActiveSubscriber = `${isActiveSubscriber}`;
        }

        if (isTailor) {
            queryParams.isTailorMade = `${isTailor}`;
        }

        if (isWithNoLogin) {
            queryParams.withNoLogin = `${isWithNoLogin}`;
        }

        if (programs) {
            queryParams.programIds = `${programs}`;
        }

        //return this.fitApi.get<string>(`/admin/reports/users-export-csv-url`, queryParams);
        return this.fitApi.get<any>(`/admin/reports/users-export-xls`, queryParams);
    }

    public removeRole(userId: number, role: string) {
        const params = {
            roles: [role]
        }
        return this.fitApi.put<CustomerById>(`/admin/user/${userId}/remove-roles`, params);
    }

    public getCompletedPrograms(customerId: number, page?: number) {
        const queryParams: any = {
            page: page ? page.toString() : '1'
        };

        return this.fitApi.get<FitList<CustomerActivityData>>(`/admin/customer/${customerId}/completed-programs`, queryParams);
    }

    public getLoggedTrainings(customerId: number, page?: number) {
        const queryParams: any = {
            page: page ? page.toString() : '1'
        };

        return this.fitApi.get<FitList<CustomerActivityData>>(`/admin/customer/${customerId}/completed-trainings`, queryParams);
    }

    public getCompletedNutritionPrograms(customerId: number, page?: number) {
        const queryParams: any = {
            page: page ? page.toString() : '1'
        };

        return this.fitApi.get<FitList<CustomerActivityData>>(`/admin/customer/${customerId}/completed-nutrition-programs`, queryParams);
    }

    public getLoggedRecipes(customerId: number, page?: number) {
        const queryParams: any = {
            page: page ? page.toString() : '1'
        };

        return this.fitApi.get<FitList<CustomerActivityData>>(`/admin/customer/${customerId}/logged-recipes`, queryParams);
    }

    public getHealthData(customerId: number, dataType: string, startDate: string, endDate: string) {
        let params;
        if (dataType) {
            params = {
                start_date: startDate,
                end_date: endDate,
                data_type: dataType
            };
        } else {
            params = {
                start_date: startDate,
                end_date: endDate
            };
        }
        return this.fitApi.get<HealthData[]>(`/admin/customer/${customerId}/health-tracking`, params);
    }

    public getHealthSummary(customerId: number, startDate: string, endDate: string) {
        const params = {
            start_date: startDate,
            end_date: endDate
        };
        return this.fitApi.get<HealthSummary>(`/admin/customer/${customerId}/health-tracking-summary`, params);
    }

    public getDailyHealthSummary(id: number, date: string) {
        const params = {
            date
        };
        return this.fitApi.get<HealthSummary>(`/admin/customer/${id}/health-tracking-summary/daily`, params);
    }

    public getDailyHealth(id: number, dataType: string, date: string, perHour: boolean = false) {
        const params = {
            date,
            data_type: dataType,
            perHour: perHour.toString()
        }
        return this.fitApi.get<any>(`/admin/customer/${id}/health-tracking/daily`, params);
    }

    public deactivateProgram(id: number) {
        return this.fitApi.post<any>(`/admin/program/${id}/deactivate`, {});
    }

    public deactivateNutritionProgram(id: number) {
        return this.fitApi.post<any>(`/admin/nutrition-program/${id}/deactivate`, {});
    }

    private mapAdministratorRequest(admin: Administrator): AdministratorRequest {
        return {
            email: admin.email,
            first_name: admin.first_name,
            gender: admin.gender,
            id: admin.id,
            image_id: admin.image ? admin.image.id : null,
            is_active: admin.is_active,
            last_name: admin.last_name,
            password: admin.password,
        };
    }

    private mapCoachRequest(coach: Coach): CoachRequest {
        return {
            email: coach.email,
            first_name: coach.first_name,
            gender: coach.gender,
            id: coach.id,
            image_id: coach.image ? coach.image.id : null,
            is_active: coach.is_active,
            last_name: coach.last_name,
            password: coach.password,
        };
    }
}
