import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decomposeObject'
})

export class DecomposeObjectPipe implements PipeTransform {
    transform(object: object): string {
        if (!object) {
            return '';
        }

        if (typeof object !== 'object') {
            return object;
        }

        return Object.entries(object).map(([k, v]) => `${k}: ${v}`).join(', ');
    }
}
