<div class="personalized-box"
     *ngIf="!hideModal">
    <img [src]="logo" />
    <h6>{{ brandName }}</h6>
    <h1 class="with-confetti">We started developing your <b>personalized app!</b></h1>
    <p>Congratulations! We started developing your personalized iOS and Android app under your brand, logo and colors.
        Now it is great time to explain how to reach your full potential as soon as possible. We will try to be quick
        and explain it in less than a minute.</p>
    <button type="button"
            class="btn button-true-green"
            (click)="letsBegin()">Let's begin!</button>
</div>

<div class="personalized-box step-2"
     *ngIf="hideModal && step === 1">
    <span>APP IN DEVELOPMENT</span>
    <h1>When do I get my <b>personalized app?</b></h1>
    <p>It usually takes 5 to 7 business days for our team to develop your personalized iOS and Android app. You can
        follow the process of the app development on the Dashboard page. Once your app is developed you will be notified
        via Resolution Center.</p>
    <p class="step-dots">
        <span class="active"></span>
        <span></span>
    </p>
    <button type="button"
            class="btn button-true-green"
            (click)="step = 2">Got it</button>
</div>

<div class="personalized-box step-1"
     *ngIf="hideModal && step === 2">
    <span>CREATE AND MANAGE CONTENT</span>
    <h1>What can I do in <br><b>the meantime?</b></h1>
    <p>While your personalized iOS and Android app is being developed, you can start creating and managing your content.
        Until your iOS and Android app is ready, you can preview how your content looks within web app we have created
        for you to use in the meantime.</p>
    <p class="step-dots">
        <span class="active"></span>
        <span class="active"></span>
    </p>
    <button type="button"
            class="btn button-true-green"
            (click)="complete()">Complete</button>
    <a (click)="step = 1"
       class="previous-step">Back to previous step</a>
</div>
