import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GalleryItem } from '@app/gallery/gallery-item';
import { FitConfigProviderService } from '@shared/fit-config-provider.service';
import { NotificationsService } from '@shared/notifications.service';
import { InteractionService } from '@app/interaction/interaction.service';
import { PageableComponent } from '@shared/pageable.component';
import { debounceTime, Subscription } from 'rxjs';
import { Attachment, MessageRequest, ResolutionCenterService, ResolutionMessage, Thread, ThreadRequest } from '../resolution-center.service';

@Component({
    selector: 'root-center',
    templateUrl: './root-center.component.html',
    styleUrls: ['./root-center.component.scss']
})

export class RootCenterComponent extends PageableComponent<any> implements OnInit, OnDestroy {
    constructor(private resolutionCenterService: ResolutionCenterService, 
                private notification: NotificationsService,
                private interaction: InteractionService,
                private fitConfig: FitConfigProviderService
    )
    {
        super();

        this.searchQueryStream.pipe(
            debounceTime(500)
        ).subscribe(() => (
            this._page = 1,
            this.load()
        ));
    }

    @ViewChild('messageForm') public messageForm: NgForm;
    @Output() public showNewButton = new EventEmitter<boolean>();
    @Input() public appActivated;

    @Input()
    get createNewButton() { return this._createNewButton; }
    set createNewButton(value) { this._createNewButton = value; }

    @Input()
    get appId() { return this._appId; }
    set appId(value) { this._appId = value; }

    public searchContact = new EventEmitter<string>();

    get messages() { return this._messages; }
    set messages(value) { this._messages = value; }

    get newThread() { return this._newThread; }
    set newThread(value) { this._newThread = value; }

    public currentDate: Date;
    public publishDate: Date;

    get uploadCounter() { return this._uploadedCounter; }

    public showCreateNewForm = true;
    public selectedThread = null;
    public showCreateNew = true;
    public noSearchResult = false;
    public showLoadMoreMessages = false;
    public loadingMessages = false;
    public seen = true;
    public _timeout = null;
    public showLoadMoreThreads = false;
    public disableThreadForm = false;
    public selectedContactThread = null;
    public progress = 0;
    public sending = false;
    public showClosedThreads = false;

    public messageText = '';
    public originalMessageText = null;
    public defaultAvatar = 'assets/icons/ic_placeholder.svg';
    public uploadedFiles = [];
    public fileList = [];
    public showSpinner = false;

    ngOnInit(): void {
        this.setDates();

        this._newDetailsResolutionMessages = this.resolutionCenterService.newDetailsResolutionCenterMessage.subscribe(() => {
            this.load();
        });
        
        this.resolutionCenterService.showNewThreadForm.subscribe(bool => {
            if (bool) {
                this.selectedContactThread = null;
                this.showNewButton.emit(false);
                this.showCreateNewForm = true;
                this.showCreateNew = false;
            }
        });
        
        this.load();
    }

    ngOnDestroy(): void {
        if(this._newDetailsResolutionMessages){
            this._newDetailsResolutionMessages.unsubscribe();
        }
    }

    public async closeThread($event: Event, threadId: number) {
        $event.preventDefault();
        $event.stopPropagation();

        const { data, error } = await this.resolutionCenterService.closeThread(threadId, this.appId);
        if (!error) {
            this._page = 1;
            this._threadPage = 1;
            this.showCreateNewForm = true;
            this.selectedThread = null;
            this.showCreateNew = false;
            this.createNewButton = false;
            this.load();
        }
    }

    public onShowClosedThreadsChange() {
        this._page = 1;
        this._threadPage = 1;
        this.showCreateNewForm = true;
        this.selectedThread = null;
        this.showCreateNew = false;
        this.createNewButton = false;
        this.load();
    }

    public async load() {
        if(this.loading){
            return;
        }
        
        this.showLoadMoreThreads = false;
        this.loading = true;
        const { data, error } = await this.resolutionCenterService.getResolutionThreadList(
            this.appId, 
            this.searchQuery, 
            this.showClosedThreads, 
            this._threadPage
        );

        if (!error) {
            if (this._threadPage > 1) {
                const threads = JSON.parse(JSON.stringify([...this._list.data]));
                this._list = data;
                this._list.data = [];
                this._list.data = [...threads];
            } else {
                this._list = data;
            }

            if (data.last_page > this._threadPage) {
                this.showLoadMoreThreads = true;
            } else {
                this.showLoadMoreThreads = false;
            }
        }
        
        this.loading = false;
    }

    public onShowThreadForm() {
        this.showCreateNewForm = true;
        this.selectedThread = null;
        this.showCreateNew = false;
        this.createNewButton = false;
    }

    public onAttachedFiles(eventFiles) {
        for (let i = 0; i < eventFiles.length; i++) {
            let haveAllready = this.fileList.some(item => item.name === eventFiles[i].name);
            if (!haveAllready) {
                this.fileList.push(eventFiles[i]);
            }
        }
        
        this.startUploading();
    }

    public startUploading() {
        this._uploadedCounter = 0;
        if (!this.fileList.length) {
            return;
        }
        
        let amountToUpload = this.fileList.length;
        const upload = async () => {
            if (amountToUpload > this._uploadedCounter) {
                const data = await this.uploadAttachment(this.fileList[this._uploadedCounter]);
                if (data) {
                    data.subscribe(
                        res => {
                            if (res.progress) {
                                this.progress = res.progress;
                            } else if (res.response) {
                                this.progress = 0;
                                if (res.response !== undefined) {
                                    this.uploadedFiles.push(res.response.data);
                                }
                                this._uploadedCounter++;
                            } else {
                                this.progress = 0;
                            }
                        },
                        error => {
                            this.progress = 0;
                            this._uploadedCounter++;
                            const err = error.status === 502 ? error.statusText : error.error.errorMessages.join('\r\n');
                            this.notification.error('Error', err);
                        },
                        () => {
                            upload();
                        }
                    );
                }
            } else {
                this.fileList = [];
                this._uploadedCounter = 0;
            }

        };
        
        upload();
    }

    public uploadAttachment(file) {
        return this.resolutionCenterService.uploadAttachment(file.name, file, this._appId);
    }

    public onRemoveAttachment(attachment: GalleryItem) {
        this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== attachment.id);
    }

    public async onDeleteAttachment(file: Attachment) {
        const { data, error } = await this.resolutionCenterService.deleteAttachment(file, this.appId, false);
        if (!error) {
            this.checkThreadMessages();
            this.notification.success('Success', 'Attachment deleted');
            this.getResolutionMessages();
        }
    }

    public async refineNewThreadMessage() {
        this.showSpinner = true;
        this.originalMessageText = this.newThread.message.trim();
        const { data, error } = await this.resolutionCenterService.getRefinedMessage(this.originalMessageText);

        if (!error) {
            if (!data.length) {
                this.notification.warning('', 'Unable to refine message, please try later');
            } else {
                this.newThread.message = data;
            }
        }

        this.showSpinner = false;
    }

    public revertNewThreadMessage() {
        this.newThread.message = this.originalMessageText;
        this.originalMessageText = null;
    }

    public async refineThreadMessage() {
        this.showSpinner = true;
        this.originalMessageText = this.messageText.trim();
        const { data, error } = await this.resolutionCenterService.getRefinedMessage(this.originalMessageText);

        if (!error) {
            if (!data.length) {
                this.notification.warning('', 'Unable to refine message, please try later');
            } else {
                this.messageText = data;
            }
        }

        this.showSpinner = false;
    }

    public revertThreadMessage() {
        this.messageText = this.originalMessageText;
        this.originalMessageText = null;
    }

    public sendThreadMessage() {
        if (this.sending) {
            return;
        }
        
        this.sending = true;
        const message: MessageRequest = {
            application_id: this.appId,
            thread_id: this.selectedThread.id,
            message: this.messageText.trim(),
            available_at: new Date(this.publishDate).toJSON(),
            attachment_ids: this.uploadedFiles.map(file => file.id)
        };
        
        if (!message.message && !message.attachment_ids.length) {
            return;
        }

        this.resolutionCenterService.sendResolutionMessage(message, this.uploadedFiles).then(res => {
            this.sending = false;
            this.messageForm.resetForm();
            this.messageText = '';
            this.uploadedFiles = [];
            this.getOnlyNewMessages();

            setTimeout(() => {
                this.scrollToLastReadedMesssage();
            }, 1000);
        });
    }

    public async deleteMessage(messageId: number) {
        const confirm = await this.interaction.confirm('Are you sure you want to delete message?');
        if (confirm) {
            const { data, error } = await this.resolutionCenterService.deleteMessage(messageId, this.appId, false);
            
            if (!error) {
                this.notification.success('Success', 'Message deleted');
                //todo just delete message from the list
                this.checkThreadMessages();
                this.getResolutionMessages();
            }
        }
    }

    public async loadMoreThreads() {
        this._threadPage++;
        this.showLoadMoreThreads = false;

        const { data, error } = await this.resolutionCenterService.getResolutionThreadList(
            this.appId,
            this.searchQuery,
            this.showClosedThreads,
            this._threadPage
        );
        
        if (!error) {
            this._list.data = [...this.list, ...data.data];
            this.showLoadMoreThreads = this.shouldShowLoadMoreButton(data.current_page, data.last_page);
        }
    }

    public onBackToThread() {
        this.showCreateNew = true;
    }

    public loadMoreThreadMessages() {
        this._page++;
        this._loadedPrevious = true;
        this.showLoadMoreMessages = false;
        this.resolutionCenterService.getResolutionMessages(this._appId, this.selectedThread.id, this.page).then(res => {
            this._messages = [...this._messages, ...res.data.data].sort((a, b) => a.id - b.id);
            this.showLoadMoreMessages = this.shouldShowLoadMoreButton(res.data.current_page, res.data.last_page);
        });
    }

    public checkThreadMessages() {
        this.loadingMessages = true;
        if (!this.selectedContactThread) {
            return;
        }
        
        if (this._loadedPrevious) {
            this.getOnlyNewMessages();
        } else {
            this.getMessages(this.selectedContactThread.id);
        }
    }

    public async getMessages(id: number, search?) {
        const { data, error } = await this.resolutionCenterService.getResolutionMessages(this.appId, this.selectedThread.id, this.page);
        if (!error) {
            this._messages = [...this._messages, ...data.data].sort((a, b) => a.id - b.id);
            this.fillEmptyAvatarsWithDefault();
            this.showLoadMoreMessages = this.shouldShowLoadMoreButton(data.current_page, data.last_page);
            this.loadingMessages = false;
            setTimeout(() => {
                this.scrollToLastReadedMesssage();
            }, 100);
        }
    }

    public scrollToLastReadedMesssage() {
        const messageBox: HTMLDivElement = document.querySelector('.message-list');
        if (messageBox) {
            messageBox.scroll({ top: 20000, behavior: 'smooth' });
        }
    }

    public fillEmptyAvatarsWithDefault() {
        this._messages.forEach(message => {
            if (!message.user_avatar) {
                message.user_avatar = this.defaultAvatar;
            }
        });
    }

    public onSelectResolutionThread(thread: Thread) {
        if (this.selectedThread && thread.id === this.selectedThread.id) {
            return;
        }
        
        if (this.selectedThread) {
            this.resolutionCenterService.cacheThread(this.selectedThread.id, this.messageText);
        }
        
        if (thread) {
            this.messageText = this.resolutionCenterService.getThreadCahedMessage(thread.id);
        }
        
        this._messages = [];
        this.createNewButton = true;
        this._loadedPrevious = false;
        this._page = 1;
        this.showCreateNewForm = false;
        this.showCreateNew = false;
        this.selectedThread = thread;
        this.getResolutionMessages();
        this.markThreadMessagesSeen();
        this.showNewButton.emit(true);
    }

    public getResolutionMessages() {
        if (this.fitConfig.isOwner) {
            this._appId = null;
        }
        
        this.resolutionCenterService.getResolutionMessages(this._appId, this.selectedThread.id, this.page).then(res => {
            this._messages = [...this._messages, ...res.data.data].sort((a, b) => a.id - b.id);
            this.showLoadMoreMessages = this.shouldShowLoadMoreButton(res.data.current_page, res.data.last_page);
            this.loadingMessages = false;
            this.scrollMessagesToNewest();
        });
    }

    public scrollMessagesToNewest() {
        if (this._scrolled) {
            return;
        }
        
        let messagesBox: HTMLDivElement;
        setTimeout(() => {
            messagesBox = document.querySelector('.message-list');
            if (!messagesBox) {
                return this.scrollMessagesToNewest();
            } else {
                messagesBox.scroll({ top: messagesBox.scrollHeight + messagesBox.offsetHeight, left: 0, behavior: 'smooth' });
            }
            this._scrolled = true;
        }, 500);
    }

    public async markThreadMessagesSeen() {
        let thread = this.selectedThread;
        const { error } = await this.resolutionCenterService.markThreadAsSeen(this.appId, thread.id);
        if (!error) {
            this.resolutionCenterService.updateTotalNotifications.next(true);
            this.seen = true;
            this._list.data.forEach(thread => {
                if (this.selectedThread.id === thread.id) {
                    thread.unread_messages = 0;
                }
            });
            this._timeout = setTimeout(() => {
                this.resolutionCenterService.updateAllAppsMessages.next(true);
            }, 500);
        }
    }

    public async onThreadFormSubmit(form: NgForm) {
        if (this.disableThreadForm) {
            return;
        }

        this._newThread.application_id = this.appId;
        this.disableThreadForm = true;
        const { data, error } = await this.resolutionCenterService.createNewThread(this._newThread, this.uploadedFiles);
        if (!error) {
            this.load();
            this.notification.success('Success', 'Thread successfully created');
            this.uploadedFiles = [];
            form.resetForm();
            setTimeout(() => {
                this.disableThreadForm = false;
            }, 500);
        }
    }

    public isMessageFromTheFuture(message: ResolutionMessage) {
        let messageDate = new Date(message.available_at).getTime();
        return messageDate > this.currentDate.getTime();
    }

    private async getOnlyNewMessages() {
        this.resolutionCenterService.getResolutionMessages(this._appId, this.selectedThread.id, 1).then(res => {
            const newMessages: any = [];
            res.data.data.forEach(message => {
                const check = this._messages.some(msg => msg.id === message.id);
                if (!check) {
                    newMessages.push(message);
                }
            });
            this._messages = [...this._messages, ...newMessages].sort((a, b) => a.id - b.id);
            this.showLoadMoreMessages = this.shouldShowLoadMoreButton(res.data.current_page, res.data.last_page);
            this.loadingMessages = false;
            this.setDates();
            this.scrollMessagesToNewest();
        });
    }

    private shouldShowLoadMoreButton(currentPage: number, lastPage: number) {
        return currentPage < lastPage;
    }

    private setDates() {
        this.currentDate = new Date();
        this.publishDate = new Date();
    }

    private _createNewButton = false;
    private _loadedPrevious = false;
    private _appId;
    private _messages = [];
    private _scrolled = false;
    private _threadPage = 1;
    private _uploadedCounter = 0;
    private _newThread: ThreadRequest = {
        application_id: null,
        subject: '',
        message: '',
        attachment_ids: []
    };
    private _newDetailsResolutionMessages: Subscription;
}
