import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService, Subscription } from '@app/profile/profile.service';
import { AuthorizationService } from '@shared/authorization.service';
import { NotificationsService } from '@shared/notifications.service';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-subscription',
  templateUrl: './manage-subscription.component.html',
  styleUrls: ['./manage-subscription.component.scss']
})
export class ManageSubscriptionComponent implements OnInit {

  constructor(public BsModalRef: BsModalRef,
            private router: Router,
            private notifications: NotificationsService,
            private profileService: ProfileService) { }


  public subscription: Subscription = null;


  ngOnInit(): void {
  }

  public onClose(){
    this.BsModalRef.hide()
  }

  public onCancelation(){
    this.BsModalRef.hide();
    setTimeout(() =>{
      this.router.navigateByUrl('/profile/cancelation')
    }, 2000)

  }

  public async cancelAutoRenewawl() {
    const { data, error } = await this.profileService.cancelAutoRenewal()
    if (!error) {
      this.BsModalRef.hide();
      this.notifications.success('Success', 'Auto renewal canceled');
      this.subscription.coach_package.subscription_term_in_months = null;
      this.subscription.cancel_auto_renewal = true;
    } else {
      this.notifications.error('Error', 'Cancelation failed. Try again')
    }
  }

}
