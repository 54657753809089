import { Component, OnInit } from '@angular/core';
import { AppTheme } from '@app/profile/types';
import { ProfileService, AppVisualsV2 } from '../../profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '@shared/authorization.service';
import { darkAccentColors, lightAccentColors } from '@app/profile/colors';

@Component({
    selector: 'app-primary-color',
    templateUrl: './primary-color.component.html',
    styleUrls: ['./primary-color.component.scss']
})
export class PrimaryColorComponent implements OnInit {

    constructor(
        private profileService: ProfileService,
        private router: Router,
        private route: ActivatedRoute,
        private auth: AuthorizationService) { }

    get user(){ return this.auth.user.getValue()}

    public selected: AppTheme = null;
    public accentColors: AppTheme[] = [];
    public includedCustomApp = false;
    public fromDashboard = false;

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (!params.has('dashboard')) {
                this.fromDashboard = false;
                return;
            }
            this.fromDashboard = true;
        });
        this.profileService.pwaAssets.subscribe(res => {
            if (!res.theme_color) {
                // this.router.navigate(['/profile/onboarding-wizard/lets-begin']);
            }
            if (res.theme_color === 'dark') {
                this.accentColors = this._darkAccentColors;
            } else {
                this.accentColors = this._lightAccentColors;
            }

            this.selected = res.primary_color;
            this._pwaAssets = res;
        });

        this.auth.user.subscribe((user) => {
            if (user) {
                this.includedCustomApp = user.coach_package?.include_custom_application;
            }
        });
    }

    public selectPrimaryColor(color: AppTheme) {
        this.selected = color;
        this.profileService.pwaAssets.next({
            home_background: this._pwaAssets.home_background,
            onboarding_1: this._pwaAssets.onboarding_1,
            onboarding_2: this._pwaAssets.onboarding_2,
            onboarding_3: this._pwaAssets.onboarding_3,
            primary_color: color,
            splash_screen: this._pwaAssets.splash_screen,
            theme_color: this._pwaAssets.theme_color,
            logo: this._pwaAssets.logo
        });
    }

    public async saveChanges() {
        const { data, error } = await this.profileService.setPwaAssets(this._pwaAssets);
        if (!error) {
            await this.generate();
        }

    }

    private async generate() {
        const { data, error } = await this.profileService.generatePwaApp();
        if (!error) {
            this.profileService.appVisualsCompleted.next(true);
            this.router.navigate(['/']);
        } else {
            this.profileService.appVisualsCompleted.next(false);
        }
    }

    private _lightAccentColors: AppTheme[] = lightAccentColors;
    private _darkAccentColors: AppTheme[] = darkAccentColors;

    private _pwaAssets: AppVisualsV2 = null;

}
