import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailName',
  pure: true
})
export class EmailNamePipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
      return value.split('-').join(' ');
  }

}
