import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form/form.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { FormsModule } from '@angular/forms';
import { InteractionModule } from '@app/interaction/interaction.module';
import { SharedModule } from '@shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { AppInfoComponent } from './app-info/app-info.component';
import { OwnerInfoComponent } from './owner-info/owner-info.component';
import { BillingInfoComponent } from './billing-info/billing-info.component';
import { GalleryModule } from '@app/gallery/gallery.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadComponent } from './app-info/file-upload/file-upload.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ProfileStepperComponent } from './profile-stepper/profile-stepper.component';
import { AppVisualsComponent } from './app-visuals/app-visuals.component';
import { PreviewModulatorModalComponent } from './preview-modulator/preview-modulator.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { ManageSubscriptionComponent } from './subscriptions/manage-subscription/manage-subscription.component';
import { CancellationComponent } from './subscriptions/cancellation/cancellation.component';
import { ResolutionCenterModule } from '@app/resolution-center/resolution-center.module';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OnboardingWizardComponent } from './onboarding-wizard/onboarding-wizard.component';
import { LetsBeginComponent } from './onboarding-wizard/lets-begin/lets-begin.component';
import { ThemeColorComponent } from './onboarding-wizard/theme-color/theme-color.component';
import { PrimaryColorComponent } from './onboarding-wizard/primary-color/primary-color.component';
import { UploadImagesComponent } from './onboarding-wizard/upload-images/upload-images.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { UploadLogoComponent } from './onboarding-wizard/upload-logo/upload-logo.component';
import { UploadingAssetsComponent } from './onboarding-wizard/uploading-assets/uploading-assets.component';
import { AppReadyComponent } from './onboarding-wizard/app-ready/app-ready.component';
import { QRCodeModule } from 'angularx-qrcode';
import { TagInputModule } from 'ngx-chips';
import { PersonalizedAppModalComponent } from './personalized-app-modal/personalized-app-modal.component';

@NgModule({
    declarations: [
        FormComponent,
        AppInfoComponent,
        OwnerInfoComponent,
        BillingInfoComponent,
        FileUploadComponent,
        ProfileStepperComponent,
        AppVisualsComponent,
        PreviewModulatorModalComponent,
        SubscriptionsComponent,
        ManageSubscriptionComponent,
        CancellationComponent,
        OnboardingWizardComponent,
        LetsBeginComponent,
        ThemeColorComponent,
        PrimaryColorComponent,
        UploadImagesComponent,
        UploadLogoComponent,
        UploadingAssetsComponent,
        AppReadyComponent,
        PersonalizedAppModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ProfileRoutingModule,
        NgSelectModule,
        PopoverModule.forRoot(),
        SharedModule,
        ProgressbarModule,
        GalleryModule,
        InteractionModule,
        ResolutionCenterModule,
        TooltipModule.forRoot(),
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        QRCodeModule,
        TagInputModule
    ],
    exports: [FormComponent],
    providers: [BsModalRef],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProfileModule { }
