import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@shared/authorization.service';

@Component({
    selector: 'app-lets-begin',
    templateUrl: './lets-begin.component.html',
    styleUrls: ['./lets-begin.component.scss']
})
export class LetsBeginComponent implements OnInit {

    constructor(private auth: AuthorizationService) { }

    public includedCustomApp = false;

    ngOnInit(): void {
        this.auth.user.subscribe((user) => {
            if (user) {
                this.includedCustomApp = user.coach_package?.include_custom_application;
            }
        });
    }

}
