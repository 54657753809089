import { Component, OnInit } from '@angular/core';
import { GalleryItem } from '@app/gallery/gallery-item';
import { AppVisualsV2, ProfileService } from '@app/profile/profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '@shared/authorization.service';

@Component({
    selector: 'app-upload-images',
    templateUrl: './upload-images.component.html',
    styleUrls: ['./upload-images.component.scss']
})
export class UploadImagesComponent implements OnInit {

    constructor(
        private profileService: ProfileService,
        private router: Router,
        private route: ActivatedRoute,
        private auth: AuthorizationService) { }

        get user(){ return this.auth.user.getValue(); }

    public selected = null;
    public disableGenerate = true;
    public numberOfImages = 0;
    public includedCustomApp = false;
    public fromDashboard = false;

    get pwaAssets() { return this._pwaAssets; }
    set pwaAssets(value) {
        this._pwaAssets = value;
        if (value.onboarding_1 && value.onboarding_2 && value.onboarding_3 && value.home_background && value.splash_screen) {
            this.numberOfImages = 5;
        }
    }

    public ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (!params.has('dashboard')) {
                this.fromDashboard = false;
                return;
            }
            this.fromDashboard = true;
        });

        this.profileService.pwaAssets.subscribe(res => {
            this.pwaAssets = res;
            if (!res.primary_color) {
                // this.router.navigate(['/profile/onboarding-wizard/lets-begin']);
            }
        });


        this.auth.user.subscribe((user) => {
            if (user) {
                this.includedCustomApp = user.coach_package?.include_custom_application;
            }
        });
    }

    public uploadedHomeBG($event: GalleryItem) {
        this.calculateNumberOfImages($event);
        this.profileService.pwaAssets.next({
            home_background: $event,
            onboarding_1: this._pwaAssets.onboarding_1,
            onboarding_2: this._pwaAssets.onboarding_2,
            onboarding_3: this._pwaAssets.onboarding_3,
            primary_color: this._pwaAssets.primary_color,
            splash_screen: this._pwaAssets.splash_screen,
            theme_color: this._pwaAssets.theme_color,
            logo: this._pwaAssets.logo
        });
    }

    public uploadedSplashScreen($event: GalleryItem) {
        this.calculateNumberOfImages($event);
        this.profileService.pwaAssets.next({
            home_background: this._pwaAssets.home_background,
            onboarding_1: this._pwaAssets.onboarding_1,
            onboarding_2: this._pwaAssets.onboarding_2,
            onboarding_3: this._pwaAssets.onboarding_3,
            primary_color: this._pwaAssets.primary_color,
            splash_screen: $event,
            theme_color: this._pwaAssets.theme_color,
            logo: this._pwaAssets.logo
        });
    }

    public uploadedOnboarding1($event: GalleryItem) {
        this.calculateNumberOfImages($event);
        this.profileService.pwaAssets.next({
            home_background: this._pwaAssets.home_background,
            onboarding_1: $event,
            onboarding_2: this._pwaAssets.onboarding_2,
            onboarding_3: this._pwaAssets.onboarding_3,
            primary_color: this._pwaAssets.primary_color,
            splash_screen: this._pwaAssets.splash_screen,
            theme_color: this._pwaAssets.theme_color,
            logo: this._pwaAssets.logo
        });
    }
    public uploadedOnboarding2($event: GalleryItem) {
        this.calculateNumberOfImages($event);
        this.profileService.pwaAssets.next({
            home_background: this._pwaAssets.home_background,
            onboarding_1: this._pwaAssets.onboarding_1,
            onboarding_2: $event,
            onboarding_3: this._pwaAssets.onboarding_3,
            primary_color: this._pwaAssets.primary_color,
            splash_screen: this._pwaAssets.splash_screen,
            theme_color: this._pwaAssets.theme_color,
            logo: this._pwaAssets.logo
        });
    }
    public uploadedOnboarding3($event: GalleryItem) {
        this.calculateNumberOfImages($event);
        this.profileService.pwaAssets.next({
            home_background: this._pwaAssets.home_background,
            onboarding_1: this._pwaAssets.onboarding_1,
            onboarding_2: this._pwaAssets.onboarding_2,
            onboarding_3: $event,
            primary_color: this._pwaAssets.primary_color,
            splash_screen: this._pwaAssets.splash_screen,
            theme_color: this._pwaAssets.theme_color,
            logo: this._pwaAssets.logo
        });
    }

    public async saveChanges() {
        const { data, error } = await this.profileService.setPwaAssets(this._pwaAssets);
        if (!error) {
            await this.generate();
        }

    }

    private async generate() {
        const { data, error } = await this.profileService.generatePwaApp();
        if (!error) {
            this.profileService.appVisualsCompleted.next(true);
            this.router.navigate(['/']);
        } else {
            this.profileService.appVisualsCompleted.next(false);
        }
    }

    private calculateNumberOfImages(e) {
        if (!e) {
            this.numberOfImages--;
        } else {
            this.numberOfImages++;
        }
    }
    private _pwaAssets: AppVisualsV2 = null;
}
