import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FitConfigProviderService } from '@shared/fit-config-provider.service';
import { AuthorizationService } from '@shared/authorization.service';

@Injectable({ providedIn: 'root' })

export class NotForCoachGuard implements CanActivate {
    constructor(private fitConfig: FitConfigProviderService, private router: Router, private auth: AuthorizationService) {}

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return new Promise((resolve, reject) =>{
            this.auth.user.subscribe(user => {
                if (user) {
                    if (!this.fitConfig.isAdministrator && this.fitConfig.isCoach) {
                        //pure coach, not admin + coach
                        resolve(false);
                        return this.router.navigateByUrl('/');
                    }
                    
                    resolve(true);
                }
            })
        })
    }
}
