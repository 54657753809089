import { EventEmitter, Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({ providedIn: 'root' })
export class LibraryModalService {
    constructor(private modalService: BsModalService) {}

    public selectedItem = new EventEmitter();

    public show(component: any, library: string) {
        const initialState = { library };

        this.modalService.show(component, { initialState });
    }
}
