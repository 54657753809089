<div class="wizard-box"
     *ngIf="owner">
    <div class="container">
        <div class="wizard-box-stepper-box">
            <div *ngIf="!fromDashboard"
                 class="stepper-box included-app seventh-step"></div>
            <a (click)="onBack()"
               class="back-btn"
               [ngClass]="{'top-0' : fromDashboard}"><img src="../../assets/icons/ic_arrow_left-2.svg" />
               {{ fromDashboard ? 'Back to dashboard' : 'Back' }}</a>
            <ng-container *ngIf="!fromDashboard">
                <h6 *ngIf="user.coach_package.pwa_wizard_advanced_flow">Step {{ !user?.coach_package?.include_custom_application ? '5' : '7' }} of {{ !user?.coach_package?.include_custom_application ? '6' : '8' }}</h6>
                <h6 *ngIf="!user.coach_package.pwa_wizard_advanced_flow">Step {{ !user?.coach_package?.include_custom_application ? '5' : '7' }} of {{ !user?.coach_package?.include_custom_application ? '6' : '8' }}</h6>
            </ng-container>
            <ng-container *ngIf="fromDashboard">
                <h6>Step 4</h6>
            </ng-container>
        </div>
        <div class="wizard-box-content">
            <h2>Let us know you better</h2>
            <p class="mb-0">Information below is used to create your account and will not be shared with anyone.</p>
            <form class="owner-info"
                  #ownerForm="ngForm">

                <div class="row-full-width">
                    <div class="form-row ps-0">
                        <p class="input-label">Name</p>
                        <input class="input"
                               type="text"
                               required
                               [disabled]="true"
                               placeholder="{{owner.first_name}}"
                               name="ownerName"
                               [(ngModel)]="owner.first_name" />
                    </div>
                    <div class="form-row">
                        <p class="input-label">Surname</p>
                        <input class="input"
                               type="text"
                               required
                               [disabled]="true"
                               placeholder="{{owner.last_name}}"
                               name="ownerLastname"
                               [(ngModel)]="owner.last_name" />
                    </div>
                    <div class="form-row">
                        <p class="input-label">Email</p>
                        <input class="input"
                               type="text"
                               [disabled]="true"
                               required
                               placeholder="{{owner.email}}"
                               name="owneremail"
                               [(ngModel)]="owner.email" />
                    </div>
                    <div class="form-row ps-0">
                        <p class="input-label">City</p>
                        <input class="input"
                               required
                               type="text"
                               placeholder="Enter city name"
                               name="ownerCity"
                               [(ngModel)]="owner.city"
                               (ngModelChange)="updateOwnerInfo()"
                               [ngClass]="{ 'form-submitted': ownerForm.submitted || submitted}" />
                    </div>
                    <div class="form-row">
                        <p class="input-label">Street address and number</p>
                        <input class="input"
                               required
                               type="text"
                               placeholder="Enter street address and number"
                               name="ownerAddressOne"
                               [(ngModel)]="owner.street_name_and_number"
                               (ngModelChange)="updateOwnerInfo()"
                               [ngClass]="{ 'form-submitted': ownerForm.submitted || submitted }" />
                    </div>
                    <div class="form-row">
                        <p class="input-label">Street address and number 2 (opt.)</p>
                        <input class="input"
                               type="text"
                               placeholder="Enter street address and number 2"
                               name="ownerAddressTwo"
                               [(ngModel)]="owner.street_name_and_number_line_2"
                               [ngClass]="{ 'form-submitted': ownerForm.submitted || submitted }" />
                    </div>
                    <div class="form-row ps-0">
                        <p class="input-label">Country</p>
                        <ng-select name="ownerCountry"
                                   class="select-input"
                                   [items]="countries"
                                   [searchable]="true"
                                   bindLabel="text"
                                   dropdownPosition="top"
                                   bindValue="text"
                                   required
                                   placeholder="Select your country"
                                   [(ngModel)]="owner.country"
                                   (ngModelChange)="updateOwnerInfo()"
                                   [ngClass]="{ 'form-submitted': ownerForm.submitted || submitted}">
                        </ng-select>
                    </div>
                    <div class="form-row">
                        <p class="input-label">State</p>
                        <input class="input"
                               required
                               type="text"
                               placeholder="State"
                               name="ownerState"
                               [(ngModel)]="owner.state"
                               (ngModelChange)="updateOwnerInfo()"
                               [ngClass]="{ 'form-submitted': ownerForm.submitted || submitted}" />
                    </div>
                    <div class="form-row"
                         stepperFix>
                        <p class="input-label">Postal code/Zip code</p>
                        <input class="input"
                               required
                               type="text"
                               placeholder="Enter your postal / zip code"
                               name="ownerZipCode"
                               [(ngModel)]="owner.zip_code"
                               (ngModelChange)="updateOwnerInfo()"
                               [ngClass]="{ 'form-submitted': ownerForm.submitted || submitted }" />
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="d-flex w-100 bottom-wizard-box">
        <button class="btn button-true-green"
                [disabled]="!ownerForm.valid"
                (click)="nextStep(ownerForm)"
                [ngClass]="{'disabled' : !ownerForm.valid}">{{ fromDashboard ? 'Save changes' : 'Continue' }}</button>
    </div>
</div>
