import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'documentation-link',
  templateUrl: './documentation-link.component.html',
  styleUrls: ['./documentation-link.component.scss']
})
export class DocumentationLinkComponent implements OnInit {


  @Input() page: string;
  @Input() gotoID: string;
  
  public docuWiki= 'https://wiki.leanondigital.com/doku.php';
  
  public docsLink: string;
  
  constructor() { }
  
  ngOnInit(): void {
  this.docsLink = `${this.docuWiki}?id=${this.page}#${this.gotoID}`;
  }
}
