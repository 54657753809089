export const style = {
    base: {
        iconColor: "#FF1E00",
        color: '#000',
        margin: '0 auto',
        fontWeight: '500',
        minHeight: '45px',
        fontFamily: 'Montserrat, sans-serf',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
            color: '#fce883',
        },
        '::placeholder': {
            color: '#bbb',
        },
    },
    invalid: {
        iconColor: '#FFC7EE',
        color: '#FFC7EE',
    },
    iFrame: {
        minHeight: '200px',
        border: '1px solid red'
    }
};
