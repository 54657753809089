import { Component, OnInit } from "@angular/core";
import { PageableComponent } from "@shared/pageable.component";
import { BsModalRef } from "ngx-bootstrap/modal";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ExerciseLibraryResponse, ExerciseLibraryService } from "../../../libraries/exercise/exercise.service";
import { LibraryModalService } from "./library-modal.service";

@Component({
    selector: "library-modal",
    templateUrl: "./library-modal.component.html",
    styleUrls: ["./library-modal.component.scss"],
})
export class LibraryModalComponent extends PageableComponent<ExerciseLibraryResponse> implements OnInit {
    constructor(
        private exerciseLibrary: ExerciseLibraryService,
        private bsModalRef: BsModalRef,
        private service: LibraryModalService
    ) {
        super();

        this.searchQueryStream.pipe(
            debounceTime(500)
        ).subscribe(() => (
            this._page = 1,
            this.load()
        ));

        this.load();
    }

    public library: string;
    public title = '';

    public onCloseModal() {
        this.bsModalRef.hide();
    }

    public onSelectedItem(item: any) {
        this.service.selectedItem.emit(item);
        this.bsModalRef.hide();
    }

    public async load() {
        switch (this.library) {
            case 'exercise':
                this.exerciseLibrary.search(this.searchQuery, this._page)
                    .then((response) => {
                        if (!response.error) {
                            this._list = response.data;
                        }
                    });
                break;
        }
    }
}
