import { Injectable } from '@angular/core';

import { FitApiClientService, FitList } from '@shared/fit-api-client.service';

interface LevelSearch {
    id: number;
    name: string;
    order: number;
}

interface LevelResponse extends LevelSearch {
    description: string;
}

interface LevelRequest {
    name: string;
    order: number;
    description: string;
}

export { FitList, LevelSearch, LevelResponse };

@Injectable({ providedIn: 'root' })

export class LevelService {
    constructor(private fitApi: FitApiClientService) { }

    public search(query: string, page: number) {
        return this.fitApi.get<FitList<LevelSearch>>('/admin/level', {
            search: query,
            page: page.toString()
        });
    }

    public all() {
        return this.fitApi.get<any>('/admin/level/list');
    }
    
    public get(id: number) {
        return this.fitApi.get<LevelResponse>('/admin/level/' + id);
    }
    
    public create(level: LevelResponse) {
        return this.fitApi.put<LevelResponse>('/admin/level/0', this.mapToRequest(level));
    }
    
    public update(level: LevelResponse) {
        return this.fitApi.put<LevelResponse>('/admin/level/' + level.id, this.mapToRequest(level));
    }
    
    public delete(id: number) {
        return this.fitApi.delete<any>('/admin/level/' + id);
    }

    private mapToRequest(level: LevelResponse): LevelRequest {
        return {
            name: level.name,
            description: level.description,
            order: level.order
        };
    }
}
