import { Component } from '@angular/core';
import { Customer, ProgramService } from '@app/program/program.service';
import { Program } from '@app/program/types';
import { NotificationsService } from '@shared/notifications.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TailorMadeService } from '../../../tailor-made/tailo-made.service';

@Component({
    selector: 'app-assign-modal',
    templateUrl: './assign-modal.component.html',
    styleUrls: ['./assign-modal.component.scss']
})
export class AssignModalComponent {

    constructor(
        private bsModalRef: BsModalRef,
        private notifications: NotificationsService,
        private programService: ProgramService,
        private tailorService: TailorMadeService) { }

    public program: Program;
    public user: Customer;

    public price: number;
    public currencyCode: string;

    public freeProgram = false;

    public currencyList: string[] = ['USD', 'EUR', 'GBP'];

    public async onFormSubmit() {;
        if (!this.program.is_published) {
            return this.notifications.error('Error', `Program must be published first`);
        }
        if(this.freeProgram){
            return this.assignAsFreeProgram();
        }
        const { data, error } =
            await this.tailorService.assignTailorProgramToUser(this.program.id, this.user.id, this.price, this.currencyCode);
        if (!error) {
            this.bsModalRef.hide();
            this.notifications.success('Success', `${this.user.email} will now receive payment link`);
        } else {
            this.notifications.error('Error', `${error}`);
        }
    }

    public async assignAsFreeProgram(){
        const { data, error } = await this.programService.assignProgramToUser(this.program.id, this.user.id)
        if(!error){
            this.notifications.success('Success', `Tailor made program assigned`);
            this.bsModalRef.hide();
        }
    }

    public onModalClose() {
        this.bsModalRef.hide();
    }
}
