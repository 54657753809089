<div class="wizard-box">
    <div class="container">
        <div class="wizard-box-stepper-box">
            <div *ngIf="!fromDashboard"
                 [ngClass]="{'included-app': includedCustomApp}"
                 class="stepper-box fourth-step"></div>
            <a [routerLink]="fromDashboard ? '/' : '/profile/onboarding-wizard/upload-images'"
               class="back-btn"
               [ngClass]="{'top-0' : fromDashboard}"><img src="../../assets/icons/ic_arrow_left-2.svg" /><span class="back-arrow-text">{{ fromDashboard ? 'Back to dashboard' : 'Back' }}</span></a>
                <ng-container *ngIf="!fromDashboard">
                    <h6>Step 4 of {{ !includedCustomApp ? '6' : '8' }}</h6>
                </ng-container>
                <ng-container *ngIf="fromDashboard">
                    <h6>Step 4</h6>
                </ng-container>
        </div>
        <div class="wizard-box-content">
            <h2>Upload your logo</h2>
            <p *ngIf="!includedCustomApp"
               class="mb-0">Your logo will be shown within your branded web app and on the home screen of the user's
                phone</p>
            <p *ngIf="includedCustomApp"
               class="mb-0">Your logo will be shown within your personalized fitness app, in the app stores and on the
                home screen of the user's phone.</p>
            <div class="theme-color-content flex-wrap">
                <form #appVisualsForm="ngForm"
                      class="w-100">
                    <div class="app-visuals flex-column">
                        <div class="d-flex w-100 images-and-preview-box">
                            <div class="logo-wrap">
                                <div class="form-row logo mb-3">
                                    <div class="logo-upload-wrapper">
                                        <file-upload [id]="'logo'"
                                                     name="logo"
                                                     [required]="true"
                                                     [type]="'image'"
                                                     [manualClass]="'logo'"
                                                     [isAvatar]="true"
                                                     [createLogoFromName]="createLogoFromName"
                                                     [brandName]="brandName"
                                                     [themeClass]="themeColor"
                                                     [ngClass]="{'form-submitted': appVisualsForm.submitted }"
                                                     [(ngModel)]="logo"
                                                     (uploadedFile)="uploadedLogo($event)"></file-upload>
                                    </div>
                                </div>
                                <p (click)="onCreateLogoFromName()"
                                   class="switch-logo">Or click here to use logo that we have generated for you. You can always upload new logo later.</p>
                                <!-- <p (click)="onCreateLogoFromName()"
                                   class="switch-logo"></p> -->
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="d-flex w-100 bottom-wizard-box">
        <button *ngIf="!fromDashboard"
                (click)="continue()"
                [disabled]="!logo"
                class="btn button-true-green"
                [ngClass]="{'disabled' : !logo}">Continue</button>
        <button *ngIf="fromDashboard"
                type="button"
                class="btn button-true-green"
                (click)="saveChanges()">Save changes</button>
    </div>
</div>
