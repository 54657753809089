import { ElementRef, OnChanges } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
  selector: '[IphoneContentFix]'
})
export class ContentFixDirective {

 
  constructor(el: ElementRef) { 
    if(this.checkPlatform()){
      el.nativeElement.classList.add('ios-fix-content');
    }
  }

  private checkPlatform(){
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform);
  }


}
