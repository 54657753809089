import { Injectable } from '@angular/core';
import { FitApiClientService, FitList } from '@shared/fit-api-client.service';
import { GalleryItem } from '@app/gallery/gallery.service';

interface MuscleGroupBase {
    id: number;
    name: string;
    order: number;
}

interface MuscleGroupById extends MuscleGroupBase {
    image: GalleryItem;
}

interface MuscleGroupRequest {
    name: string;
    image_id: number;
    order: number;
}

export { FitList, MuscleGroupBase, MuscleGroupById, MuscleGroupRequest };

@Injectable({ providedIn: 'root' })

export class MuscleGroupService {
    constructor(private fitApi: FitApiClientService) { }

    public search(query: string, page: number) {
        return this.fitApi.get<FitList<MuscleGroupBase>>('/admin/muscle-group', {
            search: query,
            page: page.toString()
        });
    }

    public all() {
        return this.fitApi.get<any>('/admin/muscle-group/list');
    }
    
    public get(id: number) {
        return this.fitApi.get<MuscleGroupById>('/admin/muscle-group/' + id);
    }

    public create(muscleGroup: MuscleGroupRequest) {
        return this.fitApi.put<MuscleGroupById>('/admin/muscle-group/0', muscleGroup);
    }

    public update(muscleGroupId: number, muscleGroup: MuscleGroupRequest) {
        return this.fitApi.put<MuscleGroupById>('/admin/muscle-group/' + muscleGroupId, muscleGroup);
    }

    public delete(id: number) {
        return this.fitApi.delete<any>('/admin/muscle-group/' + id);
    }
}
