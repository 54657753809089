import { Injectable } from '@angular/core';
import { FitApiClientService } from '@shared/fit-api-client.service';

@Injectable()
export class CloudMessagingService {
    constructor(private fitApi: FitApiClientService) { }

    public notify(request: { object_id: number; object_type: string; }) {
        return this.fitApi.post<any>('/admin/notification/', request);
    }
}
