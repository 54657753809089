import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormComponent } from './form/form.component';
import { OwnerInfoComponent } from './owner-info/owner-info.component';
import { BillingInfoComponent } from './billing-info/billing-info.component';
import { AppInfoComponent } from './app-info/app-info.component';
import { AppVisualsComponent } from './app-visuals/app-visuals.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { CancellationComponent } from './subscriptions/cancellation/cancellation.component';
import { OnboardingWizardComponent } from './onboarding-wizard/onboarding-wizard.component';
import { LetsBeginComponent } from './onboarding-wizard/lets-begin/lets-begin.component';
import { ThemeColorComponent } from './onboarding-wizard/theme-color/theme-color.component';
import { PrimaryColorComponent } from './onboarding-wizard/primary-color/primary-color.component';
import { UploadImagesComponent } from './onboarding-wizard/upload-images/upload-images.component';
import { UploadLogoComponent } from './onboarding-wizard/upload-logo/upload-logo.component';
import { UploadingAssetsComponent } from './onboarding-wizard/uploading-assets/uploading-assets.component';
import { AppReadyComponent } from './onboarding-wizard/app-ready/app-ready.component';


const routes: Routes = [
    { path: '', component: FormComponent },
    { path: 'app-info', component: AppInfoComponent },
    { path: 'app-visuals', component: AppVisualsComponent },
    { path: 'owner-info', component: OwnerInfoComponent },
    { path: 'billing-info', component: BillingInfoComponent },
    { path: 'subscriptions', component: SubscriptionsComponent },
    { path: 'cancelation', component: CancellationComponent },
    {
        path: 'onboarding-wizard', component: OnboardingWizardComponent, children: [
            { path: 'lets-begin', component: LetsBeginComponent },
            { path: 'theme-color', component: ThemeColorComponent },
            { path: 'theme-color/:dashboard', component: ThemeColorComponent },
            { path: 'primary-color', component: PrimaryColorComponent },
            { path: 'primary-color/:dashboard', component: PrimaryColorComponent },
            { path: 'upload-images', component: UploadImagesComponent },
            { path: 'upload-images/:dashboard', component: UploadImagesComponent },
            { path: 'upload-logo', component: UploadLogoComponent },
            { path: 'upload-logo/:dashboard', component: UploadLogoComponent },
            { path: 'uploading-assets', component: UploadingAssetsComponent },
            { path: 'app-ready', component: AppReadyComponent },
            { path: 'app-info', component: AppInfoComponent },
            { path: 'app-info/:dashboard', component: AppInfoComponent },
            { path: 'owner-info', component: OwnerInfoComponent },
            { path: 'owner-info/:dashboard', component: OwnerInfoComponent },
            { path: 'billing-info', component: BillingInfoComponent },
            { path: 'billing-info/:dashboard', component: BillingInfoComponent },
            { path: '**', redirectTo: 'lets-begin' }
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfileRoutingModule { }
