import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { record as rrwebRecord } from 'rrweb';
import { eventWithTime } from 'rrweb/typings/types';

import { v4 as uuidv4 } from 'uuid';

import { FitApiClientService, FitList } from '@app/shared/fit-api-client.service';
import { FitConfigProviderService } from '@shared/fit-config-provider.service';

interface ClientMetaData {
    userAgent: string;
    languages: string[];
    geo_ip_data?: GeoIpData;
}

interface GeoIpData {
    city: string;
    continent_code: string;
    continent_name: string;
    country_code: string;
    country_name: string;
    ip: string;
    latitude: number;
    longitude: number;
    location: LocationData;
    region_code: number;
    region_name: string;
    type: string;
    zip: string;
}
interface LocationData {
    geoname_id: number | string;
    capital: string;
    languages: string[];
    country_flag: string;
    country_flag_emoji: string;

}

interface RecordingStoreRequest {
    session_id: string;
    route: string;
    events: any;
    metadata: ClientMetaData;
}

export interface RecordingListItem {
    id: number;
    session_id: string;
    route: string;
    metadata: ClientMetaData;
    user_id?: number;
    user_email?: string;
    created_at: string;
}

export interface RecordingDetails extends RecordingListItem {
    events_url: string;
}

@Injectable({
    providedIn: 'root'
})
export class RecordingService {
    private static RECORDING_SESSION_ID_KEY = 'recording-session-id';

    constructor(private route: Router,
        private api: FitApiClientService,
        private fitConfig: FitConfigProviderService) {
        
        this.fitConfig.getConfig()
            .then(config => {
                if (config.env.name === 'production') {
                    rrwebRecord({
                        emit: (e, isCheckout) => {
                            events.push(e);
                            if (isCheckout) {
                                const eventsToSend = events;
                                setTimeout(() => {
                                    this.pipe.next(eventsToSend);
                                });
                                events = [];
                            }
                        },
                        checkoutEveryNms: 15000
                    });
                }
            })

        this.pipe.subscribe(event => this.pushEvents(event));

        let events: eventWithTime[] = [];


    }


    public search(
        session_id: string,
        page: number,
        application_id?: number
    ) {
        const params: any = {
            page: page.toString()
        };

        if (application_id) {
            params.application_id = application_id;
        }

        if (session_id) {
            params.session_id = session_id;
        }

        return this.api.get<FitList<RecordingListItem>>('/admin/recording', params);
    }

    public get(
        id: number,
        application_id?: number
    ) {
        let route = `/admin/recording/${id}`;
        if (application_id) {
            route = `${route}/${application_id}`;
        }
        return this.api.get<RecordingDetails>(route);
    }



    private pushEvents(events: eventWithTime[]) {
        if (this.route.url.indexOf('recording') > -1) {
            return;
        }
        const request: RecordingStoreRequest = {
            events: JSON.stringify(events),
            metadata: {
                languages: Array.from(window.navigator.languages),
                userAgent: window.navigator.userAgent
            },
            route: this.route.url,
            session_id: this.sessionId()
        };
        this.api.put('/admin/recording', request);
    }

    private sessionId() {
        return this.session;
        // let sessionId = sessionStorage.getItem(RecordingService.RECORDING_SESSION_ID_KEY);
        // if (!sessionId) {
        //     sessionId = uuidv4();
        //     sessionStorage.setItem(RecordingService.RECORDING_SESSION_ID_KEY, sessionId);
        // }
        // return sessionId;
    }
    private session = uuidv4();

    private pipe = new Subject<eventWithTime[]>();
}
