<div *ngIf="type === 'ResolutionCentreMessage'"
     class="notification-message"
     [ngClass]="{'seen': seen}">
    <a routerLink="/resolution-center"
       (click)="markNotificationSeen.emit(notification_id)">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">New message in resolution center</span>
        <span>Lean On Support sent you a message</span>
        <span>{{ timestamp | dateFormat}}</span>
    </a>
</div>

<div *ngIf="type === 'ChatMessage'"
     class="notification-message"
     [ngClass]="{'seen': seen}">
    <a routerLink="/client-messages/{{ id }}"
       *ngIf="!suspended"
       (click)="markNotificationSeen.emit(notification_id)">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">New client message</span>
        <span>{{ sender }} sent you a message</span>
        <span>{{ timestamp | dateFormat}}</span>
    </a>
    
    <span class="suspended"
          *ngIf="suspended">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">New client message</span>
        <span>{{ sender }} sent you a message</span>
        <span>{{ timestamp | dateFormat}}</span>
    </span>
</div>

<div *ngIf="type === 'GroupChatMessage'"
     class="notification-message"
     [ngClass]="{'seen': seen}">
    <span class="suspended"
          (click)="markNotificationSeen.emit(notification_id)">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">{{ title }}</span>
        <span>{{ message }}</span>
        <span>{{ timestamp | dateFormat}}</span>
    </span>
</div>

<div *ngIf="type === 'GroupChatObjection'"
     class="notification-message"
     [ngClass]="{'seen': seen}">
    <a routerLink="/reported-messages/edit/{{ id }}"
       *ngIf="!suspended"
       (click)="markNotificationSeen.emit(notification_id)">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">{{ title }}</span>
        <span>{{ message }}</span>
        <span>{{ timestamp | dateFormat}}</span>
    </a>
    
    <span class="suspended"
          *ngIf="suspended">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">{{ title }}</span>
        <span>{{ message }}</span>
        <span>{{ timestamp | dateFormat}}</span>
    </span>
</div>

<div *ngIf="type === 'ScheduledEvent'"
     class="notification-message"
     [ngClass]="{'seen': seen}">
    <a routerLink="/scheduler/calendar/{{type | lowercase}}/{{id}}"
       *ngIf="!suspended"
       (click)="markNotificationSeen.emit(notification_id)">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">{{ title }}</span>
        <span>{{ sender }} scheduled new event on</span>
        <span>{{ timestamp | dateFormat}}</span>
    </a>
    
    <span class="suspended"
          *ngIf="suspended">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">{{ title }}</span>
        <span>{{ sender }} scheduled new event on</span>
        <span>{{ timestamp | dateFormat}}</span>
    </span>
</div>

<div *ngIf="type === 'LiveEvent'"
     class="notification-message"
     [ngClass]="{'seen': seen}">
    <a routerLink="/live-events"
       *ngIf="!suspended"
       (click)="markNotificationSeen.emit(notification_id)">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">New live event</span>
        <span>{{ sender }} created new live event</span>
        <span>{{ timestamp | dateFormat}}</span>
    </a>
    
    <span class="suspended"
          *ngIf="suspended">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">New live event</span>
        <span>{{ sender }} created new live event</span>
        <span>{{ timestamp | dateFormat}}</span>
    </span>
</div>

<div *ngIf="type === 'LiveEventStart'"
     class="notification-message"
     [ngClass]="{'seen': seen}">
    <a routerLink="/live-events"
       *ngIf="!suspended"
       (click)="markNotificationSeen.emit(notification_id)">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">Upcoming event</span>
        <span>Event scheduled on <code>DATE</code> at <code>TIME</code> starts in <code>SOME HOURS</code></span>
        <span>{{ timestamp | dateFormat}}</span>
    </a>
    
    <span class="suspended"
          *ngIf="suspended">
        <span class="avatar"
              [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
        <span class="noth-header">Upcoming event</span>
        <span>Event scheduled on <code>DATE</code> at <code>TIME</code> starts in <code>SOME HOURS</code></span>
        <span>{{ timestamp | dateFormat}}</span>
    </span>
</div>

<div *ngIf="type === 'QuestionnaireResponseFilled'"
     class="notification-message"
     [ngClass]="{'seen': seen}">
      <a routerLink="/user/user/details/{{id}}/questionnaire"
         (click)="markNotificationSeen.emit(notification_id)">
            <span class="avatar"
                  [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
            <span class="noth-header">Questionnaire filled out</span>
            <span>{{ message }}</span>
            <span>{{ timestamp | dateFormat}}</span>
      </a>
</div>

<div *ngIf="type === 'NoSuitableProgramFound'"
     class="notification-message"
     [ngClass]="{'seen': seen}">
      <a routerLink="/user/user/details/{{id}}/questionnaire"
         (click)="markNotificationSeen.emit(notification_id)">
            <span class="avatar"
                  [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
            <span class="noth-header">{{ title }}</span>
            <span>{{ message }}</span>
            <span>{{ timestamp | dateFormat}}</span>
      </a>
</div>

<div *ngIf="type === 'FeedPostCommentPosted'"
     class="notification-message"
     [ngClass]="{'seen': seen}">
      <a routerLink="review/comment"
         (click)="markNotificationSeen.emit(notification_id)">
            <span class="avatar"
                  [ngStyle]="{'background-image' : !seen ? 'url(assets/icons/ic_new_message.svg)' : 'url(assets/icons/ic_new_message-seen.svg)'}"></span>
            <span class="noth-header">{{ title }}</span>
            <span>{{ message }}</span>
            <span>{{ timestamp | dateFormat}}</span>
      </a>
</div>
