import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[required]'
})
export class RequiredDirective implements AfterViewInit {

    constructor(public el: ElementRef) { }

    ngAfterViewInit() {
        const label = this.el.nativeElement.previousElementSibling;
        if (!label) {
            return;
        }
        if (label.classList.contains('input-label')) {
            label.classList.add('required');
        }

    }
}
