import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ConfirmComponent, ConfirmStatus } from './confirm/confirm.component';


export interface ChooseButton{
    name: string;
    value: string | ConfirmStatus;
}

export type PickedLibrary = 'EXERCISE' | 'PLAN' | 'WORKOUT' | 'CANCELED';

@Injectable()
export class InteractionService {
    constructor(private modalService: BsModalService) { }

    public confirm(question: string, renderAsHtml?: boolean): Promise<boolean> {
        const promise = new Promise<boolean>((resolve, reject) => {
            const initialState = {
                title: question,
                renderAsHtml: renderAsHtml,
                onClose: (status: ConfirmStatus) => {
                    if (status === ConfirmStatus.CONFIRMED) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            };
            this.bsModalRef = this.modalService.show(ConfirmComponent, { initialState, backdrop: false, ignoreBackdropClick: true });
        })
        return promise;
    }

    public choose(question: string, buttonlist: ChooseButton[]): Promise<PickedLibrary> {
        const promise = new Promise<PickedLibrary>((resolve, reject) => {
            const initialState = {
                title: question,
                multiple: true,
                buttonlist,
                onPick: (status: PickedLibrary) => {
                    if (status === 'EXERCISE') {
                        resolve('EXERCISE');
                    } 
                    if (status === 'WORKOUT'){
                        resolve('WORKOUT');
                    }
                    if (status === 'PLAN') {
                        resolve('PLAN');
                    }
                    else {
                        resolve('CANCELED');
                    }
                }
            };
            this.bsModalRef = this.modalService.show(ConfirmComponent, { initialState, backdrop: false, ignoreBackdropClick: true });
        })
        return promise;
    }

    private bsModalRef: BsModalRef;
}
