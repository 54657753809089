<div class="assign-modal">
    <div class="heading">
        <h4>Assign form</h4>
        <img class="close-modal"
             (click)="onModalClose()"
             src="assets/icons/clear.png" />
    </div>
    <div class="assign-info">
        <div class="half">
            <p class="inner-half label">Program: </p>
            <p class="inner-half">{{program.name}}</p>
        </div>
        <div class="half">
            <p class="inner-half label">User:</p>
            <p class="inner-half">{{user.email}}</p>
        </div>
    </div>
    <form #assignForm="ngForm"
          (ngSubmit)="assignForm.valid && onFormSubmit()">
        <div class="form-row">
            <div class="form-row-input">
                <p class="input-label">Price</p>
                <input type="number"
                       required
                       name="price"
                       [disabled]="freeProgram"
                       class="input"
                       [(ngModel)]="price"
                       [ngClass]="{ 'form-submitted': assignForm.submitted }" />
            </div>
            <div class="form-row-input">
                <p class="input-label">Currency</p>
                <ng-select name="currency"
                           class="select-input"
                           placeholder="Select currency"
                           [searchable]="false"
                           [disabled]="freeProgram"
                           required
                           [items]="currencyList"
                           [(ngModel)]="currencyCode"
                           [ngClass]="{ 'form-submitted': assignForm.submitted }">
                </ng-select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-row-input d-flex align-items-center">
                <input type="checkbox" 
                       class="checkbox input-checkbox"
                       [class.true]="freeProgram"
                       [class.false]="!freeProgram"
                       name="free_proram" 
                       [(ngModel)]="freeProgram"/>
                <p class="input-label ml-2">Free plan</p>
            </div>
        </div>
        <div class="form-row form-row-submit">
            <button type="submit"
                    class="button-gray"
                    [disabled]="!assignForm.valid">Assign</button>
        </div>
    </form>
</div>