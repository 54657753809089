import { ElementRef, OnChanges } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
  selector: '[IphoneLogoutFix]'
})
export class IphoneLogoutFixDirective {


  constructor(el: ElementRef) {
    if (this.checkPlatform()){
      el.nativeElement.classList.add('logout-iphone');
    }
  }

  private checkPlatform(){
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform);
  }


}
