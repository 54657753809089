import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { GalleryItem } from '@app/gallery/gallery-item';
import { AuthorizationService, User } from '@shared/authorization.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppInfoComponent } from '../app-info/app-info.component';
import { AppVisualsComponent } from '../app-visuals/app-visuals.component';
import { BillingInfoComponent } from '../billing-info/billing-info.component';
import { OwnerInfoComponent } from '../owner-info/owner-info.component';
import { AppVisuals, ProfileService } from '../profile.service';
import { AppInfo, BillingInfo, OwnerResponse } from '../types';


type ReviewStatus = 'STATUS_INSUFFICIENT_DATA' | 'STATUS_IN_DEVELOPMENT' | 'STATUS_IN_PRODUCTION';
@Component({
    selector: 'profile-stepper',
    templateUrl: './profile-stepper.component.html',
    styleUrls: ['./profile-stepper.component.scss'],
})
export class ProfileStepperComponent implements OnInit, AfterViewInit {
    constructor(
        private bsModalRef: BsModalRef,
        private profileService: ProfileService,
        private router: Router,
        private auth: AuthorizationService
    ) { }

    public get user() {
        return this._user;
    }
    public set user(value) {
        this._user = value;
    }



    @ViewChild('stepperForm') public stepperForm: NgForm;
    @ViewChild('stepperContent') public stepperContent: ElementRef;
    @ViewChild(AppInfoComponent) public profileAppInfo: AppInfoComponent;
    @ViewChild(AppVisualsComponent) public profileAppVisuals: AppVisualsComponent;
    @ViewChild(OwnerInfoComponent) public profileOwnerInfo: OwnerInfoComponent;
    @ViewChild(BillingInfoComponent) public profileBillingInfo: BillingInfoComponent;
    @ViewChild('stepperHeading') public stepperHeading: ElementRef;

    public appInfo: AppInfo = null;
    public billingInfo: BillingInfo = null;
    public appVisuals: AppVisuals = null;
    public ownerInfo: OwnerResponse = null;

    public images: GalleryItem[] = [];
    public videos: GalleryItem[] = [];

    public isLocked = false;

    public copyCheckbox = false;

    public brandName = '';

    public showReviewPopup = false;

    public disabled = true;

    public showStepperScrollLine = false;

    public stepHeadings = [
        'Choose style for your branded app',
        'Fill out information about the app',
        'Let us know you better',
        'Enter your billing information',
        'Cards'
    ];

    @Input() public step = 1;

    ngOnInit(): void {
        this.profileService.appInfoCompleted.subscribe(bool => {
            this.user.wizard_section_status.app_info = bool;
        });

        this.profileService.ownerInfoCompleted.subscribe(bool => {
            this.user.wizard_section_status.your_info = bool;
        });

        this.profileService.appVisualsCompleted.subscribe(bool => {
            this.user.wizard_section_status.app_visuals = bool;
        });

        this.profileService.billingInfoCompleted.subscribe(bool => {
            this.user.wizard_section_status.billing_info = bool;
        });

        this.auth.user.subscribe((user) => {
            if (user) {
                this._user = user;
                this.brandName = user.application_name;
                if (!user.wizard_section_status) {
                    this.bsModalRef.hide();
                    return this.router.navigate(['']);
                }
            }
        });
    }


    public ngAfterViewInit() {
        this.profileService.hasBillingInfo.subscribe(bool => {
            if (bool && this.profileBillingInfo) {
                this.profileBillingInfo.showSaveCard = true;
                this.step++;
            }
        });
        this.recheckScrollable();
    }

    public isStepperScrollable(){
        if(!this.stepperContent){
            return this.recheckScrollable();
        }
        if(this.stepperContent.nativeElement.scrollHeight > this.stepperContent.nativeElement.clientHeight){
            this.showStepperScrollLine = true;
        }else{
            this.showStepperScrollLine = false;
        }
    }

    public recheckScrollable(){
        setTimeout(()=>{
            this.isStepperScrollable();
        }, 1000)
    }

    public onSkipStep() {
        this.stepperForm.resetForm();
        this.step++;
        this.scrollTotop();
        this.recheckScrollable();
    }

    public scrollTotop() {
        if (this.stepperHeading.nativeElement) {
            this.stepperHeading.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        this.recheckScrollable();
    }

    public onStepBack() {
        this.stepperForm.resetForm();
        if (this.step > 1) {
            this.step--;
            this.scrollTotop();
            this.recheckScrollable();
        }
    }

    public async onFinishWizzard() {
        sessionStorage.setItem('profile-stepper-closed', 'true');
        await this.auth.refreshUserData();
        if (this.user.status_mobile_apps) {
            this.showReviewPopup = true;
        } else {
            alert('No status data');
            this.onCloseWizzard();
        }
    }

    public onCloseWizzard() {
        this.router.navigate(['/']);
        this.bsModalRef.hide();
    }

    public async updateByStep(step: number) {
        if (!this.stepperForm.invalid) {
            this.disabled = true;
            if (step === 1) {
                await this.profileAppVisuals.onFormSubmit();
                this.disabled = false;
                this.step++;
            } else if (step === 2) {
                await this.profileAppInfo.update();
                this.disabled = false;
                this.step++;
            } else if (step === 3) {
                await this.profileOwnerInfo.update();
                this.disabled = false;
                this.step++;
            } else if (step === 4) {
                await this.profileBillingInfo.onFormSubmit();
                this.disabled = false;
                this.profileService.billingInfoCompleted.next(true);
                this.profileBillingInfo.showSaveCard = true;
                this.step++;
            }
            this.stepperForm.resetForm();
            this.scrollTotop();
        }
    }

    public onCopyFromYourInfo() {
        if (this.copyCheckbox) {
            return this.profileService.copyOwnerFromStepper.emit(true);
        }
        return this.profileService.copyOwnerFromStepper.emit(false);
    }

    private _user: User;
}
