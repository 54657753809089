<div class="gallery-wrapper">
    <div class="gallery-header">
        <h4>Select {{type === 'all' ? 'image or video' : type}}</h4>
        <img class="close-modal"
             (click)="bsModalRef.hide()"
             src="assets/icons/clear.png" />
    </div>
    <app-gallery (itemSelected)="select($event)"
                 [type]="type"
                 [source]="source"
                 class="gallery-body"></app-gallery>
</div>