import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { GalleryItem } from '@app/gallery/gallery-item';
import { UserService } from '@app/user/user.service';
import { NotificationsService } from '@shared/notifications.service';
import { InteractionService } from '@app/interaction/interaction.service';
import { PageableComponent } from '@shared/pageable.component';
import { distinctUntilChanged, debounceTime } from 'rxjs';
import { ClientMessage } from '../resolution-center.component';
import { Attachment, BroadcastRequest, MessageRequest, ResolutionCenterService, Thread } from '../resolution-center.service';

@Component({
    selector: 'client-center',
    templateUrl: './client-center.component.html',
    styleUrls: ['./client-center.component.scss']
})

export class ClientCenterComponent extends PageableComponent<any> implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private resolutionCenterService: ResolutionCenterService,
        private userService: UserService,
        private notification: NotificationsService,
        private interaction: InteractionService
    ) {
        super();

        this.searchQueryStream.pipe(
            debounceTime(500)
        ).subscribe(() => (
            this._page = 1,
            this.load()
        ));
    }

    @ViewChild('clientMessageForm') public clientMessageForm: NgForm;
    
    get selectedContactThread() { return this._selectedContactThread; }
    set selectedContactThread(value: any) {
        if (value !== this._selectedContactThread) {
            this._selectedContactThread = value;
            //this.onSelectedContactThread(this._selectedContactThread);
            this.getContactMessages(this._selectedContactThread.id)
        }
    }

    get messages() { return this._messages; }
    set messages(value) { this._messages = value; }
    get uploadCounter() { return this._uploadedCounter; }

    public showCreateNew = true;
    public showCreateNewForm = true;
    public createNewButton = false;
    public noSearchResult = false;
    public showLoadConversations = false;
    public showLoadMoreThreads = false;
    public showLoadMoreMessages = false;
    public loadingMessages = false;
    public sending = false;
    public newContact = null;
    public messageText = '';
    public defaultAvatar = 'assets/icons/ic_placeholder.svg';
    public availableReactions = ['🔥', '👍', '👎', '😂', '😮', '❤️', '🙏', '💪', '✅', '👏', '😍', '😥'];
    public uploadedFiles = [];
    public users = [];
    public conversations = [];
    public customerName = '';
    public fileList = [];
    public progress = 0;
    public today = '';
    
    @Output() public showNewButton = new EventEmitter<boolean>();

    public searchContact = new EventEmitter<string>();

    ngOnInit(): void {
        const now = new Date(Date.now());
        //this.today = now.getFullYear() + '-' + ('0' + (now.getMonth() + 1)).slice(-2) + '-' + now.getDate().toString().padStart(2, '0');
        this.today = now.getFullYear() + '-' + (now.getMonth() + 1).toString().padStart(2, '0') + '-' + now.getDate().toString().padStart(2, '0');
        
        this.route.paramMap.subscribe(params => {
            if (params.has('id')) {
                this.resolutionCenterService.getContactThreadByMessageId(parseInt(params.get('id'), 10)).then(res => {
                    this.selectedContactThread = res.data;
                });
            }
        });

        this.searchContact.pipe(
            distinctUntilChanged(),
            debounceTime(400)
        ).subscribe(term => {
            this.userService.searchUser(term, 1)
                .then(response => {
                    this.users = response.data.data;
                });
        });
        
        this.loadContactThreadList();
    }

    public async load() {
        this.loadContactThreadList();
    }

    public onShowContactForm() {
        this.showCreateNewForm = true;
        //this.selectedContactThread = null;
        this.showCreateNew = false;
        this.createNewButton = false;
    }

    public onBackToContactList() {
        this.showCreateNew = true;
    }

    public onSelectedContactThread(contactThread: Thread) {
        if (this.selectedContactThread && contactThread.id === this.selectedContactThread.id) {
            return;
        }

        contactThread.unread_messages = 0;
        
        if (this.selectedContactThread) {
            this.resolutionCenterService.cacheThread(this.selectedContactThread.id, this.messageText);
        }
        
        if (contactThread) {
            this.messageText = this.resolutionCenterService.getThreadCahedMessage(contactThread.id);
        }

        //this._messages = [];
        this.createNewButton = true;
        this._loadedPrevious = false;
        this.selectedContactThread = contactThread;
        this._page = 1;
        this.showCreateNewForm = false;
        this.showCreateNew = false;
        this.showNewButton.emit(true);
        this.getContactMessages(contactThread.id);
        this.resolutionCenterService.updateTotalNotifications.next(true);
    }

    public async getContactMessages(id: number, search?) {
        if (this.loadingMessages) {
            return;
        }

        this._messages = [];
        this.loadingMessages = true;
        const { data, error } = await this.resolutionCenterService.getContactMessages(id, this._page);
        if (!error) {
            this.createNewButton = true;
            this.showCreateNewForm = false;
            this._messages = [...this._messages, ...data.data].sort((a, b) => a.id - b.id);
            this.fillEmptyAvatarsWithDefault();
            this.showLoadMoreMessages = this.shouldShowLoadMoreButton(data.current_page, data.last_page);
            setTimeout(() => {
                this.scrollToLastReadedMesssage();
            }, 100);
        }

        this.loadingMessages = false;
    }

    public scrollToLastReadedMesssage() {
        const messageBox: HTMLDivElement = document.querySelector('.message-list');
        if (messageBox) {
            messageBox.scroll({ top: 20000, behavior: 'smooth' });
        }
    }

    public fillEmptyAvatarsWithDefault() {
        this._messages.forEach(message => {
            if (!message.user_avatar) {
                message.user_avatar = this.defaultAvatar;
            }
        });
    }

    public async loadMoreContactThreads() {
        this._contactPage++;
        this.showLoadMoreThreads = false;
        const { data, error } = await this.resolutionCenterService.getContactThreadList(this._contactPage);
        if (!error) {
            this._list.data = [...this.list, ...data.data];
            this.conversations = this._list.data;
            this.showLoadConversations = this.shouldShowLoadMoreButton(data.current_page, data.last_page);
        }
    }

    public async loadContactThreadList() {
        //const { data, error } = await this.resolutionCenterService.getContactThreadList(1, this.customerName);
        const { data, error } = await this.resolutionCenterService.getContactThreadList(1, this.searchQuery);
        if (!error) {
            this._list = data;
            this.conversations = data.data;
            
            if (data.last_page > this._page) {
                this.showLoadConversations = true;
            } else {
                this.showLoadConversations = false;
            }

            if (this.selectedContactThread) {
                this.getContactMessages(this.selectedContactThread.id);
            }
        }
    }

    public removeRecipient() {
        this.newContact = null;
        this.selectedContactThread = null;
    }

    public loadMoreContactMessages() {
        this._page++;
        this._loadedPrevious = true;
        this.showLoadMoreMessages = false;
        this.resolutionCenterService.getContactMessages(this.selectedContactThread.id, this.page).then(res => {
            this._messages = [...this._messages, ...res.data.data].sort((a, b) => a.id - b.id);
            this.fillEmptyAvatarsWithDefault();
            this.showLoadMoreMessages = this.shouldShowLoadMoreButton(res.data.current_page, res.data.last_page);
        });
    }

    public async onDeleteAttachment(file: Attachment) {
        const { data, error } = await this.resolutionCenterService.deleteAttachment(file, undefined, true);
        if (!error) {
            this.checkContactThreadMessages();
            this.notification.success('Success', 'Attachment deleted');
            return this.getContactMessages(this.selectedContactThread.id);
        }
    }

    public async onDeleteMessage(messageId: number) {
        const isConfirmed = await this.interaction.confirm(
            "<b>Delete your message?</b>" + "<br><br>" +
            "Are you sure you want to delete your message? This action cannot be undone.", true
        )
        if (isConfirmed) {
            const { data, error } = await this.resolutionCenterService.deleteMessage(messageId, undefined, true);
            if (!error) {
                let message = this._messages.find(message => message.id === data.id);
                if (message) {
                    message.message = data.message;
                    message.is_message_deleted = data.is_message_deleted;
                }
                this.notification.success('Success', 'You have successfully deleted a message');
                //return this.getContactMessages(this.selectedContactThread.id);
            }
        }
    }

    public async onMarkMessageUnread(messageId: number) {
        const { data, error } = await this.resolutionCenterService.markMessageUnread(messageId);
        if (!error) {
            let message = this._messages.find(message => message.id === data.id);
            if (message) {
                message.is_message_unread = true;
                this.selectedContactThread.unread_messages++; // or get thread from backend and read number of unread messages
            }
        }
    }

    public async onPostReaction(messageId: number, reaction: string) {
        const { data, error } = await this.resolutionCenterService.postReaction(messageId, reaction);
        if (!error) {
            let message = this._messages.find(message => message.id === data.id);
            if (message) {
                message.reactions = data.reactions;
            }
            //return this.getContactMessages(this.selectedContactThread.id);
        }
    }

    public checkContactThreadMessages() {
        this.loadingMessages = true;
        if (!this.selectedContactThread) {
            return;
        }
        if (this._loadedPrevious) {
            this.getOnlyNewContactMessages();
        } else {
            this.getContactMessages(this.selectedContactThread.id);
        }
    }

    public onRemoveAttachment(attachment: GalleryItem) {
        this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== attachment.id);
    }

    public onAttachedFiles(eventFiles) {
        for (let i = 0; i < eventFiles.length; i++) {
            let haveAllready = this.fileList.some(item => item.name === eventFiles[i].name);
            if (!haveAllready) {
                this.fileList.push(eventFiles[i]);
            }
        }
        this.startUploading();
    }

    public uploadAttachment(file) {
        return this.resolutionCenterService.uploadAttachment(file.name, file, this._appId);
    }

    public startUploading() {
        this._uploadedCounter = 0;
        if (!this.fileList.length) {
            return;
        }
        const amountToUpload = this.fileList.length;
        const upload = async () => {
            if (amountToUpload > this._uploadedCounter) {

                const data = await this.uploadAttachment(this.fileList[this._uploadedCounter]);
                if (data) {
                    data.subscribe(
                        res => {
                            if (res.progress) {
                                this.progress = res.progress;
                            } else if (res.response) {
                                this.progress = 0;
                                if (res.response !== undefined) {
                                    this.uploadedFiles.push(res.response.data);
                                }
                                this._uploadedCounter++;
                            } else {
                                this.progress = 0;
                            }
                        },
                        error => {
                            this.progress = 0;
                            this._uploadedCounter++;
                            const err = error.status === 502 ? error.statusText : error.error.errorMessages.join('\r\n');
                            this.notification.error('Error', err);
                        },
                        () => {
                            upload();
                        }
                    );
                }
            } else {
                this.fileList = [];
                this._uploadedCounter = 0;
            }
        };
        upload();
    }

    public async onNewRecipientMessageSubmit(form?: NgForm) {
        if (this.sending) {
            return;
        }

        this.sending = true;
        this.newContact ? this.sendMessageToNewUser(form) : this.broadcastMessageToAll();
        this.sending = false;
    }

    public async sendMesssage() {
        if (this.sending) {
            return;
        }
        
        this.sending = true;
        this.sendMessageToContact();
        this.sending = false;
    }

    private async sendMessageToNewUser(form?: NgForm) {
        const message: ClientMessage = {
            user_id: this.newContact.id,
            message: this.messageText,
            attachment_ids: this.uploadedFiles.map(file => file.id)
        };
        
        if (!message.message && !message.attachment_ids.length) {
            return;
        }

        this.resolutionCenterService.sendMessageToContact(message).then(res => {
            if (form) {
                form.resetForm();
            }

            this.messageText = '';
            this.uploadedFiles = [];
            this.notification.success('Success', 'Message sent');
            //this.loadContactThreadList();
            this.load();
        });
    }

    private async sendMessageToContact() {
        const message: MessageRequest = {
            user_id: this.selectedContactThread.user_id,
            message: this.messageText,
            attachment_ids: this.uploadedFiles.map(file => file.id)
        };
        
        if (!message.message && !message.attachment_ids.length) {
            return;
        }
        
        this.resolutionCenterService.sendMessageToContact(message).then(res => {
            this.clientMessageForm.resetForm();
            this.messageText = '';
            this.uploadedFiles = [];
            this.getOnlyNewContactMessages();
        });
    }

    private async broadcastMessageToAll(form?: NgForm) {
        const message: BroadcastRequest = {
            message: this.messageText,
            attachment_ids: this.uploadedFiles.map(file => file.id)
        };
        
        if (!message.message && !message.attachment_ids.length) {
            return;
        }
        
        const isConfirmed = await this.interaction.confirm(
            "<b>Broadcast message to all clients?</b>" + "<br><br>" +
            "You didn't choose any recipient. Are you sure you want to send this message to all of your clients?", true
        )
        
        if (isConfirmed) {
            this.resolutionCenterService.broadcastMessageToAll(message).then(res => {
                if (form) {
                    form.resetForm();
                }

                this.messageText = '';
                this.uploadedFiles = [];
                this.notification.success('Success', 'Message sent to all users');
            });
        }
    }

    private shouldShowLoadMoreButton(currentPage: number, lastPage: number) {
        return currentPage < lastPage;
    }

    private async getOnlyNewContactMessages() {
        this.resolutionCenterService.getContactMessages(this.selectedContactThread.id, 1).then(res => {
            const newMessages: any = [];
            res.data.data.forEach(message => {
                const check = this._messages.some(msg => msg.id === message.id);
                if (!check) {
                    newMessages.push(message);
                }
            });
            this._messages = [...this._messages, ...newMessages].sort((a, b) => a.id - b.id);
            this.fillEmptyAvatarsWithDefault();
            this.loadingMessages = false;
            if (newMessages.length) {
                setTimeout(() => {
                    this.scrollToLastReadedMesssage();
                }, 100);
            }
        });
    }

    private _selectedContactThread = null;
    private _loadedPrevious;
    private _contactPage = 1;
    private _messages = [];
    private _appId;
    private _uploadedCounter = 0;
}
