<div class="manage-subscription">
    <div class="header">
        <h5>Manage subscription</h5>
    </div>
    <div class="body">
        <h5>Commited terms</h5>
        <p *ngIf="subscription.subscription_end_date">{{subscription.coach_package.subscription_term_in_months}}
                month subscription term | {{ subscription.subscription_start_date | dateFormat}} to {{ subscription.subscription_end_date | dateFormat}}</p>

        <p class="d-flex py-3 w-100 justify-content-between"><span class="left">{{subscription.coach_package.name}}</span>
            <span class="right">
                <span class="text-bold">${{subscription.coach_package.price}}</span>
                <span>Billed every month</span>
                <button class="button-true-green"
                        [popover]="cancelationPopover"
                         outsideClick="true">Early Cancelation</button>
            </span>
        </p>
        <hr/>
        <h5>Auto-renewal-terms</h5>
        <p *ngIf="subscription.auto_renewal_date">{{subscription.coach_package.subscription_term_in_months}} month
            subscription term | {{ subscription.subscription_end_date | dateFormat}} to {{ subscription.auto_renewal_date
            | dateFormat}}</p>
        <p *ngIf="!subscription.auto_renewal_date">No auto renewal</p>
        <p class="d-flex py-3 w-100 justify-content-between"><span class="left">{{subscription.coach_package.name}}</span>
            <span class="right">
                <span class="text-bold" >${{subscription.coach_package.price}}</span>
                <span>Billed {{subscription.coach_package.billing_period}} <span
                          *ngIf="subscription.coach_package.subscription_term_in_months">,
                        {{subscription.coach_package.subscription_term_in_months}}</span>
                        </span>
                <button class="button-true-green" (click)="!subscription.cancel_auto_renewal && cancelAutoRenewawl()">
                    {{ subscription.cancel_auto_renewal ? 'Canceled' : 'Cancel Auto-renewal' }}
                </button>
            </span>
        </p>
    </div>
    <div class="footer">
        <button class="button-gray" (click)="onClose()">Close</button>
    </div>
</div>

<ng-template #cancelationPopover>
    <div class="cancelation-wrapper">
            <p>You may choose to cancel your subscription early at your convenience provided that, we will not provide any refunds
                of prepaid fees or unuses Subscription Fees, and you will promptly pay all unpaid fees due through the end of the
                Subscription Term </p>
            <a class="button-true-green" (click)="onCancelation()">Ask for cancellation</a>
    </div>

</ng-template>
