<div class="page-list">
    <div class="page-list-wrapper">
        <div class="page-list-headline sticky">
            <h4 class="page-list-title">Ask for cancelation</h4>
        </div>
        <div class="cancelation">
            <resolution-center (showNewButton)="false"
                               fromCancelationCentre="true"
                               [scrollToForm]="true"
                               class="d-flex w-100"></resolution-center>
        </div>
           
    </div>

</div>