import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, ValidationErrors, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Component({
    selector: 'counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: CounterComponent, multi: true },
        { provide: NG_VALIDATORS, useExisting: CounterComponent, multi: true }
    ]
})
export class CounterComponent implements ControlValueAccessor, Validator {

    get value() { return this._value; }
    set value(v: number) { this._value = v * 1; }

    @Input()
    get required() { return this._required; }
    set required(value) { this._required = value !== false; }

    @Input() public name: string;
    @Input() public step = 1;
    @Input() public maxValue = 99999;
    @Input() public minValue = 0;
    @Input() public readonly = false;

    public touched = false;
    public isDisabled = false;
    public onValidatorChange: () => void = () => { };

    public onChange: (value: number) => void = (value: number) => { };

    public increaseValue() {
        if (this._value < this.maxValue && this._value + this.step <= this.maxValue) {
            this._value = this._value * 1 + this.step;
            this.onChange(this._value);
        }
    };

    public decreaseValue() {
        if (this._value > this.minValue && this._value - this.step >= this.minValue) {
            this._value = this._value * 1 - this.step;
            this.onChange(this._value);
        }
    };

    public onTouched: () => void = () => { };

    public writeValue(value: number): void {
        this._value = value;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public validate(control: AbstractControl): ValidationErrors {
        if (this.required && !this._value && this._value !== 0) {
            return { valueNotSet: 'Value is required' };
        }
        if (this._value && (this._value < this.minValue || this._value > this.maxValue)) {
            return { invalidValue: 'Value is not in acceptable range' };
        }
        return null;
    }

    public registerOnValidatorChange?(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    private _value: number;
    private _required = false;
}
