<div class="modal-header" *ngIf="!multiple">
    <button type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="cancel()">
        <img src="assets/icons/clear.png" />
    </button>
</div>

<div class="modal-header" *ngIf="multiple">
    <button type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="pickedOption('CANCELED')">
        <img src="assets/icons/clear.png" />
    </button>
</div>

<div class="modal-body">
    <p *ngIf="renderAsHtml" innerHtml="{{ title }}"></p>
    <p *ngIf="!renderAsHtml">{{ title }}</p>
</div>

<div class="modal-footer" *ngIf="!multiple">
    <button type="button"
            class="btn btn-default"
            (click)="cancel()">{{cancelBtnName}}</button>
    <button type="button"
            class="btn btn-default"
            (click)="confirm()">{{confirmBtnName}}</button>        
</div>

<div class="modal-footer modal-footer-multiple" *ngIf="multiple && buttonlist.length">
    <button type="button"
            class="btn btn-default cancel"
            (click)="pickedOption('CANCELED')">{{cancelBtnName}}</button>
    <button *ngFor="let pick of buttonlist"
            type="button"
            class="btn btn-default"
            (click)="pickedOption(pick.value)">{{pick.name}}</button>
</div>
