import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationsService } from '@shared/notifications.service';
import { Observable } from 'rxjs';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
    constructor(private notifications: NotificationsService){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const offline = navigator.onLine === false;
        if (offline){
            this.notifications.warning('Warning', 'Please check your internet connection');
            return;
        }
        return next.handle(req);
    }
}