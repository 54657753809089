<div class="page-list">
    <div class="page-list-wrapper">
        <div class="page-list-headline sticky">
            <h4 class="page-list-title">Subscription info</h4>
        </div>
        <div class="create-page-content-full"
             *ngIf="subscription">
            <div class="info-item">
                <div class="left">
                    <span>Subscription plan: </span>
                </div>
                <div class="right">
                    <span class="text-bold">
                        {{subscription.coach_package.name}}
                        <span class="warning" *ngIf="subscription.cancel_auto_renewal">
                            canceled
                        </span>
                    </span>
                    <span *ngIf="!subscription.cancel_auto_renewal">
                        Billed {{subscription.coach_package.billing_period}} <span
                              *ngIf="subscription.coach_package.subscription_term_in_months">,
                            {{subscription.coach_package.subscription_term_in_months}}</span>
                    </span>
                </div>
            </div>
            <div class="info-item"
                 *ngIf="subscription.coach_package.clients_limit">
                <div class="left">
                    <span>Clients limit: </span>
                </div>
                <div class="right">
                    <span class="text-bold">{{subscription.coach_package.clients_limit}}</span>
                </div>
            </div>
            <div class="info-item"
                 *ngIf="subscription.coach_package.subscription_term_in_months">
                <div class="left">
                    <span>Subscription term: </span>
                </div>
                <div class="right">
                    <span class="text-bold">{{subscription.coach_package.subscription_term_in_months}} month
                        subscription term | <span>{{subscription.subscription_start_date | dateFormat}} to
                            {{subscription.subscription_end_date| dateFormat}}</span>
                    </span>
                </div>
            </div>
            <div class="info-item"
                 *ngIf="subscription.next_billing_date">
                <div class="left">
                    <span>Next billing date: </span>
                </div>
                <div class="right">
                    <span class="text-bold">{{subscription.next_billing_date | dateFormat}}</span>
                </div>
            </div>
            <div class="info-item">
                <div class="left">
                    <span>Subscription price: </span>
                </div>
                <div class="right">
                    <span class="text-bold">${{subscription.coach_package.price}}</span>
                </div>
            </div>
            <div class="info-item">
                <div class="left">
                    <span>Payment method:</span>
                </div>
                <div class="right">
                    <span *ngFor="let card of cards; let i = index" class="card-info">
                        <span class="card-icon">{{card?.card_type}}</span>
                        <span class="">{{card?.card_type}} {{card?.card_number}}</span>
                        <span>Expires {{card?.expiration_date}}</span>
                        <a class="link mt-2"
                           *ngIf="i === cards.length - 1"
                           [routerLink]="['/profile/billing-info']">Change</a>
                    </span>
                </div>
            </div>
            <div class="info-item">
                <div class="left">
                    <span>Unpaid fees: </span>
                </div>
                <div class="right">
                    <span class="text-bold">${{ subscription.coach_package.subscription_term_in_months * +subscription.coach_package.price }}</span>
                </div>
            </div>
            <div class="info-item"
                 *ngIf="subscription.subscription_end_date">
                <div class="left">
                    <span>Automatically renews on: </span>
                </div>
                <div class="right">
                    <span *ngIf="!subscription.cancel_auto_renewal" class="cancel-auto-renewal">{{subscription.auto_renewal_date | dateFormat}}<a class="link"
                           (click)="cancelAutoRenewawl()">Cancel auto-renewal</a></span>
                    <span *ngIf="subscription.cancel_auto_renewal" class="text-bold">CANCELED</span>
                </div>
            </div>
            <div class="info-item">
                <button class="button-true-green ms-0"
                        (click)="manageSubscription()">Manage subscription</button>
            </div>
        </div>
        <div class="row-full-width"
             *ngIf="subscription">
            <div class="overflow-table">
                <table class="table-v4-xs">
                    <thead>
                        <tr>
                            <th scope="col">DATE ISSUED</th>
                            <th scope="col">DETAILS</th>
                            <th scope="col">AMOUNT</th>
                            <th scope="col">STATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let transaction of subscription.transactions">
                            <td scope="row"
                                class="page-list-td-40"
                                data-label="DATE ISSUED">{{transaction.timestamp | dateFormat}}</td>
                            <td class="page-list-10"
                                data-label="DETAILS">
                                <a class="link"
                                   (click)="download(transaction.transaction)">Download</a>
                            </td>
                            <td class="page-list-10"
                                data-label="AMOUNT">${{transaction.price}}</td>
                            <td class="page-list-10 status"
                                data-label="STATUS"
                                [class.failed]="transaction.status !== 'SUCCESS'">{{transaction.status}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
