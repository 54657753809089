import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthorizationService } from '@shared/authorization.service';
import { FitConfigProviderService } from '@shared/fit-config-provider.service';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class isSuspendedGuard implements CanActivate {
    constructor(private fitConfig: FitConfigProviderService, private router: Router, private auth: AuthorizationService) { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return new Promise((resolve, reject) => {
            this.auth.user.subscribe(user => {
                if (user) {
                    const root = this.fitConfig.isRoot;
                    const status = user.status_mobile_apps;
                    if (root) {
                        return resolve(true);
                    }
                    if (status === 'STATUS_SUSPENDED' || status === 'STATUS_CANCELED') {
                        resolve(false);
                        return this.router.navigateByUrl('/');
                    }
                    return resolve(true);
                }

            });
        });
    }

}
