import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FitConfigProviderService } from './fit-config-provider.service';
import { NotificationsService } from './notifications.service';
import { OrielItem } from '../oriel/oriel-item';

export interface ApiResponse<T> {
    success: boolean;
    errorCode: number;
    errorMessages: string[];
    data: T;
}

export interface FitList<T> {
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: null
    to: number;
    total: number;
    data: T[];
}

interface UploadStatus<T> {
    progress?: number;
    response?: T;
    error?: string;
}

function isApiResponse<T>(object: any): object is ApiResponse<T> {
    return 'success' in object && 'errorCode' in object && 'errorMessages' in object;
}

function mapAsyncError<T>(promise: Promise<ApiResponse<T>>): Promise<{ data: T, error: ApiResponse<any> }> {
    return promise.then(data => {
        if ((data as any).token_type) {
            return {
                data: data as any as T,
                error: undefined
            };
        }

        if (!data.success) {
            throw data;
        }
        return {
            data: data.data,
            error: undefined
        };
    }).catch((error: HttpErrorResponse | ApiResponse<T>) => {
        if (!isApiResponse(error)) {
            if (isApiResponse(error.error)) {
                return {
                    data: undefined,
                    error: error.error
                };
            }
            return {
                data: undefined,
                error: {
                    success: false,
                    errorCode: error.status,
                    errorMessages: [error.message],
                    data: null
                }
            };
        } else {
            return {
                data: undefined,
                error
            };
        }
    });
}

export function pinClear() {
    return localStorage.removeItem('pin_application');
}

export function pinGetter() {
    return localStorage.getItem('pin_application');
}

export function pinSetter(pin: string) {
    return localStorage.setItem('pin_application', pin);
}

@Injectable({ providedIn: 'root' })
export class FitApiClientService {
    constructor(private http: HttpClient, private fitConfig: FitConfigProviderService, private notifications: NotificationsService) { }

    public async get<T>(path: string, params?: { [param: string]: string }) {
        const headers = this.getPin();
        const url = await this.createUrl(path);
        const { data, error } = await mapAsyncError<T>(this.http.get<ApiResponse<T>>(url, { params, headers }).toPromise());
        if (error) {
            if (path.includes('recording') && this.ignoreRecordingError) {
                return;
            }
            this.notifications.error('Error', error.errorMessages.join('\r\n'));
        }
        return { data, error };
    }

    public async post<T>(path: string, body) {
        const headers = this.getPin();
        const url = await this.createUrl(path);
        const { data, error } = await mapAsyncError<T>(this.http.post<ApiResponse<T>>(url, body, { headers }).toPromise());
        if (error) {
            if (path.includes('recording') && this.ignoreRecordingError) {
                return;
            }
            this.notifications.error('Error', error.errorMessages.join('\r\n'));
        }
        return { data, error };
    }

    public async delete<T>(path: string, params?: { [param: string]: string }) {
        const headers = this.getPin();
        const url = await this.createUrl(path);
        const { data, error } = await mapAsyncError<T>(this.http.delete<ApiResponse<T>>(url, { params, headers }).toPromise());
        if (error) {
            if (path.includes('recording') && this.ignoreRecordingError) {
                return;
            }
            this.notifications.error('Error', error.errorMessages.join('\r\n'));
        }
        return { data, error };
    }

    public async put<T>(path: string, body: any, params?: { [param: string]: string }) {
        if(this.requestInProgress(path)){
            return {data: null, error: {
                errorMessages: ['Another tab is saving at the moment!']
            }};
        }
        const headers = this.getPin();
        const url = await this.createUrl(path);
        const { data, error } = await mapAsyncError<T>(this.http.put<ApiResponse<T>>(url, body, { params, headers }).toPromise());
        if (error) {
            if (path.includes('recording') && this.ignoreRecordingError) {
                this.markRequestComplete(path);
                return;
            }
            this.notifications.error('Error', error.errorMessages.join('\r\n'));
        }
        this.markRequestComplete(path);
        return { data, error };
    }

    public requestInProgress(path: string){
        if (!path.includes('program') && !path.includes('nutrition-program')){
                return false;
        }
        if (sessionStorage.getItem(path)) {
            this.notifications.error('Error', 'Another tab is saving at the moment!');
            return true;
        }
        sessionStorage.setItem(path, 'true');
        return false;
    }

    public markRequestComplete(path){
        if (sessionStorage.getItem(path)) {
            sessionStorage.removeItem(path)
        }
    }

    public async logout() {
        const url = await this.createUrl('/logout');
        sessionStorage.removeItem('profile-stepper-closed');
        sessionStorage.removeItem('show-wizard');
        return this.http.post(url, {});
    }

    public async uploadFileAttachment<T>(form: FormData, application_id: number) {
        let uploadURL;
        if(application_id){
            uploadURL = await this.createUrl(`/admin/attachments/${application_id}`);
        }else{
            uploadURL = await this.createUrl(`/admin/attachments/0`);
        }
        return this.http.post<any>(uploadURL, form, {
            reportProgress: true,
            observe: 'events',
            headers: this.getPin()
        }).pipe(
            map<HttpEvent<any>, UploadStatus<ApiResponse<T>>>((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return { progress: progress };
                    case HttpEventType.Response:
                        const body: ApiResponse<T> = event.body;
                        if (body.errorMessages && body.errorMessages.length > 0) {
                            this.notifications.error('Error', body.errorMessages.join('\r\n'));
                        }
                        return { response: event.body };
                    default:
                        const err = `Unhandled event: ${event.type}`;
                        return { error: err };
                }
            })
        );
    }

    public async uploadFileToGallery<T>(form: FormData) {
        const uploadURL = await this.createUrl('/admin/gallery');
        return this.http.post<any>(uploadURL, form, {
            reportProgress: true,
            observe: 'events',
            headers: this.getPin()
        }).pipe(
            map<HttpEvent<any>, UploadStatus<ApiResponse<T>>>((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return { progress: progress };
                    case HttpEventType.Response:
                        const body: ApiResponse<T> = event.body;
                        if (body.errorMessages && body.errorMessages.length > 0) {
                            this.notifications.error('Error', body.errorMessages.join('\r\n'));
                        }
                        return { response: event.body };
                    default:
                        const err = `Unhandled event: ${event.type}`;
                        return { error: err };
                }
            })
        );
    }

    public async uploadFileFromProfile<T>(form: FormData) {
        const uploadURL = await this.createUrl('/admin/gallery/application');
        return this.http.post<any>(uploadURL, form, {
            reportProgress: true,
            observe: 'events',
            headers: this.getPin()
        }).pipe(
            map<HttpEvent<any>, UploadStatus<ApiResponse<T>>>((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return { progress: progress };
                    case HttpEventType.Response:
                        const body: ApiResponse<T> = event.body;
                        if (body.errorMessages && body.errorMessages.length > 0) {
                            this.notifications.error('Error', body.errorMessages.join('\r\n'));
                        }
                        return { response: event.body };
                    default:
                        const err = `Unhandled event: ${event.type}`;
                        return { error: err };
                }
            })
        );
    }

    public async swapGalleryFile<T>(item: OrielItem, form: FormData) {
        const uploadURL = await this.createUrl(`/admin/gallery/${item.id}/swap`);
        return this.http.post<any>(uploadURL, form, {
            reportProgress: true,
            observe: 'events',
            headers: this.getPin()
        }).pipe(
            map<HttpEvent<any>, UploadStatus<ApiResponse<T>>>((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return { progress: progress };
                    case HttpEventType.Response:
                        const body: ApiResponse<T> = event.body;
                        if (body.errorMessages && body.errorMessages.length > 0) {
                            this.notifications.error('Error', body.errorMessages.join('\r\n'));
                        }
                        return { response: event.body };
                    default:
                        const err = `Unhandled event: ${event.type}`;
                        return { error: err };
                }
            })
        );
    }

    public download(url: string): Observable<Blob> {
        return this.http.get(url, {
            responseType: 'blob'
        })
    }

    private getPin() {
        return !!pinGetter() ? new HttpHeaders({ 'x-white-label-application-pin': pinGetter() }) : null;
    }

    private async createUrl(path: string) {
        const config = await this.fitConfig.getConfig();
        return config.apiUrl + path;
    }

    private ignoreRecordingError = true;
}
