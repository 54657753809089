import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'SecondsToTimestamp'
})

export class SecondsToTimestampPipe implements PipeTransform {
    transform(seconds: number, ...args: string[]): string {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        if (hours > 0) {
            return [
                hours.toString().padStart(2, '0'),
                minutes.toString().padStart(2, '0'),
                remainingSeconds.toString().padStart(2, '0')
            ].join(':');
        } else {
            return [
                minutes.toString().padStart(2, '0'),
                remainingSeconds.toString().padStart(2, '0')
            ].join(':');
        }
    }
}
