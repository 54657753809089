<ng-template #themeColor>
    <p class="popover-text">Theme color can be changed until we start developing your custom branded app. Once the app
        is developed and placed on App & Play Stores, there is one free change of the app's visuals.</p>
</ng-template>
<ng-template #accentColor>
    <p class="popover-text">Accent color can be changed until we start developing your custom branded app. Once the app
        is developed and placed on App & Play Stores, there is one free change of the app's visuals.</p>
</ng-template>
<ng-template #ourDesign>
    <p class="popover-text">Select this option if you want to get a color palette suggestion by our design team.</p>
</ng-template>
<ng-template #appLogo>
    <p class="popover-text">Use logo that represents your brand. Your logo will be used in your branded app and on the
        app stores.</p>
</ng-template>
<ng-template #appImages>
    <p class="popover-text">Use images that represent you and your brand. These images will be used in your branded app
        and on the app stores.</p>
</ng-template>
<div [ngClass]="{'page-list': !stepper}">
    <div [ngClass]="{'page-list-wrapper': !stepper}">
        <div class="page-list-headline sticky"
             *ngIf="!stepper">
            <h4 class="page-list-title">App visuals</h4>
        </div>
        <form #appVisualsForm="ngForm"
              class="app-visuals-form"
              (submit)="appVisualsForm.valid && onFormSubmit()">
            <div class="app-visuals flex-column">
                <div class="d-flex w-100 pb-0 pe-3">
                    <div class="form-row-input">
                        <p class="input-label">Choose your theme color <span class="input-label-tooltip"
                                  [popover]="themeColor"
                                  placement="bottom"
                                  triggers="hover"></span>
                        </p>
                        <ng-select name="selectedTheme"
                                   class="select-input"
                                   required
                                   placeholder="Select theme color"
                                   bindLabel="name"
                                   [disabled]="appVisuals.is_locked || appVisuals.our_design"
                                   [searchable]="false"
                                   [clearable]="false"
                                   [items]="themes"
                                   [multiple]="false"
                                   [(ngModel)]="appVisuals.theme_color"
                                   (ngModelChange)="changedTheme()"
                                   [ngClass]="{'form-submitted': appVisualsForm.submitted || submitted }">
                            <ng-template ng-label-tmp
                                         let-item="item">
                                <div class="d-flex align-items-center">
                                    <span class="color-box"
                                          [ngClass]="{'select-color': item.value === 'select-theme-color', 'bordered': item.color === '#FFFFFF'}"
                                          [ngStyle]="{'background': item.color}"></span>
                                    <p>{{item.name}}</p>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp
                                         let-item="item"
                                         let-index="index">
                                <div class="ng-select-value-box">
                                    <span class="color-box"
                                          [ngClass]="{'select-color': item.value === 'select-theme-color', 'bordered': item.color === '#FFFFFF'}"
                                          [ngStyle]="{'background': item.color}"></span>
                                    <p>{{item.name}}</p>
                                </div>
                            </ng-template>
                        </ng-select>
                        <p class="descriptor fix-margin-top">App's background color</p>
                    </div>
                    <div class="form-row-input ps-3">
                        <p class="input-label">Choose your accent color <span class="input-label-tooltip"
                                  [popover]="accentColor"
                                  placement="bottom"
                                  triggers="hover"></span>
                        </p>
                        <ng-select name="selectedAccentColor"
                                   class="select-input app-visuals-dropdown"
                                   required
                                   placeholder="Select accent color"
                                   bindLabel="name"
                                   [searchable]="false"
                                   [clearable]="false"
                                   [disabled]="appVisuals.is_locked || appVisuals.our_design"
                                   [items]="accentColors"
                                   [multiple]="false"
                                   [(ngModel)]="appVisuals.accent_color"
                                   (ngModelChange)="accentColorChanged()"
                                   [ngClass]="{'form-submitted': appVisualsForm.submitted || submitted }">
                            <ng-template ng-label-tmp
                                         let-item="item">
                                <div class="d-flex align-items-center">
                                    <span class="color-box"
                                          [ngClass]="{'select-color': item.value === 'select-accent-color'}"
                                          [ngStyle]="{'background': item.color}"></span>
                                    <p>{{item.name}}</p>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp
                                         let-item="item"
                                         let-index="index">
                                <div class="ng-select-value-box">
                                    <span class="color-box"
                                          [ngClass]="{'select-color': item.value === 'select-accent-color'}"
                                          [ngStyle]="{'background': item.color}"></span>
                                    <p>{{item.name}}</p>
                                </div>
                            </ng-template>
                        </ng-select>
                        <p class="descriptor fix-margin-top">Used for buttons and highlights</p>
                    </div>
                </div>
                <div class="d-flex w-100">
                    <div class="form-row pb-0">
                        <div class="form-row-input check mt-5">
                            <input name="weChooseColorPalette"
                                   class="checkbox input-checkbox"
                                   type="checkbox"
                                   [disabled]="appVisuals.is_locked"
                                   [class.true]="appVisuals.our_design"
                                   [class.false]="!appVisuals.our_design"
                                   [(ngModel)]="appVisuals.our_design"
                                   (ngModelChange)="weChooseChange($event)" />
                            <span class="checkbox-label">Let us choose your color palette <span
                                      class="input-label-tooltip"
                                      [popover]="ourDesign"
                                      placement="bottom"
                                      triggers="hover"></span></span>
                        </div>
                    </div>
                </div>
                <div class="d-flex w-100 images-and-preview-box">
                    <div class="logo-wrap">
                        <div class="form-row logo">
                            <div class="logo-upload-wrapper">
                                <p class="input-label">Upload your logo <span class="input-label-tooltip"
                                          [popover]="appLogo"
                                          placement="bottom"
                                          triggers="hover"></span>
                                </p>
                                <file-upload [id]="'logo'"
                                             name="logo"
                                             [required]="true"
                                             [type]="'image'"
                                             [manualClass]="'logo'"
                                             [readonly]="appVisuals.is_locked"
                                             [isAvatar]="true"
                                             [ngClass]="{'form-submitted': appVisualsForm.submitted || submitted }"
                                             *ngIf="!appVisuals.logo"
                                             [(ngModel)]="appVisuals.logo"
                                             (uploadedFile)="uploadedLogo($event)"></file-upload>
                                <gallery-item *ngIf="appVisuals.logo"
                                              name="uploadedLogo"
                                              [type]="'image'"
                                              required
                                              [disabled]="appVisuals.is_locked"
                                              [manualClass]="'app-visual-item'"
                                              [(ngModel)]="appVisuals.logo"
                                              (ngModelChange)="uploadedLogo($event)"
                                              [readonly]="appVisuals.is_locked"
                                              [ngClass]="{ 'form-submitted': appVisualsForm.submitted || submitted }">
                                </gallery-item>
                                <p class="descriptor w-260">We recommend using a PNG image with a transparent background
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="images"
                         stepperFix>
                        <p class="input-label required">Upload up to 10 images <span class="input-label-tooltip"
                                  [popover]="appImages"
                                  placement="bottom"
                                  triggers="hover"></span>
                        </p>
                        <div class="images-wrapper"
                             [ngClass]="appVisuals.gallery.length ? 'small-img' : ''">
                            <file-upload [type]="'image'"
                                         [id]="'images'"
                                         name="images"
                                         [multiple]='true'
                                         [readonly]="appVisuals.is_locked"
                                         [required]="appVisuals.gallery.length === 0"
                                         [disable]="appVisuals.is_locked"
                                         *ngIf="appVisuals.gallery.length < 10"
                                         [ngClass]="{'form-submitted': appVisualsForm.submitted || submitted }"
                                         [manualClass]="!appVisuals.gallery.length ? 'app-visuals' : 'small-images'"
                                         [(ngModel)]="appVisuals.gallery[appVisuals.gallery.length]"
                                         (uploadedFile)="onUploadedFile($event)"></file-upload>
                            <div class="item-{{i + 1}} small-image-item"
                                 *ngFor="let item of appVisuals.gallery; let i = index; trackBy: trackByFn">
                                <gallery-item name="images{{i}}"
                                              [type]="'image'"
                                              [readonly]="appVisuals.is_locked"
                                              [manualClass]="appVisuals.gallery.length ? 'small-gallery-item' : ''"
                                              [(ngModel)]="appVisuals.gallery[i]"
                                              (ngModelChange)="onChangeImageModel()"
                                              [ngClass]="{ 'form-submitted': appVisualsForm.submitted || submitted }">
                                </gallery-item>
                            </div>
                        </div>
                        <p class="descriptor w-260">We recommend using images with larger height than width</p>
                    </div>
                    <div class="preview-app-wrap">
                        <div class="preview-app">
                            <h5>Preview branded app</h5>
                            <p>Choose a color palette and upload your logo and images to see how your branded app would
                                look like.</p>
                            <button type="button"
                                    [disabled]="!appVisuals.accent_color"
                                    (click)="showModal()"
                                    class="button-gray"> Preview app <svg xmlns="http://www.w3.org/2000/svg"
                                     width="24"
                                     height="24"
                                     viewBox="0 0 24 24">
                                    <defs>
                                        <style>
                                            .a {
                                                fill: none;
                                            }

                                            .b {
                                                fill: #293254;
                                            }

                                        </style>
                                    </defs>
                                    <path class="a"
                                          d="M0,0H24V24H0Z" />
                                    <path class="b"
                                          d="M12,4.5A11.827,11.827,0,0,0,1,12a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4.5ZM12,17a5,5,0,1,1,5-5A5,5,0,0,1,12,17Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,12,9Z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <button *ngIf="!stepper"
                    [disabled]="appVisuals.is_locked"
                    class="button-true-green"
                    type="submit">Save</button>
        </form>
    </div>
</div>
