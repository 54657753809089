<div class="lets-begin">
    <div class="lets-begin-lf">
        <div class="lets-begin-lf-box">
            <h5>Trusted by 100+ personal trainers, coaches and fitness instructors.</h5>
            <div class="trainers-box">
                <img [src]="'assets/images/image_avatar_moali.png'" />
                <img [src]="'assets/images/image_avatar_lauren.png'" />
                <img [src]="'assets/images/image_avatar_brute.png'" />
                <img [src]="'assets/images/image_avatar_brittany.png'" />
                <img [src]="'assets/images/image_avatar_betty.png'" />
            </div>
            <img [src]="'assets/images/image_testimonial_moali.png'" />
        </div>
    </div>
    <div class="lets-begin-rh">
        <div class="lets-begin-rh-box">
            <img [src]="'assets/icons/logo_lean_on_black.svg'" />
            <h1 *ngIf="!includedCustomApp">Get your <b>branded web app</b> created in few minutes</h1>
            <h1 *ngIf="includedCustomApp">Reach your full potential with <b>personalized fitness app</b></h1>
                <a [routerLink]="'/profile/onboarding-wizard/theme-color'"
                   class="btn button-true-green">Let's begin</a>

        </div>
    </div>
</div>
