import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GalleryItem } from '@app/gallery/gallery-item';
import { NotificationsService } from '@shared/notifications.service';
import { Attachment, MessageRequest, ResolutionCenterService, ThreadRequest } from '../resolution-center.service';

@Component({
    selector: 'cancellation-center',
    templateUrl: './cancellation-center.component.html',
    styleUrls: ['./cancellation-center.component.scss']
})
export class CancellationCenterComponent implements OnInit {

    constructor(private notifications: NotificationsService, private resolutionCenterService: ResolutionCenterService) { }

    @ViewChild('messageForm') public messageForm: NgForm;

    get uploadCounter() { return this._uploadedCounter }

    get newThread() { return this._newThread; }
    set newThread(value) { this._newThread = value; }


    public cancelationReasons = ['Illiquidity', 'Medical reason', 'Personal reason', 'Other'];

    public selectedCancelationReason = 'Illiquidity';
    public reasonSelected = false;
    public disableThreadForm = false;
    public fileList = [];
    public uploadedFiles: GalleryItem[] = [];
    public progress = 0;
    public sending = false;
    public showCreateNew = false;
    public messageText = '';

    ngOnInit(): void {
    }

    public onAttachedFiles(eventFiles) {
        for (let i = 0; i < eventFiles.length; i++) {
            let haveAllready = this.fileList.some(item => item.name === eventFiles[i].name);
            if (!haveAllready) {
                this.fileList.push(eventFiles[i])
            }
        }
        this.startUploading();
    }

    public startUploading() {
        this._uploadedCounter = 0;
        if (!this.fileList.length) {
            return;
        }
        let amountToUpload = this.fileList.length;
        const upload = async () => {
            if (amountToUpload > this._uploadedCounter) {

                const data = await this.uploadAttachment(this.fileList[this._uploadedCounter])
                if (data) {
                    data.subscribe(
                        res => {
                            if (res.progress) {
                                this.progress = res.progress;
                            } else if (res.response) {
                                this.progress = 0;
                                if (res.response !== undefined) {
                                    this.uploadedFiles.push(res.response.data);
                                }
                                this._uploadedCounter++;
                            } else {
                                this.progress = 0;
                            }
                        },
                        error => {
                            this.progress = 0;
                            this._uploadedCounter++;
                            const err = error.status === 502 ? error.statusText : error.error.errorMessages.join('\r\n');
                            this.notifications.error('Error', err);
                        },
                        () => {
                            upload();
                        }
                    )
                }
            } else {
                this.fileList = [];
                this._uploadedCounter = 0;
            }

        }
        upload();
    }

    public async onDeleteAttachment(file: Attachment) {
        const { data, error } = await this.resolutionCenterService.deleteAttachment(file, null, false);
        if (!error) {
            this.notifications.success('Success', 'Attachment deleted');
        }
    }

    public uploadAttachment(file) {
        return this.resolutionCenterService.uploadAttachment(file.name, file, null)
    }

    public async sendCancellationMessage() {
        if (this.sending) {
            return;
        }
        console.log('happens')
        const subject = this.selectedCancelationReason === 'Other' ? this._newThread.subject : this.selectedCancelationReason;
        this.sending = true;
        const thread = {
            subject: 'Cancellation: ' + subject,
            message: this.messageText.trim(),
            attachment_ids: this.uploadedFiles.map(file => file.id)
        };

        if (!thread.message && !thread.attachment_ids.length) {
            return;
        }

        if (this.disableThreadForm) {
            return;
        }

        this.disableThreadForm = true;

        const { data, error } = await this.resolutionCenterService.createNewThread(thread, this.uploadedFiles);
        if (!error) {
            if (this.uploadedFiles.length) {
                setTimeout(() => {
                    this.disableThreadForm = false;
                }, 500);
                this.notifications.success('Success', 'Cancellation request has been sent')
            } else {
                setTimeout(() => {
                    this.disableThreadForm = false;
                }, 500);
            }
        }
    }

    public onRemoveAttachment(attachment: GalleryItem) {
        this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== attachment.id);
    }


    private _uploadedCounter = 0;
    private _newThread: ThreadRequest = {
        application_id: null,
        subject: '',
        message: '',
        attachment_ids: []
    };


}
