import { ElementRef } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
    selector: '[appLogoutIphonePositionFix]'
})
export class LogoutPositionDirective {

    constructor(el: ElementRef) {
        if (this.checkPlatform()) {
            el.nativeElement.classList.add('ios-fix-logout-position');
        }
    }

    private checkPlatform() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform);
    }

}
