<ng-template #galleryBoxInfoTemplate
             let-item="item">
    <div class="gallery-body-box-info">
        <input #galleryItemName
               [ngClass]="{'gallery-item-editing': isEditing(item)}"
               type="text"
               class="gallery-body-box-info-name"
               (focus)="startEditing(item); galleryItemName.select()"
               (blur)="stopEditing(item)"
               [(ngModel)]="item.name" />

        <button *ngIf="!isEditing(item)"
                type="button"
                class="button-edit"
                (click)="startEditing(item); galleryItemName.select()"></button>
    </div>
</ng-template>

<ng-template #galleryBoxImageVideoTemplate
             let-item="item">
    <div class="gallery-body-box-img video"
         *ngIf="item.type === 'video'">
        <div class="gallery-body-box-img-overlayer">

            <div class="buttons" [ngClass]="{'streaming-buttons': item.mime_type === 'application/x-mpegURL'}">
                <button type="button"
                        title="Select"
                        class="button-gallery"
                        (click)="select(item)">
                    <img src="assets/icons/upload.png" /></button>
                <button *ngIf="item.mime_type !== 'application/x-mpegURL'"
                        type="button"
                        title="Crop video"
                        class="button-gallery button-crop"
                        (click)="crop(item)">
                    <img src="assets/icons/ic_crop.svg" /></button>
                <button *ngIf="item.mime_type !== 'application/x-mpegURL'"
                        type="button"
                        title="Fix video"
                        class="button-gallery"
                        (click)="onFixVideo(item.id)">
                        <img src="assets/icons/ic_fix.png" /></button>
            </div>
            <div class="buttons">
                <button *ngIf="!item.playMode"
                        type="button"
                        title="Play video"
                        class="button-gallery"
                        (click)="switchPlayMode(item)">
                    <img src="assets/icons/play.png" />
                </button>
                <button *ngIf="item.playMode"
                        type="button"
                        title="Pause video"
                        class="button-gallery"
                        (click)="switchPlayMode(item)">
                    <img src="assets/icons/pause.png" />
                </button>
                <button type="button"
                        class="button-gallery"
                        title="Delete video"
                        (click)="delete(item.id)">
                    <img src="assets/icons/trash-alt.png" />
                </button>
            </div>


        </div>
        <div *ngIf="!item.playMode"
             class="gallery-body-box-img"
             [ngStyle]="{'background-image': 'url(' + item.thumbnail_url + ')'}">
        </div>
        <video #player
               autoplay
               *ngIf="item.playMode && item.mime_type !== 'application/x-mpegURL'"
               class="gallery-body-box-img"
               [ngStyle]="{'background-image': 'url(' + item.url + ')'}">
            <source [src]="item.url"
                    [type]="item.mime_type">
            Your browser does not support the video tag.
        </video>
        <div class="video-js-wrapper" *ngIf="item.playMode && item.mime_type === 'application/x-mpegURL'">
            <video-js
            autoplay="true"
            [sources]="[{src: item.url, type: item.mime_type}]"
            class="gallery-body-box-img"
            [ngStyle]="{'background-image': 'url(' + item.url + ')'}"></video-js>
        </div>

    </div>
</ng-template>
<ng-template #galleryBoxFileTemplate
             let-item="item">
    <div class="gallery-body-box-img file" attr.data-mime_type="{{getDocumentType(item.mime_type)}}">
        <div class="gallery-body-box-img-overlayer">
            <div class="buttons">
                <button type="button"
                        title="Select"
                        class="button-gallery me-2"
                        (click)="select(item)">
                    <img src="assets/icons/upload.png" /></button>
            </div>
            <div class="buttons">
                <button type="button"
                        class="button-gallery"
                        title="Delete video"
                        (click)="delete(item.id)">
                    <img src="assets/icons/trash-alt.png" />
                </button>
            </div>
        </div>
    </div>
</ng-template>

<div class="gallery-body-wrapper">
    <div class="search-wrapper">
        <input id="search"
               name="search"
               class="text-input"
               type="text"
               [(ngModel)]="searchQuery"
               (ngModelChange)="searchQueryStream.next($event)"
               placeholder="Search" />
        <label for="search"></label>
        <button class="button-clear"
                [ngClass]="{'active': searchQuery}"
                (click)="clearSearch()"></button>
                <list-sort [currentSort]="sortBy"
                           [fromGallery]="true"
                           [buttonsOnly]="true"
                           (onSort)="onSort($event)"></list-sort>
    </div>
    <p *ngIf="progress > 0"
       class="gallery-body-progress-label">{{progress}}% done <span>{{ currentUpload }}/{{ uploadAmount }}</span></p>
    <progressbar *ngIf="progress > 0"
                 [value]="progress"
                 class="gallery-body-progress-bar"
                 type="warning"
                 [striped]="true"></progressbar>

    <div class="gallery-body-imgs">
        <form name="fileUpload"
              #fileUploadForm="ngForm"
              (submit)="upload(files)">
            <label for="file-upload"
                   class="gallery-body-imgs-add">
                <img class="gallery-body-imgs-add-plus"
                     src="assets/icons/plus.png" />
                <p class="gallery-body-imgs-add-text">Browse computer</p>
                <p class="gallery-body-imgs-add-size">Max size ({{maxUploadSize}})</p>
            </label>
            <input type="file"
                   id="file-upload"
                   style="display: none"
                   name="file"
                   multiple
                   required
                   [(ngModel)]="fakePath"
                   (change)="onFileChange($event)" />
        </form>
        <div *ngFor="let item of list"
             class="gallery-item-box"
             [ngClass]="{'selected': (selected && item.id === selected.id)}">
            <div class="gallery-body-box">
                <div *ngIf="item.type === 'image'"
                     class="gallery-body-box-img"
                     [ngStyle]="{'background-image': 'url(' + item.thumbnail_url + ')'}">
                    <div class="gallery-body-box-img-overlayer">
                        <button type="button"
                                class="button-gallery"
                                (click)="select(item)">
                            <img src="assets/icons/upload.png" /></button>
                        <button type="button"
                                class="button-gallery"
                                (click)="delete(item.id)">
                            <img src="assets/icons/trash-alt.png" />
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="item.type === 'video'">
                    <ng-container *ngTemplateOutlet="galleryBoxImageVideoTemplate; context: { item: item }">
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="item.type === 'document'">
                    <ng-container *ngTemplateOutlet="galleryBoxFileTemplate; context: { item: item }">
                    </ng-container>
                </ng-container>

                <ng-container *ngTemplateOutlet="galleryBoxInfoTemplate; context: { item: item }">
                </ng-container>
            </div>
        </div>
    </div>

    <pagination *ngIf="hasPagination"
                [boundaryLinks]="true"
                [totalItems]="totalItems"
                [itemsPerPage]="itemsPerPage"
                (pageChanged)="onPageChanged($event)"
                [maxSize]="10"
                previousText="&lsaquo;"
                class="gallery-body-pagination"
                nextText="&rsaquo;"
                firstText="&laquo;"
                lastText="&raquo;">
    </pagination>
</div>
