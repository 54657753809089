import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@shared/authorization.service';
import { AppVisualsV2, ProfileService } from '../../profile.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-theme-color',
    templateUrl: './theme-color.component.html',
    styleUrls: ['./theme-color.component.scss']
})
export class ThemeColorComponent implements OnInit {

    constructor(
        private profileService: ProfileService,
        private auth: AuthorizationService,
        private route: ActivatedRoute,
        private router: Router) { }

    get user(){ return this.auth.user.getValue()}

    public selected: 'light' | 'dark' = null;

    public includedCustomApp = false;
    public fromDashboard = false;

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (!params.has('dashboard')) {
                this.fromDashboard = false;
                return;
            }
            this.fromDashboard = true;
        });

        this.profileService.pwaAssets.subscribe(res => {
            this.selected = res.theme_color;
            this._pwaAssets = res;
        });
        this.auth.user.subscribe((user) => {
            if (user) {
                this.includedCustomApp = user.coach_package?.include_custom_application;
            }
        });
    }

    public selectThemeColor(theme: 'light' | 'dark') {
        this.selected = theme;
        this.profileService.pwaAssets.next({
            home_background: this._pwaAssets.home_background,
            onboarding_1: this._pwaAssets.onboarding_1,
            onboarding_2: this._pwaAssets.onboarding_2,
            onboarding_3: this._pwaAssets.onboarding_3,
            primary_color: this._pwaAssets.primary_color,
            splash_screen: this._pwaAssets.splash_screen,
            theme_color: theme,
            logo: this._pwaAssets.logo
        });

    }

    public async saveChanges() {
        const { data, error } = await this.profileService.setPwaAssets(this._pwaAssets);
        if (!error) {
            await this.generate();
        }

    }

    private async generate() {
        const { data, error } = await this.profileService.generatePwaApp();
        if (!error) {
            this.profileService.appVisualsCompleted.next(true);
            this.router.navigate(['/']);
        } else {
            this.profileService.appVisualsCompleted.next(false);
        }
    }

    private _pwaAssets: AppVisualsV2 = null;

}
