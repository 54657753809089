import { Component, Input, ViewChild, ContentChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, ValidationErrors, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { NotificationsService } from '@shared/notifications.service';

import { GalleryItem, GalleryService } from '../gallery.service';

@Component({
    selector: 'gallery-item',
    templateUrl: './gallery-item.component.html',
    styleUrls: ['./gallery-item.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: GalleryItemComponent, multi: true },
        { provide: NG_VALIDATORS, useExisting: GalleryItemComponent, multi: true }
    ]
})
export class GalleryItemComponent implements ControlValueAccessor, Validator {
    constructor(
        private gallery: GalleryService, 
        private elementRef: ElementRef<HTMLDivElement>,
        private notification: NotificationsService) { }

    get item() { return this._item; }

    @Input()
    get required() { return this._required; }
    set required(value) { this._required = value !== false; }

    @Input()
    get readonly() { return this._readonly; }
    set readonly(value) { this._readonly = value !== false; }

    @Input() manualClass = '';

    @Input() public type: 'all' | 'image' | 'video' | 'document' = 'all';

    @Input() public source: 'gallery' | 'avatar' = 'gallery';

    @Output() public itemRemoved = new EventEmitter<any>(true);

    @Output() public itemAdded = new EventEmitter<any>(true);



    public touched = false;

    public isDisabled = false;

    public onValidatorChange: () => void = () => { };

    public onChange: (value: GalleryItem) => void = (value: GalleryItem) => { };

    public onTouched: () => void = () => { };

    public writeValue(value: GalleryItem): void {
        if (this.videoPlayer) {
            setTimeout(() => { this.videoPlayer.load(); });
        }
        this._item = value;
        this.itemAdded.emit(true);
    }

    public switchPlayMode(item: GalleryItem) {
        item.playMode = !item.playMode;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public validate(control: AbstractControl): ValidationErrors {
        if (this.required && !this._item) {
            return { galleryItemNotSelected: 'Gallery item is required' };
        }
        return null;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    public async openGallery() {
        this.onTouched();
        this._item = await this.gallery.selectFromGallery(this.type, this.source);
        this.onChange(this._item);
        this.onValidatorChange();
    }

    public onRemoveClick() {
        this._item = null;
        this.onChange(this._item);
        this.itemRemoved.emit();
    }

    public async onFixVideo(id: number){
        const { data, error } = await this.gallery.fixVideo(id);
        if (!error){
           this.notification.success('Success', 'Video fixed');
        }
    }

    private get videoPlayer(): HTMLVideoElement {
        return this.elementRef.nativeElement.querySelector('video');
    }

    private _item: GalleryItem;
    private _required = false;
    private _readonly = false;
}
