import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { FitConfigProviderService } from '@shared/fit-config-provider.service';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class ProfileRootGuard implements CanActivate {
    constructor(private fitConfig: FitConfigProviderService, private router: Router) { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const root = this.fitConfig.isRoot;
        if (root) {
            return this.router.navigateByUrl('/');
        }

        return true;
    }

}
