import { Injectable } from '@angular/core';
import { FitApiClientService, FitList } from '@shared/fit-api-client.service';
import { ExerciseLibrary } from '../types';

export interface ExerciseLibraryResponse {
    id: number;
    name: string;
}

@Injectable({ providedIn: 'root' })
export class ExerciseLibraryService {
    constructor(private fitApi: FitApiClientService) { }

    public search(term: string, page: number) {
        return this.fitApi.get<FitList<ExerciseLibraryResponse>>('/admin/exercise-library', {
            search: term,
            page: page.toString()
        });
    }

    public get() {
        return this.fitApi.get<any>('/admin/exercise-library');
    }

    public getById(id: number) {
        return this.fitApi.get<any>('/admin/exercise-library/' + id);
    }

    public create(exerciseLibrary: ExerciseLibrary) {
        return this.fitApi.put<ExerciseLibrary>('/admin/exercise-library/0', this.mapToRequest(exerciseLibrary));
    }

    public update(exerciseLibrary: ExerciseLibrary) {
        return this.fitApi.put<ExerciseLibrary>('/admin/exercise-library/' + exerciseLibrary.id, this.mapToRequest(exerciseLibrary));
    }

    public delete(id: number) {
        return this.fitApi.delete<ExerciseLibrary>('/admin/exercise-library/' + id);
    }

    public mapToRequest(library: ExerciseLibrary) {
        return library;
    }
}
