import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProfileStepperComponent } from '../profile-stepper/profile-stepper.component';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
    constructor(private modalService: BsModalService) { }

    ngOnInit() {
        const initialState = {
            step: 1
        };

        this.modalService.show(ProfileStepperComponent, { initialState, backdrop: false, ignoreBackdropClick: true, class: 'profile-stepper-modal' });
    }

    private bsModalRef: BsModalRef;
}
