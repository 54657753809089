import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-personalized-app-modal',
    templateUrl: './personalized-app-modal.component.html',
    styleUrls: ['./personalized-app-modal.component.scss']
})
export class PersonalizedAppModalComponent implements OnInit {

    constructor(private router: Router) { }

    public logo: string;
    public brandName: string;

    public hideModal = false;
    public step = 1;

    ngOnInit(): void {
    }

    public letsBegin() {
        this.hideModal = true;
    }

    public complete() {
        this.router.navigate(['/']);
    }

}
