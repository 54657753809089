<ng-template #reactionForm>
    <div class="reaction-template">
        <div class="reaction-template-popover">
            <span *ngFor="let reaction of reactions"
                  class="reaction-content"
                  (click)="chooseReaction(pop, reaction)">
                  {{ reaction }}
            </span>
        </div>
    </div>
</ng-template>

<div triggers="click"
     placement="left"
     #pop="bs-popover"
     [popover]="reactionForm"
     (click)="onOpen()">
    <button type="button" class="button-add-reaction"></button>
</div>
