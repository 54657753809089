<coach-center *ngIf="fromCoachCentre"></coach-center>

<cancellation-center *ngIf="fromCancelationCentre"></cancellation-center>

<root-center *ngIf="fromRootCentre"
              [appActivated]="appActivated"
              [appId]="appId"></root-center>

<client-center *ngIf="fromClientCentre"></client-center>

<modal-center *ngIf="fromModalCentre" 
                [getModalUserId]="getModalUserId"
                [customerName]="customerName"
                [customerLastname]="customerLastname"></modal-center>
