import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'monthsAndDays'
})

export class MonthsAndDaysPipe implements PipeTransform {
    transform(hours: number, ...args: string[]): string {
        const daysInMonth = 30; // Assuming 30 days in a month for simplicity
        const totalDays = hours / 24;
        const months = Math.floor(totalDays / daysInMonth);
        const remainingDays = Math.round(totalDays % daysInMonth);

        //return { months: months, days: remainingDays };
        
        let result = '';
        
        if (months > 0) {
            result+= months + ' month';
            if (months > 1) {
                result+= 's';
            }
            result+= ' and '; 
        }
        
        result+= remainingDays + ' day';
        if (remainingDays > 1) {
            result+= 's';
        }

        return result;
    }
}
