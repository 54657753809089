import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GravatarPipe } from './gravatar.pipe';

@NgModule({
    declarations: [GravatarPipe],
    imports: [
        CommonModule
    ],
    exports: [GravatarPipe]
})
export class PipesModule { }
