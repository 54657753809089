import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgForm, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

import { NotificationsService } from '@shared/notifications.service';

import { ProfileService } from '../profile.service';
import { AppInfo, AppInfoResponse } from '../types';
import { Router, ActivatedRoute } from '@angular/router';

interface FreeTrialItem {
    value: string;
    label: string;
}

const NONE: FreeTrialItem = {
    value: 'NONE',
    label: 'None'
};

const THREE_DAYS: FreeTrialItem = {
    value: 'THREE_DAYS',
    label: '3 days'
};

const ONE_WEEK: FreeTrialItem = {
    value: 'ONE_WEEK',
    label: '1 week'
};

const TWO_WEEKS: FreeTrialItem = {
    value: 'TWO_WEEKS',
    label: '2 weeks'
};

const ONE_MONTH: FreeTrialItem = {
    value: 'ONE_MONTH',
    label: '1 mont'
};

const TWO_MONTHS: FreeTrialItem = {
    value: 'TWO_MONTHS',
    label: '2 months'
};

const THREE_MONTHS: FreeTrialItem = {
    value: 'THREE_MONTHS',
    label: '3 months'
};

const SIX_MONTHS: FreeTrialItem = {
    value: 'SIX_MONTHS',
    label: '6 months'
};

@Component({
    selector: 'profile-app-info',
    templateUrl: './app-info.component.html',
    styleUrls: ['./app-info.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: AppInfoComponent, multi: true },
        { provide: NG_VALIDATORS, useExisting: AppInfoComponent, multi: true }
    ]
})
export class AppInfoComponent implements ControlValueAccessor, Validator, OnInit {

    constructor(
        private profileService: ProfileService,
        private notifications: NotificationsService,
        private router: Router,
        private route: ActivatedRoute) { }

    get appInfo() { return this._appInfo; }
    set appInfo(value) { this._appInfo = value; }

    @Input() stepper = false;
    @Input() submitted = false;

    @Input() removeBackground = false;

    @ViewChild('appInfoForm') public appInfoForm: NgForm;

    public isDisabled = false;
    public isSecondStep = false;
    public fromDashboard = false;

    public monthlyFree: FreeTrialItem[] = [NONE, THREE_DAYS, ONE_WEEK, TWO_WEEKS];
    public yearlyFree: FreeTrialItem[] = [NONE, THREE_DAYS, ONE_WEEK, TWO_WEEKS, ONE_MONTH, TWO_MONTHS, THREE_MONTHS, SIX_MONTHS];
    public items: Array<{ display: string; value: string }> = [];

    public ngOnInit() {
        this.route.paramMap.subscribe(params => {
            if (!params.has('dashboard')) {
                this.fromDashboard = false;
                return;
            }
            this.fromDashboard = true;
        });
        this.load();
    }

    public onValidatorChange: () => void = () => { };
    public onTouched: () => void = () => { };
    public onChange: (value: AppInfo) => void = (value: AppInfo) => { };

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    public validate(control: AbstractControl): ValidationErrors {
        if (!this.appInfo) {
            return { appInfo: 'App Info is required' };
        } else if (!this.appInfo.shortDescription) {
            return { shortDescription: 'Short Description is required' };
        } else if (!this.appInfo.name) {
            return { name: 'name is required' };
        } else if (!this.appInfo.packageOptions) {
            return { packageOptions: 'Package options is required' };
        } else if (!this.appInfo.fullDescription) {
            return { fullDescription: 'Full description is required' };
        } else if (!this.appInfo.keywords) {
            return { keywords: 'Keywords is required' };
        }
        return null;
    }

    public writeValue(obj: AppInfo): void {
        if (obj) {
            this.appInfo = obj;
            this.updateAppInfo();
        } else {
            this._appInfo = {
                name: '',
                fullDescription: '',
                keywords: '',
                packageOptions: null,
                shortDescription: '',
                isLocked: false,
                subtitle: '',
            };
        }
    }

    public updateAppInfo() {
        this.onTouched();
        this.onChange(this._appInfo);
    }

    public trackByFn(i: number) {
        return i;
    }

    public nextStepLocked() {
        if (!this.isSecondStep) {
            this.isSecondStep = true;
        } else {
            if (this.fromDashboard) {
                this.router.navigate(['/']);
            } else {
                this.router.navigate(['/profile/onboarding-wizard/owner-info']);
            }
        }
    }

    public nextStep(form: NgForm) {
        if (!form.valid) {
            return;
        }
        if (!this.isSecondStep) {
            this.isSecondStep = true;
        } else {
            this.update();
        }
    }

    public async update() {
        this.isDisabled = true;
        this._appInfo.keywords = this.items.map(i => i.display).join(', ');
        const { data, error } = await this.profileService.updateAppInfo(this._appInfo);
        if (!error) {
            this.mapAppInfo(data);
            this.profileService.appInfoCompleted.next(true);
            if (this.fromDashboard) {
                this.router.navigate(['/']);
            } else {
                this.router.navigate(['/profile/onboarding-wizard/owner-info']);
            }

        }
        this.isDisabled = false;
    }

    private mapAppInfo(data: AppInfoResponse) {
        const keys = data.keywords.split(', ');
        keys.forEach(k => this.items.push({ display: k, value: k }));
        this._appInfo = {
            name: data.name,
            fullDescription: data.full_description,
            keywords: data.keywords,
            packageOptions: data.packaging_options || {
                monthly: {
                    price: 3.99,
                    trial_period: 'NONE'
                },
                yearly: {
                    price: 39.99,
                    trial_period: 'NONE'
                }
            },
            shortDescription: data.short_description,
            subtitle: data.store_subtitle,
            isLocked: data.is_locked
        };
        this.updateAppInfo();
    }

    private async load() {
        const { error, data } = await this.profileService.getAppInfo();
        if (!error) {
            this.mapAppInfo(data);
        }
    }

    private _appInfo: AppInfo = null;

}
