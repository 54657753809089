<div class="modal-header">
    <h4>Branded app preview</h4>
    <button type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="cancel()">
        <img src="assets/icons/clear.png" />
    </button>
</div>
<div class="modal-body">
    <div class="form-row justify-content-center">
        <app-modulator class="w-100"
                       [previewMode]="false"
                       [isWizard]="true"
                       [modulatorStep]="6"
                       [modulatorData]="modulator"></app-modulator>
    </div>

</div>
<div class="modal-footer">
    <button type="button"
            class="btn btn-default"
            (click)="cancel()">Cancel</button>
</div>
