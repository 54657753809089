<ng-template #monthlyPricePopover>
    <p class="popover-text">Monthly price</p>
</ng-template>
<ng-template #monthlyFreeTrialPopover>
    <p class="popover-text">Monthly Free trial is the period for which new subscribers can access content for
        free. Subscriptions begin immediately, but subscribers won't be billed until the free trial period is
        over.</p>
</ng-template>

<ng-template #yearlyPricePopover>
    <p class="popover-text">Yearly price</p>
</ng-template>
<ng-template #yearlyFreeTrialPopover>
    <p class="popover-text">Yearly Free trial is the period for which new subscribers can access content for
        free. Subscriptions begin immediately, but subscribers won't be billed until the free trial period is
        over.</p>
</ng-template>
<ng-template #storeSubtitlePopover>
    <p class="popover-text">Your app’s subtitle will sit directly below its title in the
        Apple App Store and gives you an additional 30 characters to describe your app to users. We encourage
        you to use
        targeted, descriptive keywords in this section.</p>
</ng-template>
<ng-template #keywordsPopover>
    <p class="popover-text">Keywords make App
        Store search results more accurate. e.g. weight loss, home workout, fitness workout, fitness app, yoga
        class,
        yoga at home, yoga app, hiit workout, weightlifting, meal planner, etc.</p>
</ng-template>
<ng-template #shortDescriptionPopover>
    <p class="popover-text">A short description about your application. Carefully select
        a set of keywords that best represent your app and use them in your app description. You can use a
        maximum of 80
        characters.</p>
</ng-template>
<ng-template #fullDescriptionPopover>
    <p class="popover-text">A longer description about your application which will be
        displayed on the full Play and App Store listing page. Give users a clear indication of what they get
        with your
        app. You can use a maximum of 3000 characters.</p>
</ng-template>


<div class="wizard-box"
     *ngIf="appInfo">
    <div class="container">
        <div class="wizard-box-stepper-box">
            <div *ngIf="!fromDashboard"
                 class="stepper-box included-app fifth-step"
                 [ngClass]="{'sixth-step' : isSecondStep}"></div>
            <a *ngIf="!isSecondStep && !fromDashboard"
               [routerLink]="'/profile/onboarding-wizard/upload-logo'"
               class="back-btn"><img src="../../assets/icons/ic_arrow_left-2.svg" /> <span class="back-arrow-text">Back</span></a>
            <a *ngIf="!isSecondStep && fromDashboard"
               [routerLink]="'/'"
               class="back-btn top-0"><img src="../../assets/icons/ic_arrow_left-2.svg" /> <span class="back-arrow-text">Back to dashboard</span></a>
            <a *ngIf="isSecondStep"
               (click)="isSecondStep = false;"
               class="back-btn"
               [ngClass]="{'top-0' : fromDashboard}"><img src="../../assets/icons/ic_arrow_left-2.svg" /> <span class="back-arrow-text">Back</span></a>
            <h6 *ngIf="!fromDashboard">Step {{ isSecondStep ? '6' : '5' }} of 8</h6>
            <h6 *ngIf="fromDashboard">Step {{ isSecondStep ? '6' : '5' }}</h6>
        </div>
        <div class="wizard-box-content">
            <h2>{{ isSecondStep ? 'App stores descriptions' : 'Personalized app price' }}</h2>
            <ng-container *ngIf="!isSecondStep">
                <p class="mb-0">Information below is used to define a subscription price for your personalized app.</p>
                <p class="mb-0 mt-0">This is necessary to get your app published on App and Play Stores as soon as
                    possible.
                </p>
            </ng-container>
            <ng-container *ngIf="isSecondStep">
                <p class="mb-0">Information below is used to describe what your personalized app does and who it is best
                    for.</p>
                <p class="mb-0 mt-0">This is necessary to get your app published on App and Play Stores as soon as
                    possible.</p>
            </ng-container>

            <form class="app-info"
                  #appInfoForm="ngForm"
                  [ngClass]="{'max-full-width' : isSecondStep}">
                <div *ngIf="!isSecondStep"
                     class="package-options-wrapper">
                    <div class="fileds-box">
                        <div class="filed-box">
                            <p class="input-label monthly-popover-fix">Monthly price in $ <span
                                      class="input-label-tooltip"
                                      [popover]="monthlyPricePopover"
                                      placement="bottom"
                                      triggers="hover"></span></p>
                            <input class="input"
                                   required
                                   type="number"
                                   name="monthlyPrice"
                                   [disabled]="appInfo.isLocked"
                                   [(ngModel)]="appInfo.packageOptions?.monthly.price"
                                   (ngModelChange)="updateAppInfo()"
                                   [ngClass]="{ 'form-submitted': appInfoForm.submitted || submitted}" />
                            <p class="descriptor input-descriptor-fix">Monthly price that your users will pay</p>
                        </div>
                        <div class="filed-box">
                            <p class="input-label monthly-free-popover-fix">Monthly free trial period <span class="input-label-tooltip"
                                      [popover]="monthlyFreeTrialPopover"
                                      placement="bottom"
                                      triggers="hover"></span></p>
                            <ng-select name="monthlyFreeTrial"
                                       class="select-input"
                                       placeholder="Select"
                                       [searchable]="false"
                                       [items]="monthlyFree"
                                       bindLabel="label"
                                       bindValue="value"
                                       [disabled]="appInfo.isLocked"
                                       required
                                       [multiple]="false"
                                       [(ngModel)]="appInfo.packageOptions?.monthly.trial_period"
                                       (ngModelChange)="updateAppInfo()"
                                       [ngClass]="{ 'form-submitted': appInfoForm.submitted || submitted }">
                            </ng-select>
                            <p class="descriptor input-descriptor">Duration of trial period for the monthly plan</p>
                        </div>
                    </div>
                    <div class="fileds-box">
                        <div class="filed-box">
                            <p class="input-label yearly-popover-fix">Yearly price in $ <span
                                      class="input-label-tooltip"
                                      [popover]="yearlyPricePopover"
                                      placement="bottom"
                                      triggers="hover"></span></p>
                            <input class="input"
                                   required
                                   type="number"
                                   name="yearlyPrice"
                                   [disabled]="appInfo.isLocked"
                                   [(ngModel)]="appInfo.packageOptions?.yearly.price"
                                   (ngModelChange)="updateAppInfo()"
                                   [ngClass]="{ 'form-submitted': appInfoForm.submitted || submitted }" />
                            <p class="descriptor input-descriptor-fix">Yearly price that your users will pay</p>
                        </div>
                        <div class="filed-box">
                            <p class="input-label yearly-free-popover-fix">Yearly free trial period <span
                                      class="input-label-tooltip"
                                      [popover]="yearlyFreeTrialPopover"
                                      placement="bottom"
                                      triggers="hover"></span></p>
                            <ng-select name="yearlyFreeTrial"
                                       class="select-input"
                                       placeholder="Select"
                                       [searchable]="false"
                                       [items]="yearlyFree"
                                       bindLabel="label"
                                       bindValue="value"
                                       required
                                       [disabled]="appInfo.isLocked"
                                       [multiple]="false"
                                       [(ngModel)]="appInfo.packageOptions?.yearly.trial_period"
                                       (ngModelChange)="updateAppInfo()"
                                       [ngClass]="{ 'form-submitted': appInfoForm.submitted || submitted }">
                            </ng-select>
                            <p class="descriptor input-descriptor">Duration of trial period for the yearly plan</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="isSecondStep"
                     class="fileds-box">
                    <div class="filed-box">
                        <p class="input-label">App keywords <span class="input-label-tooltip"
                                  [popover]="keywordsPopover"
                                  placement="bottom"
                                  triggers="hover"></span></p>
                        <tag-input class="input"
                                   [(ngModel)]="items"
                                   [maxItems]="5"
                                   (ngModelChange)="updateAppInfo()"
                                   [ngClass]="{ 'form-submitted': appInfoForm.submitted || submitted }"
                                   required
                                   [ripple]="false"
                                   [disable]="appInfo.isLocked"
                                   [placeholder]="'E.g Fitness, Nutrition, Health, Bodybuilding, Yoga'"
                                   [secondaryPlaceholder]="'E.g Fitness, Nutrition, Health, Bodybuilding, Yoga'"
                                   name="appKeywords"
                                   [separatorKeyCodes]="[188]"></tag-input>
                        <p class="descriptor input-descriptor-fix">Include up to 5 keywords that best describe your app
                        </p>
                    </div>
                    <div class="filed-box">
                        <p class="input-label store-popover-fix">App Store subtitle (optional) <span
                                  class="input-label-tooltip"
                                  [popover]="storeSubtitlePopover"
                                  placement="bottom"
                                  triggers="hover"></span></p>
                        <input class="input"
                               type="text"
                               maxlength="30"
                               placeholder="E.g App for living a healthy life."
                               name="appSubtitle"
                               [disabled]="appInfo.isLocked"
                               [(ngModel)]="appInfo.subtitle"
                               (ngModelChange)="updateAppInfo()"
                               [ngClass]="{ 'form-submitted': appInfoForm.submitted || submitted }" />
                        <p class="descriptor input-descriptor-fix"><span>This will be shown under the app title in App
                                Store</span>
                            <span>{{appInfo.subtitle.length}}/30</span>
                        </p>
                    </div>
                </div>
                <div *ngIf="isSecondStep"
                     class="fileds-box">
                    <div class="filed-box">
                        <p class="input-label">App short description <span class="input-label-tooltip"
                                  [popover]="shortDescriptionPopover"
                                  placement="top"
                                  triggers="hover"></span></p>
                        <textarea class="input input-textarea short-description"
                                  type="text"
                                  required
                                  [disabled]="appInfo.isLocked"
                                  name="appShortDescription"
                                  maxlength="80"
                                  [(ngModel)]="appInfo.shortDescription"
                                  (ngModelChange)="updateAppInfo()"
                                  [ngClass]="{ 'form-submitted': appInfoForm.submitted || submitted}"></textarea>
                        <p class="descriptor textarea-descriptor-fix"><span>Short description of your app</span><span>
                                {{appInfo.shortDescription.length}}/80</span></p>
                    </div>

                    <div class="filed-box"
                         stepperFix>
                        <p class="input-label full-description-popover-fix">App long description <span
                                  class="input-label-tooltip"
                                  [popover]="fullDescriptionPopover"
                                  placement="top"
                                  triggers="hover"></span></p>
                        <textarea class="input long-description input-textarea"
                                  type="text"
                                  maxlength="4000"
                                  required
                                  [disabled]="appInfo.isLocked"
                                  name="appFullDescription"
                                  [(ngModel)]="appInfo.fullDescription"
                                  (ngModelChange)="updateAppInfo()"
                                  [ngClass]="{ 'form-submitted': appInfoForm.submitted || submitted }"></textarea>
                        <p class="descriptor textarea-descriptor-fix"><span>Long description of your app</span>
                            <span>{{appInfo.fullDescription.length}}/4000</span>
                        </p>
                    </div>

                </div>
            </form>
        </div>
    </div>
    <div class="d-flex w-100 bottom-wizard-box">
        <button *ngIf="!appInfo.isLocked"
                class="btn button-true-green"
                [disabled]="!appInfoForm.valid || isDisabled"
                (click)="nextStep(appInfoForm)"
                [ngClass]="{'disabled' : !appInfoForm.valid}">{{ fromDashboard && isSecondStep? 'Save changes' :
            'Continue' }}</button>
        <button *ngIf="appInfo.isLocked"
                class="btn button-true-green"
                (click)="nextStepLocked()">{{ fromDashboard && isSecondStep? 'Finish' :
            'Continue' }}</button>
    </div>
</div>
