<div class="column">
    <h5 class="create-new-thread-heading">
        <span>Lean On support</span>
        <button *ngIf="createNewButton"
                class="button-true-green"
                (click)="onShowThreadForm()">Create new thread</button>
    </h5>
    
    <div class="message-box">
        <span class="link-back"
              *ngIf="!showCreateNew"
              (click)="onBackToThread()">
            <img src="../../assets/icons/ic_arrow_left-2.svg" />Thread details
        </span>
        
        <div class="content-left"
             [ngClass]="{'hide-section': !showCreateNew}">
            <div class="conversation-heading">
                <h5>Conversations</h5>
                <span>All your conversations will be shown here.</span>
                
                <div class="search-wrapper users">
                    <input id="search"
                        name="search"
                        class="text-input"
                        type="text"
                        [(ngModel)]="searchQuery"
                        (ngModelChange)="searchQueryStream.next($event)"
                        placeholder="Search threads" />
                    <label for="search"></label>
                    <button class="button-clear"
                            [ngClass]="{'active': searchQuery}"
                            (click)="clearSearch()"></button>
                </div>
                
                <div class="form-row-input d-flex w-100 align-items-center mt-4">
                    <input name="showClosedThreads"
                           class="checkbox input-checkbox m-0"
                           type="checkbox"
                           [class.true]="showClosedThreads"
                           [class.false]="!showClosedThreads"
                           [(ngModel)]="showClosedThreads"
                           (ngModelChange)="onShowClosedThreadsChange()" />
                    <span class="checkbox-label">Show closed threads</span>
                </div>
            </div>
            
            <div class="content-body threads-list"
                 [ngClass]="{'center-content': !list.length}">
                <div class="no-conversations"
                     *ngIf="!list.length">
                    <img src="../../assets/icons/ic_no_conversations.svg" />
                    <h6>No conversations</h6>
                    <span>There are currently no conversations</span>
                </div>
                
                <ng-container>
                    <div class="thread-item"
                         [ngClass]="{'selected': selectedThread?.id === thread.id}"
                         (click)="onSelectResolutionThread(thread)"
                         *ngFor="let thread of list">
                        <h6><span>{{ thread.subject }}</span><span
                                  class="noth-option-1"
                                  *ngIf="thread.unread_messages > 0">{{ thread.unread_messages }}</span></h6>
                        <span>{{ thread.created_at | dateFormat}}</span>
                        <button *ngIf="!thread.is_closed"
                                type="button"
                                title="Close thread"
                                (click)="closeThread($event, thread.id)"
                                class="button-check"></button>
                    </div>
                </ng-container>
                
                <button *ngIf="showLoadMoreThreads"
                        class="button-true-green"
                        (click)="loadMoreThreads()">Load more threads</button>
            </div>
        </div>
        
        <div class="content-right"
             *ngIf="showCreateNewForm">
            <div class="new-thread-heading">
                <h5>New thread</h5>
            </div>
            
            <div class="content-body new-thread">
                <form id="new-thread-form"
                      name="new-thread"
                      #newThreadOrMessageForm="ngForm"
                      (submit)="!newThreadOrMessageForm.invalid && onThreadFormSubmit(newThreadOrMessageForm)">
                    <div>
                        <div class="form-row">
                            <p class="input-label">Subject</p>
                            <input name="subject"
                                   [(ngModel)]="newThread.subject"
                                   [ngClass]="{ 'form-submitted': newThreadOrMessageForm.submitted }"
                                   required />
                        </div>
                        <div class="form-row">
                            <p class="input-label">Message</p>
                            <textarea name="message"
                                      [(ngModel)]="newThread.message"
                                      [ngClass]="{ 'form-submitted': newThreadOrMessageForm.submitted }"
                                      required></textarea>
                        </div>
                    </div>
                    
                    <attach-files [files]="uploadedFiles"
                                  (onRemove)="onRemoveAttachment($event)"
                                   width="100%"
                                   maxWidth="100%"
                                  [totalUploads]="fileList.length"
                                  (onAddFiles)="onAttachedFiles($event)"
                                  [progress]='progress'
                                  [current]="(uploadCounter)"></attach-files>
                    
                    <button class="button-true-green"
                            [disabled]="disableThreadForm"
                            type="submit">Create new thread</button>
                </form>
            </div>
        </div>
        
        <div class="content-right"
             *ngIf="!showCreateNewForm"
             [ngClass]="{'hide-section': showCreateNew}">
            <h5 class="thread-heading"
                *ngIf="!showCreateNewForm">{{ selectedThread.subject }}</h5>
            <div class="content-body messages-wrapper">
                <div class="message-list">
                    <button *ngIf="showLoadMoreMessages"
                            class="button-true-green load-more"
                            (click)="loadMoreThreadMessages()">Load previous messages</button>
                    
                    <ng-container *ngFor="let message of messages; let last = last;">
                        <div class="message no-avatar"
                             [ngClass]="{'indent': message.sender === 'root', 'last-message' : last}">
                            <h6>{{message.from}} <span>{{message.created_at | dateFormat}}</span></h6>
                            <p>{{message.message}}</p>
                            <p class="attachment input-label"
                               *ngFor="let file of message.attachments">
                                <a href="{{file.attachment.url}}"
                                   target="_blank"
                                   download="{{ file.attachment.name }}">{{file.attachment.name}} </a>
                                <img *ngIf="message.sender !== 'root'"
                                     src="../../assets/icons/delete.png"
                                     (click)="onDeleteAttachment(file)" />
                            </p>
                        </div>
                    </ng-container>
                </div>

                <form id="message-form"
                      name="create"
                      #messageForm="ngForm">
                    <textarea type="text"
                              class="message-input input"
                              name="messageText"
                              maxlength="4000"
                              [required]="uploadedFiles.length === 0"
                              [(ngModel)]="messageText"
                              [ngClass]="{'form-submitted': messageForm.submitted}"></textarea>
                    <attach-files [files]="uploadedFiles"
                                  (onRemove)="onRemoveAttachment($event)"
                                  [totalUploads]="fileList.length"
                                   width="100%"
                                   maxWidth="100%"
                                  (onAddFiles)="onAttachedFiles($event)"
                                  [progress]='progress'
                                  [current]="(uploadCounter)"></attach-files>
                    <button type="button"
                            #sendMessageButton
                            [disabled]="!messageText.length && uploadedFiles.length === 0"
                            class="button-true-green"
                            (click)="!messageForm.invalid && sendThreadMessage()">Reply</button>
                </form>
            </div>
        </div>
    </div>
</div>
