import { Component, OnInit } from '@angular/core';
import { InteractionService } from '@app/interaction/interaction.service';
import { NotificationsService } from '@shared/notifications.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProfileService, Subscription } from '../profile.service';
import { ManageSubscriptionComponent } from './manage-subscription/manage-subscription.component';

@Component({
    selector: 'app-subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

    constructor(
        private profileService: ProfileService,
        private modalService: BsModalService,
        private interaction: InteractionService,
        private notifications: NotificationsService) { }

    get subscription() { return this._subscription; }
    set subscription(value) { this._subscription = value; }

    public cards = null;

    ngOnInit(): void {
        this.load();
    }

    public async load() {
        const { data, error } = await this.profileService.getSubscription();
        if (!error) {
            this._subscription = data;
            if (data.billing_info.cards.length) {
                this.cards = data.billing_info.cards.filter(card => card.is_primary);
            }

        }
    }

    public async cancelAutoRenewawl() {
        const confirm = await this.interaction.confirm('Are you sure you want to cancel auto-renewal?');
        if (!confirm) {
            return;
        }
        const { data, error } = await this.profileService.cancelAutoRenewal();
        if (!error) {
            this.notifications.success('Success', 'Auto renewal canceled');
            this.subscription.coach_package.subscription_term_in_months = null;
        }
    }


    public async download(transaction: string) {
        const { data, error } = await this.profileService.downloadReceipt(transaction);
        if (!error) {
            window.open(data);
        }
    }

    public manageSubscription() {
        this._BsModalRef = this.modalService.show(ManageSubscriptionComponent, {
            initialState: {
                subscription: this.subscription,
            },
            class: 'manage-subscription-modal'
        });
    }

    private _subscription: Subscription = null;
    private _BsModalRef = null;

}
