<div class="file-upload"
     [ngClass]='manualClass'
     [class.hide]="readonly">
    <form name="{{id}}"
          #fileUploadForm="ngForm"
          class="{{ themeClass }}"
          [ngStyle]="{'background-image':'url(' + item?.url + ')'}">
        <label for="{{id}}"
               [class.hide]="readonly"
               [ngClass]="{'uploaded' : item}"
               class="gallery-body-imgs-add">
            <button *ngIf="item && manualClass === 'logo'"
                    type="button"
                    class="button-delete"
                    (click)="removeImage($event)"
                    [class.hide]="readonly"></button>
            <span class="progress"
                  #progressBar></span>
            <svg-icon class="toolbar-theme"
                      src="assets/icons/toolbar_light_theme.svg"></svg-icon>
            <svg-icon *ngIf="!item"
                      class="gallery-body-imgs-add-plus"
                      src="assets/icons/ic_add_image-v2.svg"></svg-icon>
            <p *ngIf="!item"
               class="gallery-body-imgs-add-text">{{ manualClass === 'logo' ? 'Add your logo' : 'Add your image' }}</p>
            <div class="onboarding-box"
                 *ngIf="manualClass === 'onboarding-1' || manualClass === 'onboarding-2' || manualClass === 'onboarding-3'">
                <div class="slide-dots-box">
                    <span class="slide-dots"
                          [ngStyle]="{'background': manualClass === 'onboarding-1' ? primaryColor : ''}"
                          [ngClass]="{'active': manualClass === 'onboarding-1'}"></span>
                    <span class="slide-dots"
                          [ngStyle]="{'background': manualClass === 'onboarding-2' ? primaryColor : ''}"
                          [ngClass]="{'active': manualClass === 'onboarding-2'}"></span>
                    <span class="slide-dots"
                          [ngStyle]="{'background': manualClass === 'onboarding-3' ? primaryColor : ''}"
                          [ngClass]="{'active': manualClass === 'onboarding-3'}"></span>
                </div>
                <div class="title-mask"></div>
                <div class="subtitle-mask"></div>
                <div class="subtitle-mask"></div>
                <div class="login-btn-mask"
                     [ngStyle]="{'background': primaryColor}">
                    <div class="btn-text-mask"></div>
                </div>
                <div class="register-btn-mask"
                     [ngStyle]="{'border-color': themeClass === 'light' ? primaryColor : ''}">
                    <div class="btn-text-mask"
                         [ngStyle]="{'background': themeClass === 'light' ? primaryColor : ''}"></div>
                </div>
            </div>
            <div class="home-background-box"
                 *ngIf="manualClass === 'home-background'">
                <div class="suptitle-mask"></div>
                <div class="home-title-mask"></div>
                <div class="activated-plan-info-mask">
                    <div class="plan-info-mask"></div>
                    <div class="separator-mask"
                         [ngStyle]="{'background': primaryColor}"></div>
                    <div class="plan-info-mask"></div>
                </div>
                <div class="login-btn-mask"
                     [ngStyle]="{'background': primaryColor}">
                    <div class="btn-text-mask"></div>
                </div>
                <div class="plans-title-mask"></div>
                <div class="plans-box-mask"></div>
            </div>
        </label>
        <input type="file"
               id="{{id}}"
               style="display: none"
               name="file"
               [multiple]="multiple"
               [required]="required"
               [(ngModel)]="fakePath"
               (change)="onFileChange($event)" />
    </form>
</div>
<div class="remove-btn-box"
     *ngIf="item && manualClass !== 'logo'">
    <button type="button"
            class="button-delete"
            (click)="removeImage($event)"
            [class.hide]="readonly"></button>
    <p (click)="removeImage($event)"
       class="smaller">Remove this image</p>
</div>

<canvas class="d-none"
        width="512"
        height="512"
        #canvas
        id="canvas"></canvas>
