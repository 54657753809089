<div *ngIf="pwaAssets && pwaAssets.theme_color"
     class="app-ready"
     [ngClass]="{'is-modal': isModal}">
    <img *ngIf="isModal"
         class="close-modal"
         (click)="bsModalRef.hide()"
         src="assets/icons/ic_x-1.svg" />
    <div class="app-ready-lf">
        <div class="app-ready-lf-box">
            <img *ngIf="!isModal"
                 [src]="pwaAssets?.logo?.url" />
            <h5 *ngIf="!isModal">{{ brandName }}</h5>
            <div style="display: none;"
                 [ngClass]="{'d-block' : show}"
                 class="mockup-phone-box">
                <div class="mockup-phone"></div>
                <img [src]="pwaAssets?.onboarding_1?.url"
                     style="min-height: 470px;" />
                <svg-icon class="{{ pwaAssets.theme_color }}"
                          src="../../../../assets/icons/onboarding.svg"></svg-icon>
            </div>
        </div>
    </div>
    <div class="app-ready-rh">
        <div class="app-ready-rh-box"
             [ngClass]="{'includedCustomApp' : includedCustomApp}">
            <h1 *ngIf="!includedCustomApp">Your branded <b>web app</b> is ready!</h1>
            <h1 *ngIf="includedCustomApp">Preview your <b>web app</b></h1>
            <p *ngIf="includedCustomApp">Until your iOS and Android mobile app is ready, you can preview how your
                content looks within web app we have created for you to use in the meantime.</p>
            <div class="app-ready-rh-inner-box">
                <qrcode [qrdata]="pwaUrl"
                        [width]="144"
                        [allowEmptyString]="true"
                        [errorCorrectionLevel]="'M'"></qrcode>
                <h4>Preview your web app</h4>
                <p>Scan QR code to preview your branded web app on mobile device or</p>
                <a [href]="pwaUrl"
                   target="_blank">Open web app</a>
            </div>
            <button *ngIf="!isModal"
                    type="button"
                    (click)="continueToDashboard()"
                    class="btn button-true-green w-100">{{ includedCustomApp ? 'Continue building your mobile app' :
                'Continue' }}</button>
        </div>
    </div>
</div>
