<div class="counter-wrapper">
        <button type="button"
                class="counter-minus"
                [disabled]="isDisabled"
                (click)=decreaseValue()></button>
        <input type="number"
               class="input counter-button"
               pattern="^-?[0-9][^\.]*$"
               [required]="required"
               [max]="maxValue"
               [min]="minValue"
               [step]="step"
               [disabled]="isDisabled"
               [readonly]="readonly"
               [(ngModel)]="value"
               (ngModelChange)="onChange(value)" />
        <button type="button"
                class="counter-plus"
                [disabled]="isDisabled"
                (click)=increaseValue()></button>
</div>
