import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@shared/authorization.service';
import { AppVisualsV2, ProfileService } from '../../profile.service';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-ready',
    templateUrl: './app-ready.component.html',
    styleUrls: ['./app-ready.component.scss']
})
export class AppReadyComponent implements OnInit {

    constructor(
        private profileService: ProfileService,
        private auth: AuthorizationService,
        private router: Router,
        private modalService: BsModalService,
        public bsModalRef: BsModalRef) { }

    get pwaAssets() { return this._pwaAssets; }
    public brandName = null;
    public pwaUrl = '';
    public show = false;
    public isModal: boolean;
    public includedCustomApp = false;
    public isAdvancedFlow = false;

    ngOnInit(): void {
        this.profileService.pwaAssets.subscribe(res => {
            if (res.theme_color) {
                this._pwaAssets = res;
                setTimeout(() => {
                    const collection = document.getElementsByClassName('primary-accent fill');
                    const collectionStroke = document.getElementsByClassName('stroke-ligth-dark');
                    const collectionText = document.getElementsByClassName('primary-accent ligth-dark');
                    if (this._pwaAssets.theme_color === 'light') {
                        Array.from(collectionStroke).forEach(element => {
                            element.setAttribute('stroke', this._pwaAssets.primary_color.color);
                        });
                        Array.from(collectionText).forEach(element => {
                            element.setAttribute('fill', this._pwaAssets.primary_color.color);
                        });
                    } else {
                        Array.from(collectionText).forEach(element => {
                            element.setAttribute('fill', '#fff');
                        });
                        Array.from(collectionStroke).forEach(element => {
                            element.setAttribute('stroke', '#fff');
                        });
                    }

                    Array.from(collection).forEach(element => {
                        element.setAttribute('fill', this._pwaAssets.primary_color.color);
                    });
                    setTimeout(() => {
                        this.show = true;
                    }, 1000);
                }, 1000);
            }
        });

        this.auth.user.subscribe((user) => {
            if (user) {
                this.brandName = user.application_name;
                this.pwaUrl = user.pwa_app_link;
                this.includedCustomApp = user.coach_package?.include_custom_application;
            }
        });
    }

    public continueToDashboard() {
        if (this.includedCustomApp) {
            return this.router.navigate(['/profile/onboarding-wizard/app-info']);
        } else if (this.auth.user.getValue().coach_package.pwa_wizard_advanced_flow){
            this.auth.refreshUserData();
            return this.router.navigate(['/']);
        }else{
            return this.router.navigate(['/profile/onboarding-wizard/owner-info']);
        }

    }

    private _pwaAssets: AppVisualsV2 = null;

}
