import { AppTheme } from "./types";

export const lightAccentColors: AppTheme[] = [
    {
        color: '#E61717',
        name: 'Light Red',
        value: 'light-light-red',
        desc: 'Passion & energy'
    }, {
        color: '#8B0000',
        name: 'Dark Red',
        value: 'light-dark-red',
        desc: 'Confidence & strength'
    }, {
        color: '#E37801',
        name: 'Light Orange',
        value: 'light-light-orange',
        desc: 'Creativity & balance'
    }, {
        color: '#C26600',
        name: 'Dark Orange',
        value: 'light-dark-orange',
        desc: 'Warmth and comfort'
    }, {
        color: '#D84545',
        name: 'Coral',
        value: 'light-coral',
        desc: 'Joy & optimism'
    }, {
        color: '#FFD803',
        name: 'Yellow',
        value: 'light-yellow',
        desc: 'Happiness & freshness'
    }, {
        color: '#B48F16',
        name: 'Gold',
        value: 'light-gold',
        desc: 'Wisdom & prosperity'
    }, {
        color: '#876E3D',
        name: 'Brown',
        value: 'light-brown',
        desc: 'Safety & stability'
    }, {
        color: '#1BA86E',
        name: 'Light Green',
        value: 'light-light-green',
        desc: 'Health & nature'
    }, {
        color: '#006417',
        name: 'Dark Green',
        value: 'light-dark-green',
        desc: 'Calmness & wealth'
    }, {
        color: '#198FEA',
        name: 'Light Blue',
        value: 'light-light-blue',
        desc: 'Trust & security'
    }, {
        color: '#172C66',
        name: 'Dark Blue',
        value: 'light-dark-blue',
        desc: 'Power & seriousness'
    }, {
        color: '#6246EA',
        name: 'Light Purple',
        value: 'light-light-purple',
        desc: 'Luxury & imagination'
    }, {
        color: '#431C53',
        name: 'Dark Purple',
        value: 'light-dark-purple',
        desc: 'Intuition & power'
    }, {
        color: '#EB487F',
        name: 'Light Pink',
        value: 'light-light-pink',
        desc: 'Femininity & youth'
    }, {
        color: '#E31259',
        name: 'Dark Pink',
        value: 'light-dark-pink',
        desc: 'Playfulness & comfort'
    }
];
export const darkAccentColors: AppTheme[] = [
    {
        color: '#FF5454',
        name: 'Light Red',
        value: 'dark-light-red',
        desc: 'Passion & energy'
    }, {
        color: '#E61717',
        name: 'Dark Red',
        value: 'dark-dark-red',
        desc: 'Confidence & strength'
    }, {
        color: '#FBC964',
        name: 'Light Orange',
        value: 'dark-light-orange',
        desc: 'Creativity & balance'
    }, {
        color: '#E37801',
        name: 'Dark Orange',
        value: 'dark-dark-orange',
        desc: 'Warmth and comfort'
    }, {
        color: '#FFD803',
        name: 'Yellow',
        value: 'dark-yellow',
        desc: 'Happiness & freshness'
    }, {
        color: '#B48F16',
        name: 'Gold',
        value: 'dark-gold',
        desc: 'Wisdom & prosperity'
    }, {
        color: '#876E3D',
        name: 'Brown',
        value: 'dark-brown',
        desc: 'Safety & stability'
    }, {
        color: '#D84545',
        name: 'Coral',
        value: 'dark-coral',
        desc: 'Joy & optimism'
    }, {
        color: '#90EE90',
        name: 'Light Green',
        value: 'dark-light-green',
        desc: 'Health & nature'
    }, {
        color: '#1BA86E',
        name: 'Dark Green',
        value: 'dark-dark-green',
        desc: 'Calmness & wealth'
    }, {
        color: '#198FEA',
        name: 'Light Blue',
        value: 'dark-light-blue',
        desc: 'Trust & security'
    }, {
        color: '#00FFEF',
        name: 'Turquoise Blue',
        value: 'dark-turquoise-blue',
        desc: 'Calmness & clarity'
    }, {
        color: '#C8B8F9',
        name: 'Light Purple',
        value: 'dark-light-purple',
        desc: 'Kindness & helpfulness'
    }, {
        color: '#917BFA',
        name: 'Dark Purple',
        value: 'dark-dark-purple',
        desc: 'Luxury & imagination'
    }, {
        color: '#FFB6C1',
        name: 'Light Pink',
        value: 'dark-light-pink',
        desc: 'Femininity & youth'
    }, {
        color: '#FB3E7E',
        name: 'Dark Pink',
        value: 'dark-dark-pink',
        desc: 'Playfulness & comfort'
    }
];
