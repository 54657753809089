import { EventEmitter, Injectable } from '@angular/core';
import { ModulatorRequest } from '@app/applications/types';
import { GalleryItem } from '@app/gallery/gallery-item';
import { FitApiClientService } from '@shared/fit-api-client.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppInfo, AppInfoRequest, AppInfoResponse, AppTheme, BillingInfo, Owner, OwnerRequest, OwnerResponse } from './types';


interface StripeResponse {
    stripe_public_key: string;
    client_secret: string;
    invoice_number: string;
}

export interface Subscription {
    billing_info: BillingInfo;
    auto_renewal_date: string;
    cancel_auto_renewal: boolean;
    coach_package: SubscriptionPackage;
    next_billing_date: string;
    subscription_end_date: string;
    subscription_start_date: string;
    transactions: SubscriptionTransaction[];
}

export interface SubscriptionPackage {
    name: string;
    price: string;
    billing_period: string;
    subscription_term_in_months: any;
    clients_limit?: number;
}

export interface SubscriptionTransaction {
    card: string;
    currency: string;
    invoice: string;
    price: string;
    status: string;
    timestamp: string;
    transaction: string;
}


export interface AppVisuals {
    gallery: GalleryItem[];
    is_locked: boolean;
    id?: number;
    logo: GalleryItem;
    modulator_data: {
        created_at: string;
        data: ModulatorRequest
    };
    accent_color: AppTheme;
    theme_color: AppTheme;
    is_from_app_visuals: boolean;
    our_design: boolean;
}

export interface AppVisualsV2 {
    theme_color: 'light' | 'dark';
    primary_color: AppTheme;
    logo: GalleryItem;
    home_background: GalleryItem;
    splash_screen: GalleryItem;
    onboarding_1: GalleryItem;
    onboarding_2: GalleryItem;
    onboarding_3: GalleryItem;
}

export interface GetAppVisualsV2 {
    theme: 'light' | 'dark';
    primary_color: string;
    logo: GalleryItem;
    home_background: GalleryItem;
    splash_screen: GalleryItem;
    onboarding_1: GalleryItem;
    onboarding_2: GalleryItem;
    onboarding_3: GalleryItem;
    is_locked: boolean;
}



@Injectable({ providedIn: 'root' })
export class ProfileService {
    constructor(private fitApi: FitApiClientService) { }

    public pwaAssets = new BehaviorSubject<AppVisualsV2>({
        primary_color: null,
        home_background: null,
        logo: null,
        onboarding_1: null,
        onboarding_2: null,
        onboarding_3: null,
        splash_screen: null,
        theme_color: null
    });

    public appInfoCompleted = new Subject<boolean>();
    public appVisualsCompleted = new Subject<boolean>();
    public ownerInfoCompleted = new Subject<boolean>();
    public billingInfoCompleted = new Subject<boolean>();
    public hasBillingInfo = new Subject<boolean>();

    public showStepperSaveCard = new EventEmitter<boolean>();

    public copyOwnerFromStepper = new EventEmitter();

    public ownerInfo: OwnerResponse = null;

    public setOwnerInfo(value: OwnerResponse) {
        this.ownerInfo = value;
    }

    public copyOwnerInfo(): BillingInfo {
        const billingInfo = this.mapToBillingInfo(this.ownerInfo);
        return billingInfo;
    }

    public getAppInfo() {
        return this.fitApi.get<AppInfoResponse>(`/admin/profile/app-info`);
    }

    public getOwnerInfo() {
        return this.fitApi.get<OwnerResponse>(`/admin/profile/owner-info`);
    }

    public getSubscription() {
        return this.fitApi.get<Subscription>('/admin/subscription');
    }

    public downloadReceipt(id: string) {
        return this.fitApi.get<any>(`/admin/subscription/transaction/${id}/receipt`);
    }

    public cancelAutoRenewal() {
        return this.fitApi.put('/admin/subscription/cancel-auto-renewal', {});
    }

    public async updateAppInfo(appInfo: AppInfo) {
        const data = this.mapToAppInfoRequest(appInfo);
        const result = await this.fitApi.put<AppInfoResponse>(
            `/admin/profile/app-info`,
            data
        );
        return result;
    }

    public async updateOwnerInfo(ownerInfo: Owner) {
        const data = this.mapToOwnerRequest(ownerInfo);
        const result = await this.fitApi.put<OwnerResponse>(
            `/admin/profile/owner-info`,
            data
        );
        return result;
    }

    public getBillingInfo() {
        return this.fitApi.get<BillingInfo>(`/admin/profile/billing-info`);
    }

    public createBilling(billing: BillingInfo) {
        return this.fitApi.put<BillingInfo>(
            `/admin/profile/billing-info`,
            billing
        );
    }

    public getBillingCardToken() {
        return this.fitApi.get<any>(`/admin/profile/billing-info/add-card`);
    }

    public setAsPrimaryCard(id: number) {
        const params = {
            id: id.toString()
        };
        return this.fitApi.put<any>(`/admin/profile/billing-info/toggle-primary-card`, params);
    }

    public deleteCard(id: number) {
        const params = {
            id: id.toString()
        };
        return this.fitApi.delete<any>(`/admin/profile/billing-info/delete-card`, params);
    }

    public getBillingCardStripe() {
        return this.fitApi.get<StripeResponse>(
            `/admin/profile/billing-info/add-card-stripe`
        );
    }

    public getAppVisuals() {
        return this.fitApi.get<AppVisuals>(`/admin/profile/app-visuals`);
    }

    public updateAppVisuals(
        logoId: number,
        imageIds: number[],
        themeColor: AppTheme,
        accentColor: AppTheme,
        modulator: ModulatorRequest,
        ourDesign: boolean) {
        const params = {
            logo_id: logoId,
            gallery_ids: imageIds,
            theme_color: themeColor,
            accent_color: accentColor,
            modulator_data: modulator,
            our_design: ourDesign
        };
        return this.fitApi.put<AppVisuals>(
            `/admin/profile/app-visuals`,
            params
        );
    }

    public setPwaAssets(assets: AppVisualsV2) {
        const params = {
            logo: assets.logo.id,
            primary_color: assets.primary_color.color,
            theme: assets.theme_color,
            home_background: assets.home_background.id,
            onboarding_1: assets.onboarding_1.id,
            onboarding_2: assets.onboarding_2.id,
            onboarding_3: assets.onboarding_3.id,
            splash_screen: assets.splash_screen.id
        };
        return this.fitApi.put<any>(`/admin/pwa/resources`, params);
    }

    public getPwaAssets() {
        return this.fitApi.get<GetAppVisualsV2>(`/admin/pwa/resources`);
    }

    public generatePwaApp() {
        return this.fitApi.post<any>(`/admin/pwa/generate`, null);
    }

    public mapToAppInfoRequest(appInfo: AppInfo): AppInfoRequest {
        return {
            name: appInfo.name,
            store_subtitle: appInfo.subtitle,
            short_description: appInfo.shortDescription,
            full_description: appInfo.fullDescription,
            keywords: appInfo.keywords,
            packaging_options: appInfo.packageOptions,
        };
    }

    public mapToOwnerRequest(owner: Owner): OwnerRequest {
        return {
            user_id: owner.user_id,
            street_name_and_number: owner.street_name_and_number,
            street_name_and_number_line_2: owner.street_name_and_number_line_2,
            city: owner.city,
            country: owner.country,
            state: owner.state,
            zip_code: owner.zip_code,
        };
    }

    public mapToBillingInfo(ownerInfo: OwnerResponse): BillingInfo {
        if (!ownerInfo) {
            return;
        }
        return {
            cards: ownerInfo.cards || [],
            city: ownerInfo.city,
            country: ownerInfo.country,
            email: ownerInfo.email,
            name: ownerInfo.first_name,
            state: ownerInfo.state,
            street_name_and_number: ownerInfo.street_name_and_number,
            street_name_and_number_line_2:
                ownerInfo.street_name_and_number_line_2,
            surname: ownerInfo.last_name,
            zip_code: ownerInfo.zip_code,
        };
    }

}
