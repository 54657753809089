<div class="wizard-box">
    <div class="container">
        <div class="wizard-box-stepper-box">
            <div *ngIf="!fromDashboard"
                 class="stepper-box"></div>
            <a *ngIf="fromDashboard"
               [routerLink]="'/'"
               class="back-btn"
               [ngClass]="{'top-0' : fromDashboard}"><img src="../../assets/icons/ic_arrow_left-2.svg" /><span class="back-arrow-text">{{ fromDashboard ? 'Back to dashboard' : 'Back' }}</span></a>
            <ng-container *ngIf="!fromDashboard">
                <h6>Step 1 of {{ !includedCustomApp ? '6' : '8' }}</h6>
            </ng-container>
            <ng-container *ngIf="fromDashboard">
                <h6>Step 1</h6>
            </ng-container>
        </div>
        <div class="wizard-box-content">
            <h2>Choose your theme color</h2>
            <p *ngIf="!includedCustomApp">This is the background color of your web app. You can select one theme color.
            </p>
            <p *ngIf="includedCustomApp">This is the background color of your personalized fitness app. You can select
                one theme color.</p>
            <div class="theme-color-content">
                <div [ngClass]="{'selected-theme' : selected === 'light'}"
                     class="theme-color-box"
                     (click)="selectThemeColor('light')">
                    <div class="theme-color-box-color"></div>
                    <div class="theme-color-box-desc">
                        <span *ngIf="selected === 'light'"
                              class="checkmark-selected"></span>
                        <h6>Light theme</h6>
                        <p class="smaller">Clean, healthy and fresh</p>
                    </div>
                </div>
                <div [ngClass]="{'selected-theme' : selected === 'dark'}"
                     class="theme-color-box"
                     (click)="selectThemeColor('dark')">
                    <div class="theme-color-box-color"></div>
                    <div class="theme-color-box-desc">
                        <span *ngIf="selected === 'dark'"
                              class="checkmark-selected"></span>
                        <h6>Dark theme</h6>
                        <p class="smaller">Professional, elegant & stylish</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex w-100 bottom-wizard-box">
        <a *ngIf="!fromDashboard"
           [routerLink]="['/profile/onboarding-wizard/primary-color']"
           class="btn button-true-green"
           [ngClass]="{'disabled' : !selected}">Continue</a>
        <button *ngIf="fromDashboard"
                type="button"
                class="btn button-true-green"
                (click)="saveChanges()">Save changes</button>
    </div>
</div>
