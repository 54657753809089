<div class="library-modal">
    <div class="library">
        <div class="heading">
            <h4>Select {{title}}</h4>
            <img class="close-modal"
                 (click)="onCloseModal()"
                 src="assets/icons/clear.png" />
        </div>
        <div class="search">
            <div class="search-wrapper">
                <input id="search"
                       name="search"
                       class="text-input"
                       type="text"
                       [(ngModel)]="searchQuery"
                       (ngModelChange)="searchQueryStream.next($event)"
                       placeholder="Search exercises" />
                <label for="search"></label>
                <button class="button-clear"
                        [ngClass]="{'active': searchQuery}"
                        (click)="clearSearch()"></button>
            </div>
        </div>
        <div class="items-wrapper">
            <div class="items">
                <div class="item"
                     *ngFor="let item of list"
                     (click)="onSelectedItem(item)">
                    <p>{{ item.name }}</p>
                    <button><img src="assets/icons/upload.png" /></button>
                </div>
            </div>
        </div>
        <pagination *ngIf="hasPagination"
                    [boundaryLinks]="true"
                    [totalItems]="totalItems"
                    [itemsPerPage]="itemsPerPage"
                    [maxSize]="10"
                    (pageChanged)="onPageChanged($event)"
                    previousText="&lsaquo;"
                    class="modal-body-pagination"
                    nextText="&rsaquo;"
                    firstText="&laquo;"
                    lastText="&raquo;">
        </pagination>
    </div>
</div>
