import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GalleryItem } from '@app/gallery/gallery-item';

@Component({
  selector: 'attach-files',
  templateUrl: './attach-files.component.html',
  styleUrls: ['./attach-files.component.scss']
})
export class AttachFilesComponent implements OnInit {

  constructor() { }

  @Input() public files: GalleryItem[] = [];

  @Output() public onAddFiles = new EventEmitter<any>()
  @Output() public onRemove = new EventEmitter<any>();

  public fakePath = null;
  @Input() public progress = 0;
  @Input() public current = 0;
  @Input() public totalUploads = 0;
  @Input() public title = '';
  @Input() public width: string;
  @Input() public maxWidth: string;

  ngOnInit(): void {
  }

  public onRemoveFile(file){
    this.onRemove.emit(file)
  }

  public onSelectFile(event){
    this.onAddFiles.emit([...event.target.files]);
  }

}
