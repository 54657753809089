import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type SortBy = 'name' | 'oldest' | 'newest';

@Component({
    selector: 'list-sort',
    templateUrl: './list-sort.component.html',
    styleUrls: ['./list-sort.component.scss']
})

export class ListSortComponent implements OnInit {
    constructor() {}

    @Input() buttonsOnly = false;
    @Input() fromGallery = false;
    @Input() public currentSort: SortBy  = 'name';
    @Output() public onSort = new EventEmitter<SortBy>();

    public ngOnInit() {
        if (sessionStorage.getItem('sort-by') && this.fromGallery) {
            const sortBy = sessionStorage.getItem('sort-by') as SortBy;
            this.onSort.emit(sortBy);
        }
    }

    public changeSort(sort: SortBy) {
        sessionStorage.setItem('sort-by', sort)
        this.onSort.emit(sort)
    }
}
