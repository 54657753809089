import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';

import { ConfirmComponent } from './confirm/confirm.component';
import { InteractionService } from './interaction.service';

@NgModule({
    declarations: [ConfirmComponent],
    imports: [
        CommonModule,
        ModalModule.forRoot()
    ],
    providers: [InteractionService]
})
export class InteractionModule { }
