import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Observable } from 'rxjs';
import { FitApiClientService } from '@shared/fit-api-client.service';
import { NotificationMessage } from './resolution-center/resolution-center.service';



@Injectable({ providedIn: 'root' })
export class MessagingService {


    constructor(private angularFireMessaging: AngularFireMessaging, private fitApi: FitApiClientService) {
        this.initMessaging();
    }

    public requestPermission() {
        return this.angularFireMessaging.requestToken;
    }

    public receiveMessage() {
        return this.angularFireMessaging.messages as unknown as Observable<NotificationMessage>;
    }

    public tokenChanged() {
        return this.angularFireMessaging.tokenChanges;
    }

    public saveToken(token: string) {
        const params = {
            firebase_id: token,
            client_unique: navigator.userAgent
        };
        return this.fitApi.put('/admin/user/firebase', params);
    }

    private initMessaging() {
        this.angularFireMessaging.messages.subscribe(
            (_messaging: any) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        );
    }
}
