import { Component } from '@angular/core';
import { Modulator } from '@app/applications/types';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'preview-modulator',
    templateUrl: './preview-modulator.component.html',
    styleUrls: ['./preview-modulator.component.scss'],
})
export class PreviewModulatorModalComponent {
    constructor(public bsModalRef: BsModalRef) { }

    public onClose: Subject<any> = new Subject();
    public modulator: Modulator = null;

    public confirm() { }

    public cancel() {
        this.onClose.next(null);
        this.bsModalRef.hide();
    }
}
