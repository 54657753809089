import { Injectable } from '@angular/core';
import { FitApiClientService } from './fit-api-client.service';

export interface AvailableCurrency {
    name: string;
    code: string;
}

@Injectable()
export class CurrencyService {
    constructor(private fitApi: FitApiClientService) {}

    public getAvailableCurrencies() {
        return this.fitApi.get<AvailableCurrency[]>(`/admin/currencies`);
    }
}
