import { Injectable } from '@angular/core';
import { GalleryItem, GalleryUploadLimits } from '@app/gallery/gallery.service';
import { Customer } from '@app/user/user.service';
import { FitApiClientService, FitList } from '@shared/fit-api-client.service';
import { Subject } from 'rxjs';

export interface ResolutionMessage {
    id: number;
    created_at: string;
    available_at: string;
    from: string;
    message: string;
    seen: boolean;
    to: string;
    user_avatar?: string;
    sender: 'root' | 'owner';
    attachments: Attachment[];
}

export interface ClientMessageResponse {
    id: number;
    chat_id: number;
    sender: 'owner' | 'user';
    user_id: number;
    user_name: string;
    user_avatar?: string;
    message: string;
    is_message_deleted: boolean;
    created_at: string;
    attachments: Attachment[];
    reactions: Reaction[];
}

export interface ClientMessage {
    id: number;
    chat_id: number;
    sender: 'owner' | 'user';
    user_id: number;
    user_name: string;
    user_avatar?: string;
    message: string;
    is_message_deleted: boolean;
    is_message_unread?: boolean;
    created_at: string;
    attachments: Attachment[];
    reactions: Reaction[];
}

export interface Attachment {
    attachment: AttachmentData;
    resolution_centre_attachments_id: number;
    chat_message_attachments_id: boolean;
}

export interface AttachmentData {
    id: number;
    name: string;
    thumbnail: string;
    type: string;
    url: string;
}

export interface Reaction {
    content: string;
    count: number;
    is_user_reaction: boolean;
}

export interface ThreadRequest {
    application_id?: number;
    subject: string;
    message: string;
    attachment_ids?: number[];
}

export interface ClientThread {
    id: number;
    last_message?: string;
    last_message_at: string;
    unread_messages: number;
    user_avatar: string;
    user_id: number;
    user_name: string;
}

export interface TotalUnread {
    number_of_unread_chats: number;
    number_of_unread_resolution_centre_threads: number;
    unread_threads: any[] | HeaderNotification[] | MessageData[];
}

export interface UnreadNotifications {
    count: number;
    notifications: Notification[];
}

interface Notification {
    notification_id: number;
    type: string;
    type_id: number;
    timestamp: string;
    sender: string;
    seen: boolean;
}

export type NotificationType = 'ResolutionCentreMessage'
    | 'ChatMessage'
    | 'GroupChatMessage'
    | 'GroupChatObjection'
    | 'ScheduledEvent'
    | 'LiveEvent'
    | 'LiveEventStart'
    | 'QuestionnaireResponseFilled'
    | 'NoSuitableProgramFound'
    | 'FeedPostCommentPosted';

export interface MessageData {
    id: string;
    link: string;
    message: string;
    title: string;
    type: NotificationType;
    image: string;
    notification_id: string | number;
    cms_url: string;
    seen?: boolean;
}

export interface NotificationMessage {
    collapseKey: string;
    data: MessageData;
    from: string;
    messageId: string;
}

export interface HeaderNotification {
    sender: string;
    timestamp: string;
    type: string;
    seen?: boolean;
}

export interface Thread extends Customer {
    id: number;
    subject: string;
    total_messages: number;
    unread_messages: number;
    created_at: string;
    user_name?: string;
    user_id: number;
    is_closed: boolean;
}

export interface MessageRequest {
    application_id?: number;
    thread_id?: number;
    message: string;
    available_at?: string;
    user_id?: number;
    attachment_ids?: number[];
}

export interface BroadcastRequest {
    application_id?: number;
    message: string;
    attachment_ids?: number[];
}

export interface AllApplicationsUnreadMessages {
    whitelabel_application_id: number;
    whitelabel_application_name: string;
    count: number;
}

function formatBytes(bytes, decimals) {
    if (bytes === 0) {
        return '0 Bytes';
    }
    
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

@Injectable({
    providedIn: 'root'
})

export class ResolutionCenterService {
    constructor(private fitApi: FitApiClientService) { }

    public unreadClientMessagesNotificator = new Subject<number>();
    public updateAllAppsMessages = new Subject<any>();
    public showNewThreadForm = new Subject<boolean>();
    public updateTotalNotifications = new Subject<boolean>();
    public newMessageReceived = new Subject();
    public newDetailsResolutionCenterMessage = new Subject();

    public uploadLimits: GalleryUploadLimits = {
        image_types: [],
        upload_limit_in_bytes: 0,
        video_types: [],
        document_types: []
    };

    public formattedBytes = '';
    public threadMessageCache = {};

    public getResolutionMessages(appId: number, threadId: number, page: number) {
        let params;
        
        if (!appId) {
            params = {
                thread_id: threadId.toString(),
                page: page.toString()
            };
        } else {
            params = {
                application_id: appId.toString(),
                thread_id: threadId.toString(),
                page: page.toString()
            };
        }
        
        return this.fitApi.get<FitList<ResolutionMessage>>(`/admin/message`, params);
    }

    public getUploadLimits() {
        this.fitApi.get<GalleryUploadLimits>('/admin/gallery/upload-limits').then(res => {
            this.uploadLimits = res.data;
            this.formattedBytes = this.formatBytes(this.uploadLimits.upload_limit_in_bytes, 2);
        });
    }

    public uploadAttachment(fileName: string, file: File, application_id: number) {
        const form = new FormData();
        form.append('url', file);
        form.append('name', fileName);
        form.append('is_user_avatar', 'false');
        return this.fitApi.uploadFileAttachment<GalleryItem>(form, application_id);
    }

    public formatBytes(bytes, decimals) {
        return formatBytes(bytes, decimals);
    }

    public isValidFileType(file: File, acceptedType: string) {
        const typeValidator = (types: string[]) => types.some(type => file.type.toLowerCase().indexOf(type) > -1);
        return typeValidator(this.getAcceptableFileTypes(acceptedType));
    }

    public getAcceptableFileTypes(acceptedType: string) {
        switch (acceptedType) {
            case 'image':
                return this.uploadLimits.image_types;
            case 'video':
                return this.uploadLimits.video_types;
            case 'all':
                return [...this.uploadLimits.image_types, ...this.uploadLimits.video_types];
        }
    }

    public deleteAttachment(file: Attachment, appId: number, isClientMessaging: boolean) {
        if (isClientMessaging) {
            return this.fitApi.delete<any>(`/admin/chat/message/attachment/${file.chat_message_attachments_id}`);
        }
        
        if (appId) {
            return this.fitApi.delete<any>(`/admin/message/attachment/${file.resolution_centre_attachments_id}/${appId}`);
        }
        
        return this.fitApi.delete<any>(`/admin/message/attachment/${file.resolution_centre_attachments_id}`);
    }

    /*
    public deleteMessage(messageId: number) {
        return this.fitApi.delete<ClientMessage>(`/admin/chat/message/${messageId}`);
    }
    */

    public deleteMessage(messageId: number, appId: number, isClientMessaging: boolean) {
        if (isClientMessaging) {
            return this.fitApi.delete<ClientMessage>(`/admin/chat/message/${messageId}`);
        }

        return this.fitApi.delete<any>(`/admin/message/${messageId}/${appId}`);
    }

    public markMessageUnread(messageId: number) {
        return this.fitApi.post<ClientMessage>(`/admin/chat/message/${messageId}/unread`, {});
    }

    public postReaction(messageId: number, reaction: string) {
        return this.fitApi.post<ClientMessage>(`/admin/chat/message/${messageId}/reaction`, {
            reaction: reaction
        });
    }

    public markThreadAsSeen(applicationId: number, threadId: number) {
        let params;
        
        if (applicationId) {
            params = {
                application_id: applicationId,
                thread_id: threadId
            };
            return this.fitApi.post<FitList<ResolutionMessage>>(`/admin/message/read/${threadId}/${applicationId}`, {});
        } else {
            params = {
                thread_id: threadId
            };
            return this.fitApi.post<FitList<ResolutionMessage>>(`/admin/message/read/${threadId}`, {});
        }
    }

    public clearAllNotifications() {
        return this.fitApi.post<any>(`/admin/notification/seen-all`, {});
    }

    public getTotalNotifications() {
        // return this.fitApi.get<TotalUnread>(`/admin/notification/unread-messages`);
        return this.fitApi.get<UnreadNotifications>(`/admin/notification`);
    }

    public markNotificationSeen(notification_id: number) {
        return this.fitApi.put<any>(`/admin/notification/${notification_id}/seen`, { notification_id });
    }

    public getAllAplicationsUnreadMessages() {
        return this.fitApi.get<AllApplicationsUnreadMessages[]>(`/admin/message/unread-per-app`);
    }

    public clearIntervalUnreadMessages() {
        clearInterval(this._interval);
    }

    public getRefinedMessage(message: string) {
        const params: any = {
            text: message,
        };

        return this.fitApi.get<any>('/admin/ai-assistant/rewrite-content', params);
    }

    public sendResolutionMessage(message: MessageRequest, attachments: GalleryItem[]) {
        const params = {
            application_id: message.application_id,
            thread_id: message.thread_id,
            message: message.message,
            available_at: message.available_at,
            attachment_ids: attachments.map(item => item.id)
        };
        
        return this.fitApi.put<any>(`/admin/message`, params);
    }

    public sendMessageToContact(message: MessageRequest) {
        return this.fitApi.put<any>(`/admin/chat/message`, message);
    }

    public broadcastMessageToAll(message: BroadcastRequest) {
        return this.fitApi.put<any>(`/admin/chat/broadcast`, message);
    }

    public getResolutionThreadList(applicationId: number, query?: string, showClosed = false, page: number = 1) {
        if (!applicationId) {
            return this.fitApi.get<FitList<Thread[]>>(`/admin/message/thread`, { page: page.toString(), search: query, show_closed: String(showClosed) });
        }
        
        const params = {
            application_id: applicationId.toString(),
            page: page.toString(),
            show_closed: String(showClosed)
        };

        if (query) {
            params["search"] = query;
        }

        return this.fitApi.get<FitList<Thread[]>>(`/admin/message/thread`, params);
    }

    public createNewThread(thread: ThreadRequest, attachments: GalleryItem[]) {
        let params;
        
        if (!thread.application_id) {
            params = {
                application_id: null,
                subject: thread.subject,
                message: thread.message,
                attachment_ids: attachments.map(item => item.id)
            };
        } else {
            params = {
                application_id: thread.application_id,
                subject: thread.subject,
                message: thread.message,
                attachment_ids: attachments.map(item => item.id)
            };
        }
        
        return this.fitApi.put<FitList<any>>(`/admin/message/thread`, params);
    }

    public getContactThreadList(page = 1, query?) {
        let params;
        
        if (query) {
            params = {
                search: query,
                page: page.toString()
            };
        } else {
            params = {
                page: page.toString()
            };
        }
        
        return this.fitApi.get<FitList<ClientThread[]>>(`/admin/chat`, params);
    }

    public getContactThreadByMessageId(messageId: number) {
        return this.fitApi.get<ClientThread>(`/admin/chat/by-message/${messageId}`);
    }

    public closeThread(threadId: number, applicationId?: number) {
        let params;
        
        if (applicationId) {
            params = {
                thread_id: threadId.toString(),
                application_id: applicationId.toString()
            };
        } else {
            params = {
                thread_id: threadId.toString()
            };
        }

        return this.fitApi.put<any>(`/admin/message/thread/close`, params);
    }

    public getContactMessages(id: number, page = 1) {
        const params = {
            page: page.toString()
        };
        
        return this.fitApi.get<FitList<ClientMessageResponse>>(`/admin/chat/${id}/messages`, params);
    }

    public getCustomerMessagesById(id: number, page = 1) {
        const params = {
            page: page.toString()
        };
        
        return this.fitApi.get<FitList<any>>(`/admin/customer/${id}/messages`, params);
    }

    public cacheThread(id: number, message: string) {
        this.threadMessageCache[id] = message;
    }

    public getThreadCahedMessage(id: number): string {
        if (id in this.threadMessageCache) {
            return this.threadMessageCache[id];
        }
        
        return '';
    }

    private _interval = null;
}
