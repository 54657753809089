import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChooseButton, PickedLibrary } from '../interaction.service';

export enum ConfirmStatus {
    CANCELED,
    CONFIRMED,
    PLAN,
    WORKOUT,
    EXERCISE
}

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})

export class ConfirmComponent{
    constructor(public bsModalRef: BsModalRef) { }
    public title: string;
    public multiple: boolean;
    public buttonlist: ChooseButton[];
    public cancelBtnName = 'Cancel';
    public confirmBtnName = 'Confirm';
    public renderAsHtml: boolean;

    public status: ConfirmStatus;

    public confirm() {
        this.status = ConfirmStatus.CONFIRMED;
        this.onClose(this.status);
        this.bsModalRef.hide();
    }

    public pickedOption(option){
        this.status = option;
        this.onPick(option);
        this.bsModalRef.hide();
    }

    public cancel() {
        this.status = ConfirmStatus.CANCELED;
        this.onClose(this.status);
        this.bsModalRef.hide();
    }

    private onPick(status: PickedLibrary) { }
    private onClose(status: ConfirmStatus) { }
}
