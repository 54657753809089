import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthorizationService, User } from '@shared/authorization.service';
import { pinClear, pinGetter } from '@shared/fit-api-client.service';
import { FitConfigProviderService, SidebarNavigationItem } from '@shared/fit-config-provider.service';
import { FreeTrialService } from '@shared/trieal-period.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MessagingService } from './messaging.service';
import { RecordingService } from './recording/recording.service';

import { 
    AllApplicationsUnreadMessages, 
    MessageData, 
    NotificationMessage, 
    ResolutionCenterService, 
    UnreadNotifications 
} from './resolution-center/resolution-center.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        // recording must be instantiated even if not used
        private recording: RecordingService,
        private messagingService: MessagingService,
        private freeTrialService: FreeTrialService,
        private resolutionService: ResolutionCenterService,
        private auth: AuthorizationService,
        private fitConfigProviderService: FitConfigProviderService,
        private router: Router
    ) {}

    public freeTrialEndedAgo: number;
    public isCollapsed = false;
    public isTabletMobile = false;
    public pin = pinGetter();
    public isStreamingPage = false;
    public resolutionMessages = 0;
    public outsideClick = false;
    public clientMessages = 0;
    public showNotificationList = false;
    public trialEndsIn;
    public allAppsUnreadMessages: AllApplicationsUnreadMessages[] = [];
    public nothIconFarLeft = false;
    public currentMessage = new BehaviorSubject<MessageData>(null);
    public isOnBoardingPage = false;
    public loading = false;

    get isAuthenticated() {
        this.pin = pinGetter();
        return this.auth.isAuthenticated();
    }

    get user() { return this.auth.user; }
    get navigation() { return this.fitConfigProviderService.navigation; }
    get isRoot() { return this.fitConfigProviderService.isRoot; }
    get isOwner() { return this.fitConfigProviderService.isOwner; }
    get isAdministrator() { return this.fitConfigProviderService.isAdministrator; }
    get isCoach() { return this.fitConfigProviderService.isCoach; }
    get totalNotifications() { return this._totalNotifications; }
    set totalNotifications(value) { this._totalNotifications = value; }
    get showSpinner() { return this.auth.showSpinner; }

    @ViewChild('notifications') nothIcon: ElementRef;

    public ngOnInit() {
        this.isTabletMobile = window.innerWidth < 992;
        this.isCollapsed = this.isTabletMobile ? true : false;
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                if (event.url.indexOf('live-events/streaming') > -1) {
                    this.isStreamingPage = true;
                } else {
                    this.isStreamingPage = false;
                }

                if (event.url.indexOf('profile/onboarding-wizard') > -1) {
                    this.isOnBoardingPage = true;
                } else {
                    this.isOnBoardingPage = false;
                }
            });

        this.resolutionService.updateTotalNotifications.subscribe(bool => {
            //if (this.fitConfigProviderService.isOwner) {
                setTimeout(() => {
                    this.getTotalNotifications();
                }, 2000);
            //}
        });

        this.user.subscribe(res => {
            clearInterval(this._interval);
            clearInterval(this._notificationInterval);
            if (!res) {
                return;
            }
            if (this.fitConfigProviderService.isRoot) {
                this.getAllAppsMessages();
                this._interval = setInterval(() => this.getAllAppsMessages(), 60 * 1000);
            }
            if (res.free_trial_ends_at) {
                const trial = new Date(res.free_trial_ends_at);
                const trialMilliseconds = trial.getTime();
                const now = Date.now();
                this.trialEndsIn = Math.round((trialMilliseconds - now) / (24 * 60 * 60 * 1000));
                if (this.trialEndsIn < 0) {
                    this.freeTrialEndedAgo = Math.abs(this.trialEndsIn);
                }

                this.freeTrialService.freeTrialEndsIn.emit(this.trialEndsIn);
            }
            clearInterval(this._notificationInterval);
            if (this.fitConfigProviderService.isOwner) {
                this.getTotalNotifications();
                // this._notificationInterval = setInterval(() => this.getTotalNotifications(), 60 * 1000);
            }
        });
    }

    ngAfterViewInit() {
        if ('serviceWorker' in navigator) {
            try {
                navigator.serviceWorker.getRegistrations().then(reg => {
                    const registration = reg.find(r => r.active.scriptURL.includes('firebase'));
                    if (registration && registration.active.state === 'activated') {
                        registration.active.postMessage(JSON.stringify(environment.firebaseConfig), {});
                        this.messagingService.requestPermission().subscribe(
                            (token: string) => {
                                const prevToken = localStorage.getItem('service-token');
                                this.messagingService.saveToken(token).then(() => {
                                    localStorage.setItem('service-token', token);
                                });
                            }
                        );
                    }
                });
                this.messagingService.tokenChanged().subscribe(newToken => {
                    const prevToken = localStorage.getItem('service-token');
                    if (prevToken === newToken) {
                        return;
                    }
                    this.messagingService.saveToken(newToken).then(() => {
                        localStorage.setItem('service-token', newToken);
                    });
                });

                this.messagingService.receiveMessage().subscribe(
                    (notification: NotificationMessage) => {
                        this.currentMessage.next(notification.data);
                        if (this.fitConfigProviderService.isOwner) {
                            this.getTotalNotifications();
                        }
                        if (this.fitConfigProviderService.isRoot) {
                            this.getAllAppsMessages();
                        }
                    }
                );
            } catch (e) {
                console.log(e);
            }
        }
    }

    public getLogoUrl(user: User) {
        if (this.pin && user?.wizard_section_status?.app_visuals_2) {
            return `${environment.awsS3BucketUrl}/pwa/${this.pin}/assets/app_icons/icon-72x72.png`;
        } else {
            return 'assets/img/logo-sidebar.png';
        }
    }

    public showNotifications() {
        if (this.nothIcon.nativeElement) {
            const el = this.nothIcon.nativeElement;
            const rect = el.getBoundingClientRect();
            if (rect.left < 300 && !this.nothIconFarLeft) {
                this.nothIconFarLeft = true;
            } else if (rect.left >= 300 && this.nothIconFarLeft) {
                this.nothIconFarLeft = false;
            }
        }

        this.showNotificationList = !this.showNotificationList;
        if (this.showNotificationList) {
            setTimeout(() => {
                this.outsideClick = true;
            }, 50);
        } else {
            this.outsideClick = false;
        }
    }

    @HostListener('window:click')

    public hideNotificationList() {
        if (this.showNotificationList && this.outsideClick) {
            this.showNotificationList = false;
            this.outsideClick = false;
        }
    }

    public async removeNotifications() {
        this.clearAllNotifications();
        return;
    }

    public async onMarkNotificationSeen(id: number) {
        const { error } = await this.resolutionService.markNotificationSeen(id);
        if (!error) {
            this.totalNotifications.notifications.forEach(noth => {
                if (noth.notification_id === id) {
                    noth.seen = true;
                    if (this.totalNotifications.count > 0) {
                        this.totalNotifications.count--;
                    }

                }
            });
        }
    }

    public async clearAllNotifications() {
        const { data, error } = await this.resolutionService.clearAllNotifications();
        if (!error) {
            this.totalNotifications.count = 0;
            this.totalNotifications.notifications = [];
        }
    }

    public async getTotalNotifications() {
        const { data, error } = await this.resolutionService.getTotalNotifications();
        if (!error) {
            data.notifications.forEach(noth => {
                noth.seen = false;
            });
            this.totalNotifications = data;
        }
    }

    public ngOnDestroy() {
        clearInterval(this._interval);
        clearInterval(this._notificationInterval);
    }

    public collapseNav() {
        if (this.isTabletMobile) {
            this.isCollapsed = true;
        }
        const firstLevel = document.querySelector('.first-level-items');
        const parent = firstLevel.previousElementSibling;
        parent.classList.remove('active');
        firstLevel.children[0].classList.remove('active');
    }

    public collapseOnTabletMobile() {
        this.closeAllTabs();
        this.collapseNav();

    }

    public closeAllTabs() {
        this.navigation.forEach(nav => {
            if (nav.opened) {
                nav.opened = false;
                if (nav.items.length) {
                    nav.items.forEach(item => {
                        if (item.opened) {
                            item.opened = false;
                        }
                    });
                }
            }
        });
    }

    public switchOpenedTab(navIndex: number, navigation: any) {
        navigation.forEach((item, index) => {
            if (index === navIndex) {
                return navigation[index].opened = !navigation[index].opened;
            }
            return item.opened = false;
        });
        setTimeout(() => {
            this.previewNavigation();
        }, 100);

    }

    public previewNavigation() {
        const firstLevel = document.querySelector('.first-level-items');
        const parent = firstLevel.previousElementSibling;
        const seconditems = document.querySelector('.second-level-items');

        if (!location.href.includes('preview')) {
            return;
        }

        if (!firstLevel.classList.contains('opened')) {
            if (!parent.classList.contains('active')) {
                parent.classList.add('active');
            }
        }

        if (!seconditems.classList.contains('opened')) {
            if (!firstLevel.children[0].classList.contains('active')) {
                firstLevel.children[0].classList.add('active');
            }
        }
    }

    public isChildActive(openedNav: boolean, navItems?: SidebarNavigationItem[]) {
        if (!navItems) return false;
        
        if (!openedNav) {
            return navItems.some(item => {
                if (item && item.items) {
                    return item.items.some(i => {
                        if (i && i.items) {
                            return i.items.some(ii => {
                                if (ii && ii.items) {
                                    return ii.items.some(iii => this.router.isActive(iii.url,
                                        { paths: 'subset', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' }));
                                }
                                return this.router.isActive(ii.url,
                                    { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' });
                            });
                        }
                        return this.router.isActive(i.url,
                            { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' });
                    });
                }
                return this.router.isActive(item.url,
                    { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' });
            });
        }
    }

    public async logout() {
        pinClear();
        await this.auth.logout();
        clearInterval(this._interval);
        clearInterval(this._notificationInterval);
    }

    private async getAllAppsMessages() {
        if (!this.fitConfigProviderService.isRoot || this.loading) {
            return;
        }
        this.loading = true;
        const { data, error } = await this.resolutionService.getAllAplicationsUnreadMessages();
        if (!error) {
            this.allAppsUnreadMessages = data;
            this.resolutionService.newDetailsResolutionCenterMessage.next(data);
        }
        this.loading = false;
    }

    private _interval = null;
    private _notificationInterval = null;
    private _totalNotifications: UnreadNotifications = {
        count: 0,
        notifications: []
    };
}
