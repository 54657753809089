import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { GalleryItem, GalleryService } from '@app/gallery/gallery.service';
import { NotificationsService } from '@shared/notifications.service';


@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: FileUploadComponent, multi: true },
        { provide: NG_VALIDATORS, useExisting: FileUploadComponent, multi: true }
    ]
})
export class FileUploadComponent implements ControlValueAccessor, Validator {

    constructor(
        private galleryService: GalleryService,
        private notification: NotificationsService) { }

    @ViewChild('progressBar') progressBar: ElementRef;
    @ViewChild('canvas') public canvas: ElementRef<HTMLCanvasElement>;
    @Input() manualClass = '';
    @Input() themeClass: 'light' | 'dark' = null;
    @Input() primaryColor = '';

    get maxUploadSize() { return this.galleryService.formattedBytes; }
    get files() { return this.fileList as any; }

    public acceptedTypes = [];

    public fakePath = '';

    public isDisabled = false;

    public fileName: string;

    public progress = 0;

    // public reloadInterval = null;

    // public firtsMultipleUploaded = false;

    @Input() public type: 'all' | 'image' | 'video' = 'all';
    @Input() public source: 'gallery' | 'avatar' = 'gallery';
    @Input() public isAvatar: boolean;
    @Input() public id: string;
    @Input() public multiple: boolean;
    @Input() public brandName: string;

    @Input()
    get required() { return this._required; }
    set required(value) { this._required = value !== false; }

    @Input()
    get createLogoFromName() { return this._createLogoFromName; }
    set createLogoFromName(value) {
        if (value) {
            this.uploadCreatedLogo();
        }
        this._createLogoFromName = value;
    }

    @Input()
    get readonly() { return this._readonly; }
    set readonly(value) { this._readonly = value !== false; }

    get item() { return this._item; }
    set item(value) { this._item = value; }

    @Output() uploadedFile = new EventEmitter<GalleryItem>(true);

    // public async startMultipleUpload(file: File) {
    //     const item = file;
    //     const nameParts = item.name.split('.');
    //     nameParts.pop();
    //     this.fileName = nameParts.join('.');

    //     const res = await this.galleryService.uploadFromProfile(this.fileName, item, this.isAvatar);

    //     res.subscribe(
    //         res => {
    //             if (res.progress) {
    //                 this.progress = res.progress;
    //             } else if (res.response) {
    //                 this.progress = 0;
    //                 this._uploadIndex++;
    //                 this.firtsMultipleUploaded = true;
    //                 this.uploadedImage = res.response.data;
    //                 this.uploadedFile.emit(res.response.data);
    //                 this.onTouched();
    //                 this.onChange(res.response.data);
    //             } else {
    //                 this.progress = 0;
    //             }
    //         },
    //         error => {
    //             this.progress = 0;
    //             this._uploadIndex++;
    //             this.multipleUpload();
    //             const err = error.status === 502 ? error.statusText : error.error.errorMessages.join('\r\n');
    //             this.notification.error('Error', err);
    //         },
    //         () => {
    //             this.fileName = '';
    //             this.multipleUpload();
    //         }
    //     );
    // }

    // public multipleUpload() {
    //     if (this.fileList && this._uploadIndex < this.fileList.length) {
    //         return this.startMultipleUpload(this.fileList[this._uploadIndex]);
    //     }
    //     if (this._uploadIndex === this.fileList.length && this.firtsMultipleUploaded) {
    //         this.notification.success('Success', "");
    //     }
    //     this._uploadIndex = 0;
    //     this.fileList = null;
    //     return;
    // }


    public async upload(fileName: string, file: File | Blob) {
        const result = await this.galleryService.uploadFromProfile(fileName, file, this.isAvatar);

        result.subscribe(
            res => {
                if (res.progress) {
                    this.progress = res.progress;
                } else if (res.response) {
                    this.progress = 0;
                    this.item = res.response.data;
                    this.uploadedFile.emit(res.response.data);
                    this.fileName = '';
                    this.onTouched();
                    this.onChange(res.response.data);
                } else {
                    this.progress = 0;
                }
                this.progressBar.nativeElement.style.width = this.progress + '%';

            },
            error => {
                this.onTouched();
                this.onChange(null);
                this.uploadedFile.emit(null);
                this.progress = 0;
                this.progressBar.nativeElement.style.width = this.progress + '%';
                const err = error.status >= 500 ? error.statusText : error.errorMessages.join('\r\n');
                this.notification.error('Error', `Something went wrong while uploading! ${err}`);
            },
            () => { this.fileName = ''; this.fileList = null; }
        );
    }

    public onFileChange($event) {
        // if (this.multiple) {
        //     return this.onMultipleFileSelect($event);
        // }
        this.fileList = $event.target.files;
        const item = this.fileList.item(0);
        if (item) {
            const nameParts = item.name.split('.');
            nameParts.pop();
            this.fileName = nameParts.join('.');
            if (item.size > this.galleryService.uploadLimits.upload_limit_in_bytes) {
                this.notification.warning('File size', 'Max file size ' + this.maxUploadSize);
                return;
            }
            if (this.galleryService.isValidFileType(item, this.type)) {
                this.upload(this.fileName, item);
            } else {
                this.notification.warning('Invalid file type', 'Acceptable file types are '
                    + this.galleryService.getAcceptableFileTypes(this.type).join(', '));
            }
        }
    }

    // public onMultipleFileSelect(event) {
    //     this.fileList = event.target.files;
    //     let currentIndex = 0;
    //     let error = false;
    //     if (this.fileList.length > 0) {
    //         this.stopGalleryReloadInterval();
    //         const checkAllFileTypes = () => {
    //             if (currentIndex < this.fileList.length) {
    //                 if (this.fileList[currentIndex].size > this.galleryService.uploadLimits.upload_limit_in_bytes) {
    //                     this.notification.warning('File size', 'Max file size ' + this.maxUploadSize);
    //                     return;
    //                 }

    //                 if (!this.galleryService.isValidFileType(this.fileList[currentIndex], this.type)) {
    //                     this.notification.warning('Invalid file type', 'Acceptable file types are ' +
    //                         this.galleryService.getAcceptableFileTypes(this.type));
    //                     error = true;
    //                     return;
    //                 }
    //                 if (!error) {
    //                     currentIndex++;
    //                     return checkAllFileTypes();
    //                 }
    //             }
    //             if (!error && currentIndex === this.fileList.length) {
    //                 this._uploadAmount = this.fileList.length;
    //                 this._uploadIndex = 0;
    //                 this.multipleUpload();
    //             }
    //         };
    //         if (currentIndex === 0) {
    //             checkAllFileTypes();
    //         }
    //     }
    // }

    public removeImage(e: Event) {
        e.stopPropagation();
        e.preventDefault();
        this._item = null;
        this.fakePath = null;
        this.fileName = null;
        this.onTouched();
        this.onChange(null);
        this.uploadedFile.emit(null);
    }

    // public stopGalleryReloadInterval() {
    //     if (this.reloadInterval) {
    //         clearInterval(this.reloadInterval);
    //     }
    // }

    public onValidatorChange: () => void = () => { };

    public onChange: (value: GalleryItem) => void = (value: GalleryItem) => { };

    public onTouched: () => void = () => { };

    public writeValue(value: GalleryItem): void {
        if (value) {
            this._item = value;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public validate(control: AbstractControl): ValidationErrors {
        if (this.required && !this._item) {
            return { galleryItemNotSelected: 'Item is required' };
        }
        return null;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    private async uploadCreatedLogo() {
        const img = await this.createLogo();
        this.fileName = 'logo';
        this.upload(this.fileName, img);
    }

    private createLogo() {
        const promise = new Promise<Blob>((resolve, reject) => {
            const canvas = this.canvas.nativeElement;
            canvas.width = 512;
            canvas.height = 512;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = this.themeClass === 'light' ? '#FFFFFF' : '#232323';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = this.themeClass === 'light' ? '#232323' : '#FFFFFF';
            ctx.font = 'bold 250px Montserrat';
            ctx.textAlign = 'center';
            ctx.fillText(this.brandName[0].toUpperCase(), (canvas.width / 2), (canvas.height / 2) + 90);
            canvas.toBlob(blob => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject('Canvas not rendered');
                }
            });
        });
        return promise;
    }

    private _item: GalleryItem;
    private fileList: FileList;
    // private _uploadIndex = 0;
    // private _uploadAmount = 0;
    private _readonly;
    private _required = false;
    private _createLogoFromName = false;
}
