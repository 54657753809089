import { OnInit, Directive } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SortBy } from './components/list-sort/list-sort.component';
import { FitList } from './fit-api-client.service';

@Directive()
export abstract class PageableComponent<T> implements OnInit {
    get list() { return this._list ? this._list.data : []; }
    get page() { return this._page; }
    get totalItems() { return this._list ? this._list.total : 0; }
    get itemsPerPage() { return this._list ? this._list.per_page : 0; }
    get hasPagination() { return this._list && this._list.last_page != 1; }
    get totalPages() { return this._list ? this._list.last_page : 0; }

    public sortBy: SortBy = 'newest';
    public loading = false;
    public searchQuery = '';
    public searchQueryStream = new Subject<string>();
    public markAllItems: boolean = false;
    public markedItems: number[] = [];

    public ngOnInit() {
        this.searchQueryStream.pipe(
            debounceTime(500)
        ).subscribe(() => (
            this._page = 1,
            this.load()
        ));
    }

    public loadQuery() {
        if (sessionStorage.getItem('load-query')) {
            if (sessionStorage.getItem('query')){
                this.searchQuery = sessionStorage.getItem('query');
                sessionStorage.removeItem('query');
            }

            if (sessionStorage.getItem('page')){
                this._page = parseInt(sessionStorage.getItem('page'), 10);
                sessionStorage.removeItem('page');
            }

            sessionStorage.removeItem('load-query');
        }
    }

    public onPageChanged($event: { page: number, itemsPerPage: number }) {
        this._page = $event.page;
        this.load();
    }

    public clearSearch() {
        this.searchQuery = '';
        this._page = 1,
        this.load();
    }

    public onSort(sortBy: SortBy) {
        if (this.sortBy === sortBy) {
            return;
        }
        this.sortBy = sortBy;
        this.load();
    }

    public isItemMarked(id: number): boolean {
        return this.markedItems.includes(id);
    }

    public toggleItemMarked(id: number) {
        this.isItemMarked(id) 
            ? this.markedItems = this.markedItems.filter(item => item !== id) 
            : this.markedItems.push(id);

        this.updateMarkAllItemsState();
    }

    public toggleAllMarked() {
        this.markedItems = this.markAllItems 
            ? [...new Set(this.markedItems.concat(this.list.map(item => item.id)))] //Set removes duplicates
            : this.markedItems.filter(item => !this.list.some(meal => item === meal.id));
    }

    public updateMarkAllItemsState() { //this need to be overriden in list component
        this.markAllItems = this.list.every(item => this.markedItems.includes(item.id));
    }

    public abstract load();
    protected _list: FitList<any>;
    protected _page = 1;
}
