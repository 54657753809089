import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

export interface MarkerDimensions {
    top: number;
    left: number;
    height: number;
    width: number;
}

@Component({
    selector: 'area-marker',
    templateUrl: './area-marker.component.html',
    styleUrls: ['./area-marker.component.scss']
})
export class AreaMarkerComponent implements AfterViewInit, OnDestroy {
    @Input() public nativeWidth: number = null;
    @Input() public nativeHeight: number = null;

    @Output() public dimensions = new Subject<MarkerDimensions>();

    @ViewChild('wrapper') public wrapper: ElementRef<HTMLDivElement>;
    @ViewChild('marker') public marker: ElementRef<HTMLDivElement>;

    public visible = false;

    public ngAfterViewInit() {
        this.wrapper.nativeElement.addEventListener('touchstart', (e: any) => {
            this.onMouseDown(e, this.marker.nativeElement);
        }, { passive: true });
        this.wrapper.nativeElement.addEventListener('touchend', (e: any) => {
            this.onMouseUp(e, this.marker.nativeElement);
        }, { passive: true });
        this.wrapper.nativeElement.addEventListener('touchmove', (e: any) => {
            this.onMouseMove(e, this.marker.nativeElement);
        }, { passive: true });
    }

    public ngOnDestroy() {
        this.wrapper.nativeElement.removeEventListener('touchstart', (e) => { });
        this.wrapper.nativeElement.removeEventListener('touchend', (e) => { });
        this.wrapper.nativeElement.removeEventListener('touchmove', (e) => { });
    }

    public onMouseDown(e: MouseEvent | TouchEvent, marker: HTMLDivElement) {
        this.visible = true;

        if (!this.locked) {
            this.locked = true;
            return;
        }
        this.locked = false;

        const element: Element = e.target as Element;
        const rect = element.getBoundingClientRect();

        let x: number;
        let y: number;

        if (e instanceof MouseEvent) {
            x = e.clientX - rect.left;
            y = e.clientY - rect.top;
        } else {
            x = e.touches[0].clientX - rect.left;
            y = e.touches[0].clientY - rect.top;
        }

        this.x1 = x;
        this.y1 = y;
        this.reCalc(marker);
    }

    public onMouseUp(e: MouseEvent, marker: HTMLDivElement) {
        this.locked = true;

        const widthRatio = this.nativeWidth ? this.nativeWidth / this.wrapper.nativeElement.clientWidth : 1;
        const heightRatio = this.nativeHeight ? this.nativeHeight / this.wrapper.nativeElement.clientHeight : 1;

        const markerLeft = parseFloat(marker.style.left);
        const markerLeftNative = Math.round(markerLeft * widthRatio);
        const markerTop = parseFloat(marker.style.top);
        const markerTopNative = Math.round(markerTop * heightRatio);

        const markerWidth = parseFloat(marker.style.width);
        const markerWidthNative = Math.round(markerWidth * widthRatio);
        const markerHeight = parseFloat(marker.style.height);
        const markerHeightNative = Math.round(markerHeight * heightRatio);

        const dimensions: MarkerDimensions = {
            top: markerTopNative,
            left: markerLeftNative,
            width: markerWidthNative,
            height: markerHeightNative
        };

        this.dimensions.next(dimensions);
    }

    public onMouseMove(e: MouseEvent | TouchEvent, marker: HTMLDivElement) {
        if (this.locked) {
            return;
        }

        const element: Element = e.target as Element;
        const rect = element.getBoundingClientRect();
        let x: number;
        let y: number;
        if (e instanceof MouseEvent) {
            x = e.clientX - rect.left;
            y = e.clientY - rect.top;
        } else {
            x = e.touches[0].clientX - rect.left;
            y = e.touches[0].clientY - rect.top;
        }


        this.x2 = x;
        this.y2 = y;
        this.reCalc(marker);
    }

    private reCalc(div: HTMLDivElement) {
        const x3 = Math.min(this.x1, this.x2);
        const x4 = Math.max(this.x1, this.x2);
        const y3 = Math.min(this.y1, this.y2);
        const y4 = Math.max(this.y1, this.y2);
        div.style.left = x3 + 'px';
        div.style.top = y3 + 'px';
        div.style.width = x4 - x3 + 'px';
        div.style.height = y4 - y3 + 'px';
    }

    private locked = true;

    private x1 = 0;
    private y1 = 0;
    private x2 = 0;
    private y2 = 0;
}
