import { Component, Input, OnInit } from '@angular/core';
import { GalleryItem } from '@app/gallery/gallery-item';
import { NotificationsService } from '@shared/notifications.service';
import { PageableComponent } from '@shared/pageable.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Attachment, MessageRequest, ResolutionCenterService, ResolutionMessage } from '../resolution-center.service';

@Component({
    selector: 'modal-center',
    templateUrl: './modal-center.component.html',
    styleUrls: ['./modal-center.component.scss'],
    providers: [BsModalService]
})

export class ModalCenterComponent extends PageableComponent<any> implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        public notifications: NotificationsService,
        public resolutionCenterService: ResolutionCenterService
    ) {
        super()
    }

    @Input() public getModalUserId;
    @Input() public customerName: string;
    @Input() public customerLastname: string;

    get uploadCounter() { return this._uploadedCounter }

    get messages(){ return this._messages}
    set messages(value){ this._messages = value}

    public loadingMessages = false;
    public progress = 0;

    public sending = false;

    public defaultAvatar = 'assets/icons/ic_placeholder.svg';

    public showLoadMoreMessages = true;
    public selectedContactThread = null;
    public uploadedFiles = [];
    public fileList = [];
    public messageText = '';

    ngOnInit(): void {
        this.getMessagesByCustomerId()
    }

    public load(){}

    public async getMessagesByCustomerId(onlyNew = false) {
        if (!this.getModalUserId) {
            return this.notifications.error('Error', 'Loading user messages failed. Try again.')
        }
        
        const { data, error } = await this.resolutionCenterService.getCustomerMessagesById(this.getModalUserId, this._page);
        
        if (!error) {
            if (onlyNew) {
                this.loadOnlyNewMessages(data.data)
            } else {
                this._messages = [...data.data].sort((a, b) => a.id - b.id);
            }
     
            this.showLoadMoreMessages = this.shouldShowLoadMoreButton(data.current_page, data.last_page);
            setTimeout(() => {
                this.scrollToLastReadedMesssage();
            }, 100)
        }
    }

    public onRemoveAttachment(attachment: GalleryItem) {
        this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== attachment.id);
    }

    public onAttachedFiles(eventFiles) {
        for (let i = 0; i < eventFiles.length; i++) {
            let haveAllready = this.fileList.some(item => item.name === eventFiles[i].name);
            if (!haveAllready) {
                this.fileList.push(eventFiles[i])
            }
        }
        
        this.startUploading();
    }

    public startUploading() {
        this._uploadedCounter = 0;
        if (!this.fileList.length) {
            return;
        }
        
        let amountToUpload = this.fileList.length;
        const upload = async () => {
           if (amountToUpload > this._uploadedCounter) {
                const data = await this.uploadAttachment(this.fileList[this._uploadedCounter])
                if (data) {
                    data.subscribe(
                        res => {
                            if (res.progress) {
                                this.progress = res.progress;
                            } else if (res.response) {
                                this.progress = 0;
                                if (res.response !== undefined) {
                                    this.uploadedFiles.push(res.response.data);
                                }
                                this._uploadedCounter++;
                            } else {
                                this.progress = 0;
                            }
                        },
                        error => {
                            this.progress = 0;
                            this._uploadedCounter++;
                            const err = error.status === 502 ? error.statusText : error.error.errorMessages.join('\r\n');
                            this.notifications.error('Error', err);
                        },
                        () => {
                            upload();
                        }
                    )
                }
            } else {
                this.fileList = [];
                this._uploadedCounter = 0;
            }
        }
        
        upload();
    }

    public uploadAttachment(file) {
        return this.resolutionCenterService.uploadAttachment(file.name, file, this._appId)
    }

    public loadOnlyNewMessages(messages: ResolutionMessage[]){
        const newMessages = [];
        messages.forEach(message => {
            const check = this._messages.some(msg => msg.id === message.id);
            if (!check) {
                newMessages.push(message);
            }
        });
        this._messages = [...this._messages, ...newMessages].sort((a, b) => a.id - b.id);
    }

    public async onDeleteAttachment(file: Attachment) {
        const { data, error } = await this.resolutionCenterService.deleteAttachment(file, this._appId, true);
        if (!error) {
            this.notifications.success('Success', 'Attachment deleted');
            this._messages = this._messages.filter(message =>{
                const check = message.attachments.find(attach => attach.chat_message_attachments_id === file.chat_message_attachments_id);
                if(!check){
                    return true;
                }
                return false;
            })
            this.getMessagesByCustomerId();
        }
    }

    public sendMesssageToContactFromModal() {
        if (this.sending) {
            return;
        }
        
        this.sending = true;
        const message: MessageRequest = {
            user_id: this.getModalUserId,
            message: this.messageText,
            attachment_ids: this.uploadedFiles.map(file => file.id)
        };
        
        if (!message.message && !message.attachment_ids.length) {
            this.sending = false;
            return;
        }
        
        this.resolutionCenterService.sendMessageToContact(message).then(res => {
            this.selectedContactThread = {
                id: res.data.chat_id
            }

            this.sending = false;
            this.messageText = '';
            this.notifications.success('Success', 'Message sent')
            this.getMessagesByCustomerId();
            this.uploadedFiles = [];
        });
    }

    public loadMoreCustomerMessages() {
        if (!this.getModalUserId){
            return this.notifications.error('Error', 'Loading user messages failed. Try again.')
        }
        
        this._page++;
        this.showLoadMoreMessages = false;
        this.resolutionCenterService.getCustomerMessagesById(this.getModalUserId, this._page).then(res => {
            this._messages = [...this._messages, ...res.data.data].sort((a, b) => a.id - b.id);
            this.fillEmptyAvatarsWithDefault();
            if(!res.data.data.length){
                this._page--;
                this.notifications.warning('No messages', 'No more messages');
            }
            this.showLoadMoreMessages = true;
        });
    }

    public scrollToLastReadedMesssage() {
        const messageBox: HTMLDivElement = document.querySelector('.message-list');
        if (messageBox) {
            messageBox.scroll({ top: 20000, behavior: 'smooth' });
        }
    }

    public fillEmptyAvatarsWithDefault() {
        this._messages.forEach(message => {
            if (!message.user_avatar) {
                message.user_avatar = this.defaultAvatar;
            }
        });
    }

    private shouldShowLoadMoreButton(currentPage: number, lastPage: number) {
        return currentPage < lastPage;
    }

    public closeModalChat() {
        this.bsModalRef.hide();
    }

    private _uploadedCounter = 0;
    private _appId;
    private _messages: ResolutionMessage[] = [];
}
