import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationType } from '@app/resolution-center/resolution-center.service';

export interface MessageSeenDetails {
    index: number;
    component: NotificationMessageComponent;
}

@Component({
    selector: 'notification-message',
    templateUrl: './notification-message.component.html',
    styleUrls: ['./notification-message.component.scss']
})

export class NotificationMessageComponent implements OnInit {
    constructor() { }

    @Input() type: NotificationType;
    @Input() id: number;
    @Input() notification_id: number;
    @Input() seen: boolean;
    @Input() title: string;
    @Input() timestamp: string;
    @Input() sender: string;
    @Input() index: number;
    @Input() suspended = false;
    @Input() message: string;

    @Output() public markNotificationSeen = new EventEmitter<any>();

    ngOnInit(): void { }
}
