import { Injectable } from "@angular/core";
import { Customer } from "../../../tailor-made/tailo-made.service";
import { Program } from "@app/program/types";
import { BsModalService } from "ngx-bootstrap/modal";
import { AssignModalComponent } from './assign-modal.component';

export interface initialModalState{
    program: Program;
    user: Customer;
}

@Injectable()
export class AssignModalService{

    constructor(private modalService: BsModalService){}

    public show(initialState: initialModalState){
        this.modalService.show(AssignModalComponent, {initialState, class: 'modal-assign-custom'});
    }
}
