<div class="wizard-box">
    <div class="container">
        <div class="wizard-box-stepper-box">
            <div *ngIf="!fromDashboard"
                 [ngClass]="{'included-app': includedCustomApp}"
                 class="stepper-box second-step"></div>
            <a [routerLink]="fromDashboard ? '/' : '/profile/onboarding-wizard/theme-color'"
               class="back-btn"
               [ngClass]="{'top-0' : fromDashboard}"><img src="../../assets/icons/ic_arrow_left-2.svg" /><span class="back-arrow-text">{{ fromDashboard ? 'Back to dashboard' : 'Back' }}</span></a>
                        <ng-container *ngIf="!fromDashboard">
                            <h6>Step 2 of {{ !includedCustomApp ? '6' : '8' }}</h6>
                        </ng-container>
                        <ng-container *ngIf="fromDashboard">
                            <h6>Step 2</h6>
                        </ng-container>
        </div>
        <div class="wizard-box-content">
            <h2>Choose your highlight color</h2>
            <p *ngIf="!includedCustomApp" class="mb-0 text-show-mobile">This color is used for buttons and items that are highlighted within your branded web app. You can select one highlight color.</p>
            <p *ngIf="!includedCustomApp" class="mb-0 text-show-desktop">This color is used for buttons and items that are highlighted within your branded web app.</p>
            <p *ngIf="includedCustomApp" class="mb-0 text-show-mobile">This color is used for buttons and items that are highlighted within your personalized fitness app. You can select one highlight color.</p>
            <p *ngIf="includedCustomApp" class="mb-0 text-show-desktop">This color is used for buttons and items that are highlighted within your personalized fitness app.</p>
            <p class="m-0 text-show-desktop">You can select one highlight color.</p>
            <div class="theme-color-content flex-wrap">
                <div *ngFor="let color of accentColors"
                     class="colors-box"
                     [ngClass]="{'selected-color': selected?.value === color.value}"
                     (click)="selectPrimaryColor(color)">
                    <div class="colors-box-inner">
                        <div class="color-indicator"
                             [ngStyle]="{'background': color.color}"></div>
                        <div class="color-desc">
                            <h6>{{ color.name }}</h6>
                            <p class="smaller">{{ color.desc}}</p>
                        </div>
                        <span *ngIf="selected?.value === color.value"
                              class="checkmark-selected"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex w-100 bottom-wizard-box">
        <a *ngIf="!fromDashboard"
           [routerLink]="'/profile/onboarding-wizard/upload-images'"
           class="btn button-true-green"
           [ngClass]="{'disabled' : !selected}">Continue</a>
        <button *ngIf="fromDashboard"
                type="button"
                class="btn button-true-green"
                (click)="saveChanges()">Save changes</button>
    </div>
</div>
