import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { GalleryItem } from '../gallery.service';
@Component({
    selector: 'app-gallery-modal',
    templateUrl: './gallery-modal.component.html',
    styleUrls: ['./gallery-modal.component.scss']
})
export class GalleryModalComponent {
    constructor(public bsModalRef: BsModalRef) { }

    public type: 'all' | 'image' | 'video' | 'document' = 'all';

    public source: 'gallery' | 'avatar' = 'gallery';

    public select(item: GalleryItem) {
        this.onClose(item);
        this.bsModalRef.hide();
    }

    private onClose(item: GalleryItem) { }
}
