import { Pipe, PipeTransform } from '@angular/core';
import { Md5 } from "md5-typescript";

@Pipe({
    name: 'gravatar',
    pure: true
})
export class GravatarPipe implements PipeTransform {

    public transform(email: string): string {
        return 'https://www.gravatar.com/avatar/' + Md5.init(email || '') + '?d=404';
    }

}
