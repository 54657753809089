import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResolutionCenterComponent } from './resolution-center.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgSelectModule } from '@ng-select/ng-select';
import { AttachFilesComponent } from './attach-files/attach-files.component';
import { CoachCenterComponent } from './coach-center/coach-center.component';
import { ModalCenterComponent } from './modal-center/modal-center.component';
import { ClientCenterComponent } from './client-center/client-center.component';
import { CancellationCenterComponent } from './cancellation-center/cancellation-center.component';
import { RootCenterComponent } from './root-center/root-center.component';
import { ResolutionCenterRoutingModule } from './resolution-center-routing.module';
import { MessageReactionComponent } from './message-reaction/message-reaction.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
    declarations: [
        ResolutionCenterComponent,
        AttachFilesComponent,
        CoachCenterComponent,
        ModalCenterComponent,
        ClientCenterComponent,
        CancellationCenterComponent,
        RootCenterComponent,
        MessageReactionComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgSelectModule,
        PaginationModule.forRoot(),
        PopoverModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        FormsModule,
        ResolutionCenterRoutingModule
    ],
    providers: [],
    exports: [ResolutionCenterComponent]
})

export class ResolutionCenterModule { }
