<div class="attach-files" [ngStyle]="{'width': width, 'max-width': maxWidth}">
    <p class="progress" *ngIf="progress">
        <span class="input-label">Uploading {{ current + 1 }}/{{totalUploads}}</span>
        <span class="progress-bar" [ngStyle]="{'width': progress + '%'}"></span>
    </p>
    <p class="attached-item input-label" *ngFor="let file of files; let i = index">
        <span class="input-label">{{ file.name }}</span>
        <span (click)="onRemoveFile(file)"><img  src="../../../assets/icons/delete.png"/></span>
    </p>
    <input type="file" name="attach" id="attach"
           [(ngModel)]="fakePath"
           multiple
           (change)="onSelectFile($event)"/>
    <label for="attach" class="input-label"><img  src="../../../assets/icons/ic_attach.svg" class="attach-icon"/>
        <span>{{title ? title : 'Attach file'}}</span>
        <span [class.required]="title === 'Upload proof'"></span>
    </label>
</div>
