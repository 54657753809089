import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { pinGetter } from '@shared/fit-api-client.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HasPinGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (pinGetter()) {
            return true;
        }
        this.router.navigateByUrl('');
        return false;
    }

}
