<div class="wizard-box">
    <div class="container">
        <div class="creating-app-box">
            <img class="logo"
                 [src]="'assets/icons/logo_lean_on_black.svg'" />
            <h1>We are creating your <b>branded web app</b></h1>
            <p>You will get a link and a QR code for your branded web app.</p>
            <div class="wizard-box-stepper-box">
               <progressbar [value]="uploadPercent"></progressbar>
                <h2>{{uploadPercent}}%</h2>
            </div>
            <h5>Trusted by 100+ personal trainers, coaches and fitness instructors.</h5>
            <div class="trainers-box">
                <img [src]="'assets/images/image_avatar_moali.png'" />
                <img [src]="'assets/images/image_avatar_lauren.png'" />
                <img [src]="'assets/images/image_avatar_brute.png'" />
                <img [src]="'assets/images/image_avatar_brittany.png'" />
                <img [src]="'assets/images/image_avatar_betty.png'" />
            </div>
        </div>
    </div>
</div>
