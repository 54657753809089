import { Component } from '@angular/core';
import { MarkerDimensions } from '@shared/components/area-marker/area-marker.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { GalleryItem } from '../gallery-item';

@Component({
    selector: 'app-gallery-video-crop',
    templateUrl: './gallery-video-crop.component.html',
    styleUrls: ['./gallery-video-crop.component.scss']
})
export class GalleryVideoCropComponent {

    constructor(public bsModalRef: BsModalRef) { }

    public item: GalleryItem;

    public cropDimensionsChange(dimensions: MarkerDimensions) {
        this.dimensions = dimensions;
    }

    public close() {
        this.onClose(this.dimensions);
        this.bsModalRef.hide();
    }

    public switchPlayMode(item: GalleryItem) {
        item.playMode = !item.playMode;
    }

    private onClose(dimensions: MarkerDimensions) { }

    private dimensions: MarkerDimensions;

}
