<div class="column">
    <div class="message-box">
        <div class="content-right"
             [ngClass]="{'hide-section': showCreateNew}">
            <form #cancelForm="ngForm">
                <div class="row-full-width">
                    <p class="input-label">Cancellation reason</p>
                    <ng-select name="subject"
                               [clearable]="false"
                               class="w-100 select-input"
                               [items]="cancelationReasons"
                               [(ngModel)]="selectedCancelationReason"
                               [ngClass]="{'form-submitted': cancelForm.submitted}"
                               required>
                    </ng-select>
                    <div class="row-full-width p-0"
                         *ngIf="selectedCancelationReason === 'Other'">
                        <label class="input-label">Other</label>
                        <input name="reason"
                               class="w-100"
                               [(ngModel)]="newThread.subject"
                               [ngClass]="{'form-submitted': cancelForm.submitted}"
                               required />
                    </div>
                </div>
            </form>
            <div class="content-body messages-wrapper">
                <form id="message-form"
                      name="create"
                      #messageForm="ngForm">
                    <p class="input-label">Details</p>
                    <textarea type="text"
                              class="message-input input"
                              name="messageText"
                              maxlength="4000"
                              [required]="uploadedFiles.length === 0"
                              [(ngModel)]="messageText"
                              [ngClass]="{'form-submitted': messageForm.submitted}"></textarea>
                    <attach-files [files]="uploadedFiles"
                                  [title]="'Upload proof'"
                                  (onRemove)="onRemoveAttachment($event)"
                                  [totalUploads]="fileList.length"
                                  (onAddFiles)="onAttachedFiles($event)"
                                  [progress]='progress'
                                  [current]="(uploadCounter)"></attach-files>
                    <button type="button"
                            #sendMessageButton
                            [disabled]="messageForm.invalid || uploadedFiles.length === 0 || cancelForm.invalid"
                            class="button-true-green"
                            (click)="!messageForm.invalid && !cancelForm.invalid && sendCancellationMessage()">Submit to review</button>
                </form>
            </div>
        </div>
    </div>
</div>
