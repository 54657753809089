import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule } from 'ngx-progressbar/router';

import { tokenGetter } from '@shared/authorization.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PipesModule } from './pipes/pipes.module';

import { HeaderIphoneDirective } from './iphone-fix-directives/header-iphone.directive';
import { IphoneLogoutFixDirective } from './iphone-fix-directives/iPhone-logout-fix-directive';
import { HeaderArrowDirective } from './iphone-fix-directives/header-arrow.directive';
import { LogoutPositionDirective } from './iphone-fix-directives/logout-position.directive';
import { SidebarIphoneDirective } from './iphone-fix-directives/sidebar-iphone.directive';
import { ContentFixDirective } from './iphone-fix-directives/content-fix.directive';
import { SharedModule } from '@shared/shared.module';
import { ProfileModule } from './profile/profile.module';
import { OfflineInterceptor } from '@shared/interceptors/offline.interceptor';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from '@environments/environment';
import { NotificationMessageComponent } from './notification-message/notification-message.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderIphoneDirective,
        HeaderArrowDirective,
        ContentFixDirective,
        LogoutPositionDirective,
        IphoneLogoutFixDirective,
        SidebarIphoneDirective,
        NotificationMessageComponent],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        ProfileModule,
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: [
                    'localhost',
                    'fitness-marketplace.test:8083',
                    'fitin-api.telego-dev.net',
                    'api.leanondigital.com'
                ],
            }
        }),
        SharedModule,
        PipesModule,
        BsDropdownModule.forRoot(),
        AccordionModule.forRoot(),
        NgProgressModule,
        NgProgressRouterModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OfflineInterceptor,
            multi: true
        }
    ]
})
export class AppModule { }


