import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthorizationService } from './authorization.service';

@Injectable()
export class UnauthorizedRequestInterceptor implements HttpInterceptor {
    constructor(private auth: AuthorizationService) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                tap(
                    (event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            // do stuff with response if you want
                        }
                    }, (err: any) => {
                        if (err instanceof HttpErrorResponse) {
                            if ((err.status === 401 && this.auth.isAuthenticated()) || (err.status === 403 && this.auth.isAuthenticated())) {
                                this.auth.clearToken();
                                this.auth.logout();
                            }
                        }
                    }
                )
            );
    }
}
