import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors, ControlValueAccessor, Validator, NgForm } from '@angular/forms';
import { Country, FitConfigProviderService } from '@shared/fit-config-provider.service';
import { NotificationsService } from '@shared/notifications.service';
import { ProfileService } from '../profile.service';
import { Owner } from '../types';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '@shared/authorization.service';


@Component({
    selector: 'profile-owner-info',
    templateUrl: './owner-info.component.html',
    styleUrls: ['./owner-info.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: OwnerInfoComponent, multi: true },
        { provide: NG_VALIDATORS, useExisting: OwnerInfoComponent, multi: true }
    ]
})
export class OwnerInfoComponent implements ControlValueAccessor, Validator, OnInit {
    constructor(
        private profileService: ProfileService,
        private fitConfigProviderService: FitConfigProviderService,
        private notifications: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthorizationService
    ) {
        this.load();
    }

    get user(){ return this.authService.user.getValue()}

    @Input() submitted = false;

    @ViewChild('ownerForm') public ownerForm: NgForm;

    get owner() {
        return this._owner;
    }
    set owner(value) {
        this._owner = value;
    }

    public countries: Country[] = [];
    public isDisabled = false;
    public fromDashboard = false;

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (!params.has('dashboard')) {
                this.fromDashboard = false;
                return;
            }
            this.fromDashboard = true;
        });
        this.getCountries();
    }

    public nextStep(form: NgForm) {
        if (!form.valid) {
            return;
        }
        this.update();
    }

    public onBack(){
        if(this.fromDashboard){
            return this.router.navigateByUrl('/')
        }
        if(this.user.coach_package.include_custom_application){
            return this.router.navigateByUrl('/profile/onboarding-wizard/app-info')
        }else{
            return this.router.navigateByUrl('/profile/onboarding-wizard/app-ready')
        }
    }

    public onValidatorChange: () => void = () => { };
    public onTouched: () => void = () => { };
    public onChange: (value: Owner) => void = (value: Owner) => { };

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidatorChange = fn;
    }

    public validate(control: AbstractControl): ValidationErrors {
        if (!this._owner) {
            return { ownerInfo: 'Owner Info is required' };
        } else if (!this._owner.first_name) {
            return { name: 'Name is required' };
        } else if (!this._owner.last_name) {
            return { lastname: 'Lastname is required' };
        } else if (!this._owner.city) {
            return { city: 'City is required' };
        } else if (!this._owner.street_name_and_number) {
            return { address: 'Address is required' };
        } else if (!this._owner.country) {
            return { country: 'Country is required' };
        } else if (!this._owner.state) {
            return { state: 'State is required' };
        } else if (!this._owner.zip_code) {
            return { zuipCode: 'Zip code is required' };
        }
        return null;
    }

    public writeValue(obj: Owner): void {
        if (obj) {
            this._owner = obj;
        } else {
            this._owner = {
                street_name_and_number: '',
                street_name_and_number_line_2: '',
                city: '',
                country: '',
                state: '',
                zip_code: '',
                first_name: '',
                last_name: '',
                email: '',
            };
        }
    }

    public updateOwnerInfo() {
        this.onTouched();
        this.onChange(this._owner);
    }

    public async load() {
        const { error, data } = await this.profileService.getOwnerInfo();
        if (!error) {
            this.owner = data;
            this.profileService.setOwnerInfo(data);
            this.updateOwnerInfo();
        }
    }

    public async update() {
        const { data, error } = await this.profileService.updateOwnerInfo(
            this._owner
        );
        if (!error) {
            // this.notifications.success('Success', 'Owner info updated!');
            this.profileService.ownerInfoCompleted.next(true);
            if (this.fromDashboard) {
                this.notifications.success('Success', 'Saved');
                this.router.navigate(['/']);
            } else {
                this.router.navigate(['/profile/onboarding-wizard/billing-info']);
            }
        } else {
            this.notifications.error('Error', 'Something went wrong while updating your info');
        }
    }

    private async getCountries() {
        const countries = await this.fitConfigProviderService.getCountryList();
        if (countries) {
            return this.countries = countries;
        }
        return this.notifications.error('Error', 'Error loading list of countries');
    }

    private _owner: Owner = {
        street_name_and_number: '',
        street_name_and_number_line_2: '',
        city: '',
        country: '',
        state: '',
        zip_code: '',
        first_name: '',
        last_name: '',
        email: '',
    };
}
