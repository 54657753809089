<div *ngIf="showSpinner"
     class="loader-container">
    <div class="loader"></div>
</div>

<div *ngIf="isAuthenticated"
     class="header"
     [ngClass]="{'collapse-menu' : isCollapsed, 'streaming-page': isStreamingPage, 'boarding-page' : isOnBoardingPage}"
     appIphonePositionFix>
    <div class="header-wrapper"
         *ngIf="(user | async) as user"
         appIphonePositionFix>
        <span class="header-notifications"
              *ngIf="isOwner"
              [ngClass]="{'opened': showNotificationList, 'far-left': nothIconFarLeft}"
              #notifications>
            <a class="button-help-center"
               target="_blank"
               href="https://wiki.leanondigital.com/doku.php">
                <div style="background-image: url('assets/icons/ic_help_center.svg')"
                    class="d-flex w-100 navigation-title-icon"></div>
           </a>

            <span (click)="showNotifications()">
                <img src="../assets/icons/ic_notification.svg" />
                <span class="noth-option-1"
                      *ngIf="totalNotifications.count">{{totalNotifications.count}}</span>
            </span>
            
            <div class="notification-list"
                 [ngClass]="{'empty-list': totalNotifications.notifications.length === 0}"
                 *ngIf="showNotificationList">
                <h6><span>Notifications</span>
                    <span *ngIf="user.status_mobile_apps !== 'STATUS_SUSPENDED'"
                          (click)="clearAllNotifications()">clear all</span>
                </h6>
                
                <div class="notification-list-items">
                    <div [ngClass]="{'no-new-messages': totalNotifications.notifications.length === 0}"
                         *ngIf="totalNotifications.notifications.length === 0">
                        <p class="empty-list">No new notifications</p>
                        <p>New notifications will be shown here.</p>
                    </div>
                    
                    <ng-container *ngFor="let notification of totalNotifications.notifications; let i = index">
                        <notification-message [suspended]="user.status_mobile_apps === 'STATUS_SUSPENDED'"
                                              [id]="notification.type_id"
                                              [type]="notification.type"
                                              [message]="notification.message"
                                              [notification_id]="notification.notification_id"
                                              [seen]="notification.seen"
                                              [title]="notification.title"
                                              [timestamp]="notification.timestamp"
                                              [sender]="notification.sender"
                                              [index]="i"
                                              (markNotificationSeen)="onMarkNotificationSeen($event)">
                        </notification-message>
                    </ng-container>
                </div>
            </div>
            
            <!-- <span class="free-trial-noth"
                  [ngClass]="{'suspended': user.status_mobile_apps === 'STATUS_SUSPENDED'}"
                  *ngIf="isOwner && user.status_mobile_apps === 'STATUS_SUSPENDED' && !user.custom_status_mobile_apps"><b>Your
                    account has been suspended - please contact Lean On via Resolution Centre or email as soon as
                    possible.</b></span> -->
            
            <span class="free-trial-noth"
                  [ngClass]="{'suspended': user.status_mobile_apps === 'STATUS_SUSPENDED'}"
                  *ngIf="isOwner && user.status_mobile_apps === 'STATUS_SUSPENDED' && user.custom_status_mobile_apps"><b>{{
                    user.custom_status_mobile_apps }}</b></span>
            
            <span class="free-trial-noth"
                  *ngIf="trialEndsIn > 0 && isOwner && user.status_mobile_apps !== 'STATUS_SUSPENDED'">Your free trial
                ends in <b>{{trialEndsIn}} days</b></span>
            
            <span class="free-trial-noth"
                  *ngIf="trialEndsIn === 0 && isOwner && user.status_mobile_apps !== 'STATUS_SUSPENDED'">Your free trial
                ends <b>Today</b></span>
            
            <span class="free-trial-noth"
                  *ngIf="trialEndsIn < 0 && isOwner && user.status_mobile_apps !== 'STATUS_SUSPENDED' && freeTrialEndedAgo <= 7">Your
                free trial
                ended <b>{{ freeTrialEndedAgo }} days ago</b></span>
        </span>
        
        <div *ngIf="allAppsUnreadMessages.length > 0 && isRoot"
             class="dropdown"
             dropdown
             placement="bottom right">
            <button type="button"
                    class="rc-messages"
                    id="button-alignment"
                    dropdownToggle>
                <span>RC</span>
            </button>
            
            <ul id="dropdown-alignment"
                *dropdownMenu
                class="dropdown-menu dropdown-menu-right"
                role="menu"
                aria-labelledby="button-alignment">
                <li role="menuitem"
                    *ngFor="let app of allAppsUnreadMessages">
                    <a class="dropdown-item"
                       [routerLink]="['/applications/details/', app.whitelabel_application_id ]">
                       {{ app.whitelabel_application_name }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="sidebar"
     *ngIf="isAuthenticated && !isOnBoardingPage"
     [ngClass]="{'collapse-menu' : isCollapsed, 'streaming-page': isStreamingPage}"
     appIphonePositionFix>
    <div class="sidebar-min">
        <div class="sidebar-header">
            <div class="sidebar-header-navbar"
                 (click)="isCollapsed = !isCollapsed">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
    
    <div class="sidebar-extended fixed"
         appSidebarIphoneFix>
        <div class="sidebar-header">
            <a [routerLink]="['/']"
               (click)="collapseOnTabletMobile()">
                <div class="avatar-wrapper">
                    <div class="avatar-lon">
                        <img src="assets/img/logo_lon.svg" />
                    </div>
                    
                    <div class="logged-in-user-name">
                        <p *ngIf="(user | async) as user">{{ user.first_name + ' ' + user.last_name }}</p>
                    </div>
                </div>
            </a>
            
            <div class="sidebar-header-navbar"
                 (click)="isCollapsed = !isCollapsed">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="sidebar-menu">
            <div class="sidebar-menu-wrapper">
                <div class="sidebar-menu-links">
                    <a [routerLink]="['/']"
                       routerLinkActive="active"
                       [routerLinkActiveOptions]="{ exact: true }"
                       (click)="collapseOnTabletMobile()"
                       style="background-image: url('assets/icons/ic_dashboard.svg')"
                       class="sidebar-menu-item home-page">
                        <span class="sidebar-menu-item-title">Dashboard</span>
                    </a>
                    <!-- NAV ITEMS -->
                    <div class="links">
                        <ng-container *ngFor="let nav of navigation; let i = index">
                            <a *ngIf="nav.url"
                               [routerLink]="nav.url"
                               [routerLinkActiveOptions]="{ subset: true }"
                               routerLinkActive="active"
                               (click)="collapseNav()"
                               class="btn btn-link btn-block clearfix resolution">
                                <div [ngStyle]="{'background-image':'url(' + nav.icon + ')'}"
                                     class="d-flex w-100 navigation-title-icon">
                                    <span>{{ nav.title }}</span>
                                </div>
                            </a>

                            <button *ngIf="nav.items"
                                    class="btn text-light d-flex btn-block clearfix nav-button"
                                    [ngClass]="{'active': isChildActive(nav.opened, nav.items)}"
                                    (click)="switchOpenedTab(i, navigation)"
                                    type="button">
                                <div [ngStyle]="{'background-image':'url(' + nav.icon + ')'}"
                                     class="d-flex w-100 navigation-title-icon">{{ nav.title }}</div>
                                <div class="chevron-down"
                                     [ngClass]="{'rotated': nav.opened}"
                                     style="background-image: url('assets/icons/ic_chevron_down.svg')">
                                </div>
                                <!-- <span class="noth noth-option-1"
                                      *ngIf="nav.title === 'Your app' && totalNotifications.number_of_unread_chats">{{totalNotifications.number_of_unread_chats}}</span> -->
                            </button>
                            
                            <!-- FIRST LEVEL ITEMS -->
                            <div *ngIf="nav.items"
                                 class="first-level-items"
                                 [ngClass]="{'opened': nav.opened}">
                                <ng-container *ngFor="let first of nav.items; let f = index">
                                    <button class="btn text-light d-flex btn-block clearfix first-level-button"
                                            *ngIf="!first.url"
                                            (click)="switchOpenedTab(f, nav.items)"
                                            type="button"
                                            [ngClass]="{'active': isChildActive(first.opened, first.items)}">
                                        <div class="d-flex w-100 navigation-title-icon">{{ first.title }}</div>
                                        <div class="chevron-down scaled-once"
                                             [ngClass]="{'rotated': first.opened}"
                                             style="background-image: url('assets/icons/ic_chevron_down.svg')"></div>
                                    </button>
                                    
                                    <a *ngIf="first.url && first.url !== '/app-preview'"
                                       [routerLink]="first.url"
                                       [routerLinkActiveOptions]="{ exact: true }"
                                       routerLinkActive="active"
                                       (click)="collapseNav()"
                                       class="sidebar-menu-item second-level-button second-level-link">
                                        <span class="sidebar-menu-item-title">{{ first.title }}</span>
                                        <!-- <span class="noth noth-option-1"
                                              *ngIf="first.url === '/client-messages' && totalNotifications.number_of_unread_chats">{{totalNotifications.number_of_unread_chats}}</span> -->
                                    </a>
                                    
                                    <ng-container *ngIf="(user | async) as user">
                                        <a *ngIf="user.used_modulator && first.url && first.url === '/app-preview'"
                                           [routerLink]="first.url"
                                           [routerLinkActiveOptions]="{ exact: true }"
                                           routerLinkActive="active"
                                           (click)="collapseNav()"
                                           class="sidebar-menu-item second-level-button second-level-link">
                                            <span class="sidebar-menu-item-title">{{ first.title }}</span>
                                        </a>
                                    </ng-container>
                                    
                                    <!-- SECOND LEVEL ITEMS -->
                                    <div class="second-level-items"
                                         [ngClass]="{'opened':  first.opened}">
                                        <ng-container *ngFor="let second of first.items; let s = index">
                                            <button class="btn text-light d-flex btn-block clearfix second-level-button"
                                                    *ngIf="second.title === 'Setup'"
                                                    (click)="switchOpenedTab(s, first.items)"
                                                    type="button"
                                                    [ngClass]="{'active': isChildActive(second.opened, second.items)}">
                                                <div [ngStyle]="{'background-image':'url(' + second.icon + ')'}"
                                                     class="d-flex w-100 navigation-title-icon">{{ second.title }}</div>
                                                <div class="chevron-down scaled-once"
                                                     [ngClass]="{'scale-rotated': second.opened}"
                                                     style="background-image: url('assets/icons/ic_chevron_down.svg')">
                                                </div>
                                            </button>
                                            <a *ngIf="second.title !== 'Setup'"
                                               [routerLink]="second.url"
                                               [routerLinkActiveOptions]="{ subset: true }"
                                               routerLinkActive="active"
                                               (click)="second.opened = false; collapseNav()"
                                               class="sidebar-menu-item second-level-button">
                                                <span class="sidebar-menu-item-title">{{ second.title }}</span>
                                            </a>
                                            <div class="third-level-items"
                                                 [ngClass]="{'opened':  second.opened}"
                                                 *ngIf="second.items">
                                                <ng-container *ngFor="let third of second.items; let t = index">
                                                    <a class="third-level-button"
                                                       [routerLink]="third.url"
                                                       [routerLinkActiveOptions]="{ subset: true }"
                                                       routerLinkActive="active"
                                                       (click)="switchOpenedTab(t, second.items); collapseNav()">{{
                                                        third.title }}</a>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>

                        <a *ngIf="pin && isAdministrator && !isRoot"
                           [routerLink]="['/resolution-center']"
                           [routerLinkActiveOptions]="{ subset: true }"
                           routerLinkActive="active"
                           (click)="collapseNav()"
                           class="btn btn-link btn-block clearfix resolution">
                            <div style="background-image: url('assets/icons/ic_support.svg')"
                                 class="d-flex w-100 navigation-title-icon">
                                 <span>Lean On support</span>
                                <!-- <span title="Unread messages"
                                      class="noth-option-1"
                                      *ngIf="totalNotifications.number_of_unread_resolution_centre_threads > 0">{{
                                    totalNotifications.number_of_unread_resolution_centre_threads }}</span> -->
                            </div>
                        </a>
                        
                        <a class="btn btn-link btn-block clearfix logout"
                           (click)="logout()"
                           IphoneLogoutFix>
                            <div style="background-image: url('assets/icons/ic_logout.svg')"
                                 class="d-flex w-100 navigation-title-icon">
                                <span>Log out</span>
                            </div>
                        </a>
                    </div>
                    <!--  TO HERE -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content"
     IphoneContentFix
     onboardingFix
     [ngClass]="{'loged' : isAuthenticated, 'collapsed' : isCollapsed}">
    <router-outlet></router-outlet>
</div>

<ng-progress></ng-progress>
