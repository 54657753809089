import { Injectable } from '@angular/core';
import { NotificationsService } from '@shared/notifications.service';
import { DeclinedStripeCode, declinedStripeCodes, stripeCodeErrors } from './transaction-error-codes';


interface Code {
    code: string;
    decline_code: string;
}
@Injectable({ providedIn: 'root' })
export class DisplayCardError {

    constructor(private notification: NotificationsService) { }

    public displayAuthorizeError() {
        return;
    }

    public displayStripeError(error: Code) {
        if (error.code === 'card_declined') {
            return this.displayDeclinedCodeError(error.decline_code);
        }
        return this.displayCodeError(error.code);
    }

    private displayCodeError(code: string) {
        let message = '';
        stripeCodeErrors.forEach((errorCode: DeclinedStripeCode) => {
            if (errorCode.code === code) {
                return (message = errorCode.message);
            }
        });
        if (message === '') {
            message = 'Something went wrong, please try again or contact your bank';
        }
        this.notification.error('Error', message);
    }

    private displayDeclinedCodeError(declineCode: string) {
        let message = '';
        declinedStripeCodes.forEach((declinedCode: DeclinedStripeCode) => {
            if (declinedCode.code === declineCode) {
                return (message = declinedCode.message);
            }
        });
        if (message === '') {
            message = 'Something went wrong, please try again or contact your bank';
        }
        this.notification.error('Error', message);
    }


}



