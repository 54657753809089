import { Injectable } from '@angular/core';
import { FitApiClientService } from './fit-api-client.service';
@Injectable()
export class TimeZoneService {
    constructor(private fitApi: FitApiClientService) { }

    public setTimeZone(timezone: string) {
        return this.fitApi.put<string>(`/admin/user/timezone`, { timezone });
    }
}
