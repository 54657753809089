import { ElementRef } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
  selector: '[appIphonePositionFix]'
})
export class HeaderIphoneDirective{

  constructor(el: ElementRef) { 
    if(this.checkPlatform()){
      el.nativeElement.classList.add('ios-fix');
    }
  }

  private checkPlatform(){
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform);
  }

}
