import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trimDeleted',
    pure: true
})
export class TrimDeletedEmail implements PipeTransform {

    transform(value: string, ...args: string[]): string {
        return value.slice(0, 30);
    }

}
